import React, { useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Layout,
    Row,
    Space,
    Table,
    Tag
} from "antd";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavBreadcrumb from "../../../components/NavBreadcrumb";

import AxiosUtil from "../../../utils/AxiosUtil";
import moment from "moment";
import {PaymentChannelDo} from "../model/PaymentChannelDo";
import PaymentEditor from "../components/PaymentEditor";
import {PlusCircleOutlined, RedoOutlined} from "@ant-design/icons";

const {Content} = Layout;

const PaymentConfig = () => {

    const formRef = useRef();

    const [mainData, setMainData] = useState<PaymentChannelDo[]>([]);

    useEffect(() => {
        // document.title = "支付渠道";
        loadMainData()
    }, [])

    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/mallApi/payConfig/list', {orderBy: "sort_index"},
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list);
                }
            });
    }

    const openEdit = (r?: PaymentChannelDo) => {
        console.log(r);
        if (!r) {
            r = {} as PaymentChannelDo;
        }
        (formRef.current as any).openForm(r);
        // setShowEdit(true);
    }

    const columns: any[] = [
        {
            title: "ID",
            dataIndex: "id",
            width: 40,
            align: 'center' as 'center'
        },
        {
            title: "LOGO",
            dataIndex: "image",
            width: 100,
            align: 'center' as 'center',
            render: (v: string, r: PaymentChannelDo) => <a onClick={() => openEdit(r)}><img src={v} alt="" style={{maxHeight: 36}}/></a>
        },
        {
            title: "名称",
            dataIndex: "payName",
            width: 120,
            render: (v: string, r: PaymentChannelDo) => <a onClick={() => openEdit(r)}>{v}</a>
        },
        {
            title: "code",
            dataIndex: "payCode",
            width: 120,
        },
        {title: "费率%", dataIndex: "feeRate", width: 100, align: 'center' as 'center'},
        {title: "固定费用", dataIndex: "feeFixed", width: 100, align: 'center' as 'center'},
        {
            title: "状态", dataIndex: "payStatus", width: 100, align: 'center' as 'center',
            render: (v: number) => v === 1 ? <Tag color="green">启用</Tag> : <Tag color="red">停用</Tag>
        },
        {
            title: "推荐", dataIndex: "topping", width: 100, align: 'center' as 'center',
            render: (v: number) => v === 1 ? <Tag color="blue">推荐</Tag> : ""
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            width: 200,
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center'
        }
    ]

    const crumb = [
        {name: '系统管理'},
        {name: '支付渠道'},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain />
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Row className="table-tools">
                                <Col span={18}>

                                    <Space size={16} style={{marginBottom: 16}}>
                                        <Button key="K1"
                                                type="primary"
                                                onClick={() => openEdit()}>
                                            <PlusCircleOutlined/> 新增
                                        </Button>
                                        <Button key="K2"
                                                type="primary"
                                                onClick={loadMainData}>
                                            <RedoOutlined/> 刷新
                                        </Button>
                                    </Space>
                                </Col>
                                <Col span={6} className="right">
                                </Col>
                            </Row>
                            <Table
                                key={'categoryTable'}
                                columns={columns}
                                dataSource={mainData}
                                size={"small"}
                                bordered={true}
                                rowKey={"id"}
                                // record_key="id"
                                // addModelCallback={() => openEdit()}
                                // deleteCallback={deleteRecord}
                                // reloadCallback={loadMainData}
                                // sortCallback={sortRecord}
                                // actionButtons={['addModal', 'reload']}
                                // exButtons={exButtons}
                            />
                        </div>

                        <PaymentEditor ref={formRef}  callback={loadMainData}/>

                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default PaymentConfig;