import React, { useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Upload,
    Image,
    Modal,
    message,
    Select,
    DatePicker,
    InputNumber, Popconfirm
} from "antd";
import {
    MoneyCollectOutlined,
    SaveOutlined,
    PlusOutlined,
    LoadingOutlined,
    ExclamationCircleOutlined,
    ScanOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import {InvoiceDo} from "../model/invoiceDo";
import {UploadFile, UploadProps} from "antd/es/upload/interface";
import 'antd/es/modal/style';
import * as store from "store";
import moment from "moment";
import JsonUtil from "../../../utils/JsonUtil";
import DataUtil from "../../../utils/DataUtil";
import {ContractDo} from "../model/contractDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import {CashFlowDo} from "../model/cashFlowDo";
import SimpleList from "../../admin/components/SimpleList";
import {ListDo} from "../../admin/model/listDo";
import {RewardDo} from "../model/rewardDo";
import {FileDo} from "../model/fileDo";

const {confirm} = Modal

const RewardFrom = (props: any, ref: any) => {

    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    const [formRef] = Form.useForm();

    const [rewardInfo, setRewardInfo] = useState<RewardDo | undefined>(undefined);

    const [showForm, setShowForm] = useState(false);
    let changedValues = false;

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: RewardDo, c?: ContractDo) => openForm(r, c)
        }
    });

    useEffect(() => {
    }, []);

    const openForm = (r: RewardDo, c?: ContractDo) => {
        if (r) {
            let payDate = moment(r.payDate)
            setRewardInfo(r);
            formRef.setFieldsValue({...r,payDate});
        } else {
            setRewardInfo(undefined);
            formRef.resetFields();
            formRef.setFieldsValue({contractId: c?.id});
        }
        setShowForm(true);
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    const onFinishForm = () => {
        let values = formRef.getFieldsValue();
        console.log(values);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/reward/edit', [values], callback);
    }


    const confirmDelete = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/reward/delete', [{id: rewardInfo?.id}], callback);
    }

    return (
        <Drawer placement="right"
                width={600}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12}>
                            <MoneyCollectOutlined/> Cost & Reward
                        </Col>
                        <Col span={12}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64}}
                                    onClick={()=>{formRef.submit()}}
                            >
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>

            <Form id="cashForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 4, offset: 0}}
                  form={formRef}
                  style={{marginRight: 24}}
                  onValuesChange={() => {
                      changedValues = true
                  }}
            >
                <Form.Item hidden name={'id'} initialValue={0}><Input /></Form.Item>
                <Form.Item hidden name={'contractId'} initialValue={0}><Input /></Form.Item>

                <Row>
                    <Col span={24}>
                        <Form.Item name={'payDate'} label={'Date'} rules={[{required:true}]}>
                            <DatePicker  style={{width: 260}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'totalMoney'} label={'Should'}  rules={[{required:true}]}>
                            <InputNumber  style={{width: 260}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'payMoney'} label={'Paid'}>
                            <InputNumber  style={{width: 260}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'totalPay'} label={'Total'}>
                            <InputNumber  style={{width: 260}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'payChannel'} label={'Channel'}>
                            <Input placeholder={''}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'payTo'} label={'To'}>
                            <Input placeholder={''}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'remark'} label={'Remark'}>
                            <Input placeholder={''}/>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{textAlign:"right", marginTop:120}}>
                        <Form.Item name={''} label=" ">
                            <Popconfirm
                                title="您确定要删除这条记录吗？"
                                onConfirm={confirmDelete}
                                // onCancel={cancel}
                                okText="确认删除"
                                cancelText="再想想"
                            >
                                <Button type="primary" danger><DeleteOutlined/> 删除记录 </Button>
                            </Popconfirm>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default forwardRef(RewardFrom);