import {Button, Image, Layout, Space, Table} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import { RedoOutlined, PictureOutlined , UploadOutlined} from "@ant-design/icons";
import NavFooter from "../../components/NavFooter";
import React, {useEffect, useRef, useState} from "react";
import AxiosUtil from "../../utils/AxiosUtil";
import moment from "moment";
import DataUtil from "../../utils/DataUtil";
import {InvoiceDo} from "./model/invoiceDo";
import {InvoiceQuery} from "./model/invoiceQuery";
import InvoiceForm from "./components/InvoiceForm";
import ColumnFilterDateProp from "../../components/ColumnFilterDateProp";
import ColumnFilterNumberProp from "../../components/ColumnFilterNumberProp";
import ColumnFilterSearchProp from "../../components/ColumnFilterSearchProp";
import {ListDo} from "../admin/model/listDo";
import SimpleList from "../admin/components/SimpleList";

const {Content} = Layout;

const Invoice = () => {

    const formRef = useRef();
    const defaultSort = "invoice_date DESC, invoice_number DESC";

    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState<InvoiceDo[]>([]);
    const [preview, setPreview] = useState(false);
    const [previewImg, setPreviewImg] = useState("");
    const [taxRate,setTaxRate] = useState<ListDo[]>([]);
    const [invoiceType,setInvoiceType] = useState<ListDo[]>([]);

    const [invoiceQuery, setInvoiceQuery] = useState({
        pageSize: 20,
        pageNo: 1,
        orderBy: defaultSort,
    } as InvoiceQuery);

    useEffect(() => {
        SimpleList.param(1270, (d) => (
            setTaxRate(d.map((r) => {
                return {text: r.text, value: r.text}
            }))
        ));
        SimpleList.param(1274, (d) => (
            setInvoiceType(d.map((r) => {
                return {text: r.text, value: r.text}
            }))
        ));
    }, []);

    useEffect(() => {
        loadMainData();
    }, [invoiceQuery]);

    const formatMoney = (v: number, v2?: number) => {
        return (
            v ? <span className={v < 0 ? "red" : ""}>
                    {DataUtil.formatNumber(v, 2)}
                    {v2 ? <sup>({v2})</sup> : ""}
            </span>
                : ""
        )
    }

    const openEdit = (r?: InvoiceDo) => {
        console.log(r);
        (formRef.current as any).openForm(r);
    }

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list);
                setTotal(dat.total);
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/invoice/list', invoiceQuery, callback);
        console.log("loadMainData - ok")
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

        let query = {...invoiceQuery};
        switch (extra.action) {
            case "paginate":
                query.pageNo = query.pageSize == pagination.pageSize ? pagination.current : 1;
                query.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order == "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    query.orderBy = defaultSort;
                }
                break;
            case "filter":
                query.invoiceType = filters.invoiceType;
                query.taxRate = filters.taxRate;
                break;
        }
        setInvoiceQuery(query);
    }

    const onSearch = (f: string, v: any) => {
        console.log("onSearch", v);
        let query = {...invoiceQuery};
        switch (f) {
            case "invoiceCode":
                query.invoiceCode = v.value;
                break;
            case "invoiceNumber":
                query.invoiceNumber = v.value;
                break;
            case "buyerName":
                query.buyerName = v.value;
                break;
            case "buyerTaxCode":
                query.buyerTaxCode = v.value;
                break;
            case "contractTitle":
                query.contractTitle = v.value;
                break;
        }
        setInvoiceQuery(query);
    }

    const onSearchNumber = (f: string, v: any) => {
        console.log("onSearchNumber", v);
        let query = {...invoiceQuery};
        switch (f) {
            case "totalMoney":
                query.totalMoneyMin = v.value1 ;
                query.totalMoneyMax = v.value2 ;
                break;
            case "invoiceMoney":
                query.invoiceMoneyMin = v.value1;
                query.invoiceMoneyMax = v.value2;
                break;
            case "taxMoney":
                query.taxMoneyMin = v.value1;
                query.taxMoneyMax = v.value2;
                break;
        }
        setInvoiceQuery(query);
    }

    const onSearchDate = (f: string, v: any) => {
        console.log("onSearchDate", v);
        let query = {...invoiceQuery};
        if (v.value.length !== 2) {
            return
        }
        switch (f) {
            case "invoiceDate":
                query.invoiceDateMin = v.value[0] ? v.value[0].format('yyyy-MM-DD') : null;
                query.invoiceDateMax = v.value[1] ? v.value[1].format('yyyy-MM-DD') : null;
                break;
        }
        setInvoiceQuery(query);
    }


    const onImgPreview = async (img: string) => {
        if (img.substring(img.length - 4).toLowerCase() === '.pdf') {

        } else {
            setPreview(true);
            setPreviewImg(img);
        }
    };

    const crumb = [
        {name: '客户管理'},
        {name: '开票记录'},
    ];

    const sharedOnCell = (r: InvoiceDo) => {
        return {};
    };
    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 48,
        },
        {
            title: "开票日期", dataIndex: "invoiceDate", align: "center" as "center", width: 100,
            sorter: true,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD')
            },
            ...ColumnFilterDateProp("invoiceDate", "开票日期", onSearchDate),
        },
        {
            title: "类型", dataIndex: "invoiceType", align: "center" as "center", width: 80,
            sorter: true,
            ellipsis: true,
            filters: invoiceType, //[{value:"专用发票",text:"专用发票"},{value:"普通发票",text:"普通发票"}],
            filteredValue: invoiceQuery.invoiceType,
        },
        {
            title: "总金额", dataIndex: "totalMoney", align: "right" as "right", width: 88,
            sorter: true,
            render: (v: number, r: InvoiceDo) => (
                <a onClick={() => openEdit(r)} >{formatMoney(v)}</a>
            ),
            ...ColumnFilterNumberProp("totalMoney", "开票金额", onSearchNumber),
        },
        {
            title: "未税", dataIndex: "invoiceMoney", align: "right" as "right", width: 88, sorter: true,
            render: (v: number) => (formatMoney(v)),
            ...ColumnFilterNumberProp("invoiceMoney","未税金额",onSearchNumber),
        },
        {
            title: "税额", dataIndex: "taxMoney", align: "right" as "right", width: 80, sorter: true,
            render: (v: number) => (formatMoney(v)),
            ...ColumnFilterNumberProp("taxMoney","税额",onSearchNumber),
        },
        {
            title: "税率", dataIndex: "taxRate", align: "center" as "center", width: 80, sorter: true,
            filters: taxRate,
            filteredValue: invoiceQuery.taxRate,
        },
        {
            title: "发票代码", dataIndex: "invoiceCode", align: "center" as "center", width: 100, sorter: true,
            ...ColumnFilterSearchProp("invoiceCode", "发票代码", onSearch),
            render: (v: String, r: InvoiceDo) => {
                if(r.invoiceCode == "" && r.invoiceNumber != "") {
                    return {
                        children: r.invoiceNumber,
                        props: {colSpan:2},
                    }
                }else{
                    return r.invoiceCode
                }
            }
        },
        {
            title: "发票号码", dataIndex: "invoiceNumber", align: "center" as "center", width: 100, sorter: true,
            ...ColumnFilterSearchProp("invoiceNumber", "发票号码", onSearch),
            render: (v: String, r: InvoiceDo) => {
                if(r.invoiceCode == "" && r.invoiceNumber != "") {
                    return {
                        props: {colSpan:0},
                    }
                }else{
                    return r.invoiceNumber
                }
            }
        },
        {
            title: "买方名称", dataIndex: "buyerName", width: 120,
            ellipsis: true,
            ...ColumnFilterSearchProp("buyerName", "买方名称", onSearch),
        },
        {
            title: "买方税号", dataIndex: "buyerTaxCode",  width: 120,
            ellipsis: true,
            ...ColumnFilterSearchProp("buyerTaxCode", "买方税号", onSearch),
        },
        {
            title: "合同", dataIndex: "contractTitle", width: 120,
            ellipsis: true,
            render: (v: string, r: InvoiceDo) => (
                <a href={"/#/crm/contract/" + r.contractTitle} >{v}</a>
            ),
            ...ColumnFilterSearchProp("contractTitle", "合同", onSearch),
        },
        {
            title: "存档", dataIndex: "imgUrl", align: "center" as "center", width: 48,
            render: (v: string) => (
            v.substring(v.length - 4).toLowerCase() === '.pdf'?
                <a href={v} target='_blank'><PictureOutlined /></a>
                :<a onClick={()=>onImgPreview(v)}><PictureOutlined /></a>
            ),
        },
    ];

    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Space size={16} style={{marginBottom: 16}}>
                                <Button type="primary" key='showEdit'
                                        onClick={() => openEdit()}><UploadOutlined/> 上传发票</Button>
                                <Button type="primary" key='reload'
                                        onClick={loadMainData}><RedoOutlined/> 刷新</Button>
                            </Space>
                        </div>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               pagination={{
                                   total: total,
                                   pageSize: invoiceQuery.pageSize,
                                   current: invoiceQuery.pageNo
                               }}
                        />
                        <InvoiceForm ref={formRef} callback={loadMainData}/>

                        <Image preview={{
                            visible: preview,
                            src: previewImg,
                            onVisibleChange: value => {
                                setPreview(value);
                            },
                        }}/>

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}
export default Invoice;