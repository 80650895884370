import {PageContainer, ProCard, ProLayout} from '@ant-design/pro-components';
import React, {useState} from "react";
import {Dropdown} from "antd";
import {LogoutOutlined, UnlockOutlined, UserOutlined} from "@ant-design/icons"
import store from "store";
import {useHistory} from "react-router-dom";
import Link from "antd/es/typography/Link";
import * as Icon from "@ant-design/icons";

const New1 = () => {
    const history = useHistory()
    const hashPath = window.location.hash

    const [pathname, setPathname] = useState("/1/95/4");
    const [loading, setLoading] = useState(false)
    const login_info = store.get('login_info');
    const getMenu: any = (funList: any[]) => {
        return funList.map((r: any) => {
            return {
                // key: r.pageUrl,
                // locale: r.pageUrl,
                name: <Link href={`#/${r.pageUrl}`} >{r.functionName}</Link>,
                path: r.functionId.toString(),
                // @ts-ignore
                icon: r.icon in Icon ? React.createElement(Icon[r.icon]) : "/images/icon-eso.png",
                routes: getMenu(r.children),
            }
        })
    }
    const fun = store.get("login_power");
    const routes = getMenu(fun);

    console.log("routes", routes);
    const menus = {path: '/', routes: routes};

    const onTopMixMenuHeaderClick = (e: any) => {
        console.log(e)
    }
    return (
        <ProLayout
            title={"EasyGameNow"}
            logo={false}
            // logo={<img src="https://www.itemd2r.com/images/logo2.webp" alt={""}/>}
            // appList={[{icon:"", title:"A", desc:"", url:"", target: "_blank", children:undefined}]}
            // onMenuHeaderClick={onTopMixMenuHeaderClick}
            headerTitleRender={(logo, title, props)=>
                <div style={{width: 208, marginRight:8}}>{title}</div>}
            token={{
                header: {
                    colorHeaderTitle: 'rgba(255,255,255,0.8)',
                    colorTextMenu: 'rgba(255,255,255,0.8)',
                    colorTextMenuActive: 'rgba(255,255,255,0.8)',
                    colorTextMenuSelected: 'rgba(255,255,255,0.8)',
                    colorTextRightActionsItem: 'rgba(255,255,255,0.8)',
                    colorBgHeader: "rgba(0,0,0,0.75)",
                    colorBgMenuItemHover: 'rgba(255,255,255,0.2)',
                    colorBgMenuItemSelected: 'rgba(255,255,255,0.16)',

                    // colorBgHeader: "rgba(0,0,0,0.03)",
                    // colorBgMenuItemSelected: 'rgba(255,255,255,0.8)',
                },
                pageContainer: {paddingBlockPageContainerContent: 24, paddingInlinePageContainerContent: 24},
                // sider
            }}
            avatarProps={{
                icon: <UserOutlined/>,
                size: 'small',
                title: login_info?.loginName,
                render: (props, dom) => {
                    return (
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: 'password',
                                        icon: <UnlockOutlined/>,
                                        label: '修改密码 ',
                                    },
                                    {
                                        key: 'logout',
                                        icon: <LogoutOutlined/>,
                                        label: '退出登录.........',
                                    },
                                ],
                            }}
                        >
                            {dom}
                        </Dropdown>
                    );
                },
            }}
            layout={"mix"}
            fixSiderbar={true}
            splitMenus={true}
            siderMenuType={"sub"}

            // suppressSiderWhenMenuEmpty={false}
            route={menus}
            // {...defaultProps}
            location={{pathname: pathname}}
            // menuItemRender={(item, dom) => (
                // <Link href={item.path}>{dom}</Link>
                // <div
                //     onClick={() => {
                //         setPathname(item.path || '/');
                //         console.log(item, dom)
                //         history.push(item.locale || "/")
                //
                //     }}
                // >
                //     {dom}
                // </div>
            // )}
            // menu={{}}
            // menuProps={{openKeys:["95","96"], selectedKeys:["1"] }}
        >
            <PageContainer title={false}>
                <ProCard style={{minHeight: 800}}>1
                    <div>{hashPath}</div>
                </ProCard>
            </PageContainer>
        </ProLayout>
    )
}

export default New1;