import {Button, Layout, Space, Table, Tag} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import {
    PlusCircleOutlined,
    RedoOutlined,
    CheckCircleTwoTone,
    ExclamationCircleTwoTone
} from "@ant-design/icons";
import NavFooter from "../../components/NavFooter";
import React, { useEffect, useRef, useState} from "react";
import AxiosUtil from "../../utils/AxiosUtil";
import moment from "moment";
import {ContractDo} from "./model/contractDo";
import ColumnFilterSearchProp from "../../components/ColumnFilterSearchProp";
import {ListDo} from "../admin/model/listDo";
import {ContractQuery} from "./model/contractQuery";
import SimpleList from "../admin/components/SimpleList";
import ContractForm from "./components/ContractForm";
import {CustomerDo} from "./model/customerDo";
import ColumnFilterDateProp from "../../components/ColumnFilterDateProp";
import ColumnFilterNumberProp from "../../components/ColumnFilterNumberProp";
import MoneyUtil from "../../utils/MoneyUtil";
import IconUtil from "../../utils/IconUtil";
import { useParams} from "react-router-dom";

const {Content} = Layout;

const  Contract = (props:any) => {

    const formRef = useRef();
    const defaultSort = "contract_code DESC";

    const {code} = useParams<{ code?: string | undefined}>();
    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState<ContractDo[]>([]);
    const [statusData, setStatusData] = useState<ListDo[]>([]);
    const [typeData, setTypeData] = useState<ListDo[]>([]);
    const [ customerData,setCustomerData ] = useState<ListDo[]>([]);

    const [contractQuery, setContractQuery] = useState<ContractQuery>({
        contractTitle: code,
        pageSize: 20,
        pageNo: 1,
        contractStatus: [79, 80, 81, 82, 83, 1262],
        // contractType: [] as number[],
        orderBy: defaultSort
    } as ContractQuery);


    useEffect(() => {
        loadCustomerData();
        SimpleList.param(78, (d)=>(setStatusData(d)));
        SimpleList.param(100, (d)=>(setTypeData(d)));
    }, []);
    useEffect(() => {
        loadMainData();
    }, [contractQuery]);


    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list);
                setTotal(dat.total);
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/contract/list', contractQuery, callback);
        console.log("loadMainData - ok")
    }

    const loadCustomerData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                const data: ListDo[] = dat.list.map((r: CustomerDo) => {
                    return {
                        text: r.nickname,
                        value: r.id,
                    }
                });
                setCustomerData(data);
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/customer/list', {}, callback);
        console.log("loadCustomerData - ok")
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {
        console.log("pagination", pagination)
        console.log("filters", filters)
        console.log("sorter", sorter)
        console.log("extra", extra)

        let query = {...contractQuery};
        switch (extra.action) {
            case "paginate":
                query.pageNo = query.pageSize == pagination.pageSize ? pagination.current : 1;
                query.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order == "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    query.orderBy = defaultSort;
                }
                break;
            case "filter":
                query.contractType = filters.typeName;
                query.contractStatus = filters.statusName;
                query.customerId = filters.customerNick
                break;
        }
        setContractQuery(query);
    }

    const onSearch = (f: string, v: any) => {
        console.log("onSearch", v);
        let query = {...contractQuery};
        switch (f) {
            case "contractCode":
                query.contractCode = v.value;
                break;
            case "contractTitle":
                query.contractTitle = v.value;
                break;
        }
        setContractQuery(query);
    }

    const onSearchNumber = (f: string, v: any) => {
        console.log("onSearchNumber", v);
        let query = {...contractQuery};
        switch (f) {
            case "totalMoney":
                query.totalMoneyMin = v.value1 ;
                query.totalMoneyMax = v.value2 ;
                break;
            case "invoiceMoney":
                query.invoiceMoneyMin = v.value1;
                query.invoiceMoneyMax = v.value2;
                break;
            case "cashflowMoney":
                query.cashflowMoneyMin = v.value1;
                query.cashflowMoneyMax = v.value2;
                break;
            case "rewardMoney":
                query.rewardMoneyMin = v.value1;
                query.rewardMoneyMax = v.value2;
                break;
        }
        setContractQuery(query);
    }

    const onSearchDate = (f: string, v: any) => {
        console.log("onSearchDate", v);
        let query = {...contractQuery};
        if (v.value.length != 2) {
            return
        }
        switch (f) {
            case "dateSign":
                query.dateSignMin = v.value[0] ? v.value[0].format('yyyy-MM-DD') : null;
                query.dateSignMax = v.value[1] ? v.value[1].format('yyyy-MM-DD') : null;
                break;
            case "dateEnd":
                query.dateEndMin = v.value[0] ? v.value[0].format('yyyy-MM-DD') : null;
                query.dateEndMax = v.value[1] ? v.value[1].format('yyyy-MM-DD') : null;
                break;
        }
        setContractQuery(query);
    }

    const openEdit = (r?: ContractDo) => {
        console.log(r);
        (formRef.current as any).openForm(r);
    }

    const crumb = [
        {name: '客户管理'},
        {name: '合同跟进'},
    ];

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 48,
        },
        {
            title: '编号',
            key: 'contractCode',
            dataIndex: 'contractCode',
            width: 96,
            sorter: true,
            defaultSortOrder: 'descend' as 'descend',
            ...ColumnFilterSearchProp("contractCode", "合同编号", onSearch)
        },
        {
            title: '客户',
            key: 'customerNick',
            dataIndex: 'customerNick',
            width: 80,
            sorter: true,
            filters: customerData,
            filteredValue: contractQuery.customerId
            // filterIcon: <FilterOutlined />
        },
        {
            title: '合同名称',
            key: 'contractTitle',
            dataIndex: 'contractTitle',
            width: 160,
            sorter: true,
            ellipsis: true,
            render: (v: string, r: ContractDo) => {
                return <a onClick={() => openEdit(r)}>{v}</a>
            },
            ...ColumnFilterSearchProp("contractTitle", "合同名称", onSearch)
        },
        {
            title: '文档',
            key: 'fileCount',
            dataIndex: 'fileCount',//fileExt、filePaper
            width: 60,
            align: "center" as "center",
            render: (v: number, r: ContractDo) => {
                return v > 0 ?
                    <a onClick={() => openEdit(r)}>
                        {IconUtil.docIcon(r.fileExt)}
                        <sup>({v})&nbsp;</sup>
                        {r.filePaper == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a"/>
                            : r.filePaper == 0 ? <ExclamationCircleTwoTone twoToneColor="#fa8c16"/>
                                : ''}
                    </a>
                    : ""
            },
        },

        {
            title: "类型",
            dataIndex: "typeName",
            width: 60,
            // sorter: true,
            align: "center" as "center",
            filters: typeData,
            filteredValue: contractQuery.contractType
        },
        {
            title: "状态",
            dataIndex: "statusName",
            width: 60,
            // sorter: true,
            align: "center" as "center",
            filters: statusData,
            filteredValue: contractQuery.contractStatus,
            render: (v:string, r: ContractDo) => <Tag color={r.statusColor}>{v}</Tag>
        },
        {
            title: "签订日期", dataIndex: "dateSign", width: 100,
            sorter: true,
            align: "center" as "center",
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD')
            },
            ...ColumnFilterDateProp("dateSign", "签订日期", onSearchDate),
            // filteredValue: contractQuery.dateSignMin
        },
        {
            title: "截止日期", dataIndex: "dateEnd", width: 100,
            sorter: true,
            align: "center" as "center",
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD')
            },
            ...ColumnFilterDateProp("dateEnd", "截止日期", onSearchDate),
        },
        {
            title: "金额",
            dataIndex: "totalMoney",
            width: 88,
            sorter: true,
            align: "right" as "right",
            render: (v: number) => (MoneyUtil.format(v)),
            ...ColumnFilterNumberProp("totalMoney","合同金额",onSearchNumber),
        },
        {
            title: "开票",
            dataIndex: "invoiceMoney",
            width: 100,
            sorter: true,
            align: "right" as "right",
            render: (v: number, r: any) => (MoneyUtil.format(v, r.invoiceCount)),
            onCell: (r: ContractDo) => {
                return (r.invoiceMoney != r.totalMoney) ? {className: "cell-unbalance"} : {}
            },
            ...ColumnFilterNumberProp("invoiceMoney","开票金额",onSearchNumber),
        },
        {
            title: "到账",
            dataIndex: "cashflowMoney",
            width: 100,
            sorter: true,
            align: "right" as "right",
            render: (v: number, r: any) => (MoneyUtil.format(v, r.cashflowCount)),
            onCell: (r: ContractDo) => {
                return (r.invoiceMoney != r.cashflowMoney) ? {className: "cell-unbalance"} : {}
            },
            ...ColumnFilterNumberProp("cashflowMoney","到账金额",onSearchNumber),
        },
        {
            title: "Cost",
            dataIndex: "rewardMoney",
            width: 48,
            align: "center" as "center",
            render: (v: number, r: ContractDo) => {
                return v>0 ?
                    v==r.rewardPay ? <CheckCircleTwoTone twoToneColor="#52c41a"/>
                    : <ExclamationCircleTwoTone twoToneColor="#fa8c16"/>
                    : null
            }
        },
    ]

    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Space size={16} style={{marginBottom: 16}}>
                                <Button type="primary" key='showEdit'
                                        onClick={() => openEdit()}><PlusCircleOutlined/> 新增</Button>
                                <Button type="primary" key='reload'
                                        onClick={loadMainData}><RedoOutlined/> 刷新</Button>
                            </Space>
                        </div>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               showSorterTooltip={false}
                               pagination={{
                                   // onChange: this.onPageChange,
                                   total: total,
                                   pageSize: contractQuery.pageSize,
                                   current: contractQuery.pageNo
                               }}
                        />

                        <ContractForm ref={formRef} callback={loadMainData} />

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default Contract