import DataUtil from "./DataUtil";
import React from "react";

export default class MoneyUtil {

    static format = (v: number, v2?: number) => {
        return (
            v ? <span className={v < 0 ? "red" : ""}>
                    {DataUtil.formatNumber(v, 2)}
                    {v2 ? <sup>({v2})</sup> : ""}
            </span>
                : ""
        )
    }
}