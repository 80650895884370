import {Button, Form, InputNumber, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import { SearchOutlined} from "@ant-design/icons";
import AxiosUtil from "../../../utils/AxiosUtil";

interface Props {
    sku: any
}

const ItemSkuPrice = (props: Props) => {

    const [queryForm] = Form.useForm();
    const [skuForm] = Form.useForm();

    const [mainQuery, setMainQuery] = useState<any>();
    const [skuList, setSkuList] = useState<any[]>();

    useEffect(() => {
        loadSkuData(props.sku)
    }, [props])

    const loadSkuData = (sku: any) => {
        if (!sku.itemId) {
            return;
        }

        AxiosUtil.AxiosRequest('/mallApi/itemSku/listView',
            {id: sku.itemId, prop2: sku.prop2, pageSize: 100},
            (suc: boolean, d: any) => {
                if (suc) {
                    setSkuList(d.list);
                }
            });
    }

    const submitQuery = () => {
        let values = queryForm.getFieldsValue();
        if (values.categories && values.categories.length >= 1) {
            values.categoryId1 = values.categories[0];
            if (values.categories.length >= 2) {
                values.categoryId2 = values.categories[1];
            } else {
                values.categoryId2 = null;
            }
        } else {
            values.categoryId1 = null;
            values.categoryId2 = null;
        }
        setMainQuery({...mainQuery, pageNo: 1, ...values});
    }

    const skuColumns = [
        {
            title: '物品名称',
            dataIndex: 'skuName',
            width: 200,
        },
        {
            title: '平台',
            dataIndex: 'prop1Name',
            width: 100,
        },
        {
            title: "来源",
            dataIndex: 'source',
            width: 100,
        },
        {
            title: '价格系数',
            dataIndex: 'skuPriceCoefficient_',
            width: 100,
            render: (v: number, r: any) =>
                <Form.Item style={{marginBottom: 0}}
                           initialValue={v || 1}
                           name={"skuPriceCoefficient_" + r.skuId}>
                    <InputNumber/>
                </Form.Item>
        },
        {
            title: '原始价格',
            dataIndex: 'skuPriceOrig',
            width: 100,
            render: (v: number, r: any) =>
                <Form.Item style={{marginBottom: 0}} initialValue={v}
                           name={"priceOrig_" + r.skuId}>
                    <InputNumber/>
                </Form.Item>
        },
    ]
    return (
        <div id="mainTable">

            <Form form={queryForm}
                  onFinish={submitQuery}
                  layout="horizontal"
                  className={"queryForm"}
                  labelCol={{span: 8, offset: 0}}
                  colon={false}>
                <Row gutter={24}>
                    {/*<Col span={4}>*/}
                    {/*    <Form.Item name="text" label="物品名称">*/}
                    {/*        <Input allowClear={true}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col span={4}>*/}
                    {/*    <Form.Item name="source" label="来源">*/}
                    {/*        <Input placeholder="输入物品名称" allowClear={true}/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col span={2}>*/}
                    {/*    <Button type={"primary"} htmlType={"submit"}><SearchOutlined/> 查询</Button>*/}
                    {/*</Col>*/}
                </Row>
            </Form>

            <Form id="priceForm"
                  // onFinish={onFinishForm}
                  form={skuForm}>
                <Table key={'editableTable1'}
                       rowSelection={{type: 'checkbox',}}
                       bordered={true}
                       size="small"
                       rowKey={'skuId'}
                       columns={skuColumns}
                       dataSource={skuList}
                       pagination={false}
                />
            </Form>
            <br/>
            <br/>
            <Button type={"primary"} htmlType={"submit"}><SearchOutlined/> 查询更多同名SKU</Button>
        </div>
    )
}

export default ItemSkuPrice