
import '@wangeditor/editor/dist/css/style.css'
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {Button, Checkbox, Col, Drawer, Form, Input, InputNumber, message, Modal, Row, Table, Tabs} from "antd";
import AxiosUtil from "../../../utils/AxiosUtil";

import '../../css/InfoEditor.less'
import {ExclamationCircleOutlined, FormOutlined, PlusCircleOutlined, SaveOutlined,CloseCircleOutlined} from "@ant-design/icons";
import store from "store";
import {ItemDo} from "../model/ItemDo";
import {ListDo} from "../../admin/model/listDo";
import {ItemSkuDo} from "../model/ItemSkuDo";
import FetchUtil from "../../../utils/FetchUtil";
import LanguageUtil from "../../../utils/LanguageUtil";
import EditorForm, {EditorFieldProp} from "../../../components/EditorForm";


const {confirm} = Modal
const defaultLanguage = store.get("defaultLang");

interface IProps {
    inputFields: EditorFieldProp[];
    category?: ListDo[];
    prop1Data?: ListDo[];
    prop2Data?: ListDo[];
    callback: () => void;
    multiLanguage: boolean;
}


const ItemEditor = (props: IProps, ref: any) => {

    const editorFormRef = useRef();
    const [skuFormRef] = Form.useForm();
    const [packageFormRef] = Form.useForm();
    // const [class1, setClass1] = useState(0);
    const [itemData, setItemData] = useState<any>();
    const [itemMulti, setItemMulti] = useState<any>();
    const [skuData, setSkuData] = useState<ItemSkuDo[]>([]);
    const [packageData, setPackageData] = useState<any[]>([]);

    const [showForm, setShowForm] = useState(false);
    const [showTitle, setShowTitle] = useState("");


    const [language, setLanguage] = useState(defaultLanguage);

    let changedValues = false;

    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";
    const langData = LanguageUtil.getLangData();

    const createSkuData = async (gameId: number) => {

        const d = await FetchUtil.awaitFetch("/mallApi/category/list", {parentId: 10095}); // TODO 这里临时写死
        const prop1 = d.data.list.find((r: any) => r.categoryCode == "platform");
        const prop2 = d.data.list.find((r: any) => r.categoryCode == "server");
        if (prop1 && prop1.id && prop2 && prop2.id) {

            const list1 = await FetchUtil.awaitFetch("/mallApi/category/list", {parentId: prop1.id});
            const list2 = await FetchUtil.awaitFetch("/mallApi/category/list", {parentId: prop2.id});
            const skuList: ItemSkuDo[] = [];
            list1.data.list.forEach((p1: any) => {
                list2.data.list.forEach((p2: any) => {
                    skuList.push({
                        prop1: p1.id,
                        prop2: p2.id,
                        prop1Code: p1.categoryCode,
                        prop2Code: p2.categoryCode,
                        prop1Name: p1.categoryName,
                        prop2Name: p2.categoryName,
                    })
                })
            })
            setSkuData(skuList);
        }
    }

    // Call from the parent component
    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ItemDo) => openForm(r)
        }
    });

    const openForm = (r: ItemDo) => {
        console.log(r);
        setLanguage(defaultLanguage)
        // formRef.resetFields();
        // setHtml("");
        setSkuData([]);
        // setFileList([]);

        if (r && r.itemId) {
            loadItemData(r);
            // loadSkuData(r);
            // createSkuData(r.classId1);
        } else {
            setItemData(r);
            setItemMulti([r]);
            createSkuData(r.classId1);
        }
        // setClass1(r.classId1);
        setShowForm(true);
    }

    const loadItemData = (r: ItemDo) => {
        AxiosUtil.AxiosRequest('/mallApi/item/detailMulti', {id: r.itemId},
            (suc, data: any) => {
                if (suc && data) {
                    setItemMulti(data);
                    // let item1 = data.items.find((r1: any) => r1.langCode == defaultLanguage);
                    let categorise = data.categories.map((r2: any) =>
                        r2.categoryId1 == null ? [r2.classId2]
                            : r2.categoryId2 == null ? [r2.classId2, r2.categoryId1]
                                : [r2.classId2, r2.categoryId1, r2.categoryId2]
                    )
                    let itemTitle = "";
                    data.items.forEach((i: any) => {
                        if (i.langCode == "en") {
                            itemTitle = i.itemName + itemTitle;
                            r = i;
                            if (i.properties != null && i.properties != "") {
                                const p = JSON.parse(i.properties)
                                console.log(p)
                                setPackageData(p);
                            }

                        } else if (i.langCode == "cn") {
                            itemTitle = itemTitle + "（" + i.itemName + "）";
                        }
                    })
                    setShowTitle(itemTitle);
                    setItemDataFormat(r, categorise);
                    setSkuData(data.skus.list);
                }
            });
    }
    const setItemDataFormat = (r: any, categorise: any) => {
        r = {...r, categorise: categorise, onSell: r.onSell == 1 ? "checked" : ""};
        setItemData(r);
    }

    //
    // const loadSkuData = (r: ItemDo) => {
    //     if (!r.itemId) {
    //         return;
    //     }
    //     AxiosUtil.AxiosRequest('/mallApi/itemSku/listView', {id: r.itemId, pageSize: 100},
    //         (suc: boolean, d: any) => {
    //             if (suc) {
    //                 setSkuData(d.list);
    //             }
    //         });
    // }

    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    const onSave = () => {
        // (editorFormRef.current as any).submitForm();

        let values = (editorFormRef.current as any).getFormValue();
        console.log(values);
        values.categorise = null;
        values.updateTime = null;
        values.onSell = values.onSell ? 1 : 0;
        if (!values.itemCode || values.itemCode == "") {
            values.itemCode = values.itemName;
        }
        let editInfo;
        if (itemMulti.items == undefined || itemMulti.items.length == 0) {
            values.langCode = defaultLanguage;
            editInfo = [values];
        } else {
            editInfo = itemMulti.items.map((r: any) => {
                if (r.langCode === language) {
                    r = {...r, ...values};
                }
                r.categorise = null;
                r.updateTime = null;
                r.onSell = r.onSell ? 1 : 0;
                return r;
            });

        }
        console.log(editInfo)
        AxiosUtil.AxiosRequest('/mallApi/item/edit', editInfo,
            (suc, dat) => {
                if (suc) {
                    changedValues = false;
                    closeForm();
                    message.success("保存成功！")
                    if (props.callback) {
                        props.callback();
                    }
                }
            });
    }
    const onFinishForm = () => {
        // let values = formRef.getFieldsValue();
        let values = (editorFormRef.current as any).getFormValue();

        values.onSell = values.onSell ? 1 : 0;
        //values.category = values.categorise.length == 0 ? null : values.categorise.length == 1 ? values.categorise[0] : values.categorise[1];
        if (!values.itemCode || values.itemCode == "") {
            values.itemCode = values.itemName;
        }
        console.log(values);
        let editInfo;
        if (itemMulti == undefined || itemMulti.length == 0) {
            values.langCode = defaultLanguage;
            editInfo = [values];
        } else {
            editInfo = itemMulti.map((r: any) => {
                if (r.langCode === language) {
                    r = {...r, ...values};
                }
                return r;
            });

        }
        AxiosUtil.AxiosRequest('/mallApi/item/edit', editInfo,
            (suc, dat) => {
                if (suc) {
                    changedValues = false;
                    closeForm();
                    message.success("保存成功！")
                    if (props.callback) {
                        props.callback();
                    }
                }
            });

        const skuList: any[] = [];
        Object.keys(values).forEach(key => {
            if (key.length >= 9 && key.substring(0, 9) === "sku_price") {
                const props = key.split("_");
                const sku: any = {};
                sku.itemId = values.id;
                sku.price = values[key];
                sku.onSell = values[key.replace("sku_price", "sku_onsell")] ? 1 : 0;

                if (props[2] == undefined || props[2] === "" || props[2] === "undefined") {
                    // sku.prop1 = props[3];
                    // sku.prop2 = props[4];
                    sku.properties = "[{\"key\":\"platform\",\"id\":\"" + props[3] + "\"},{\"key\":\"server\",\"id\":\"" + props[4] + "\"}]"
                } else {
                    sku.id = props[2];
                }
                skuList.push(sku);
            }
        })

        // AxiosUtil.AxiosRequest('/mallApi/item/editItemSku', {item: values, skuList: skuList},
        //     (suc, data) => {
        //         if (suc) {
        //             if (props.callback) {
        //                 props.callback();
        //             }
        //             changedValues = false;
        //             closeForm();
        //         }
        //     });
    }
    let hidden = <></>;

    //#region -- Sku --
    // const deleteSku = (id: string) => {
    //     AxiosUtil.AxiosRequest('/mallApi/itemSku/delete', [id],
    //         (suc: boolean, dat: any) => {
    //             if (suc) {
    //                 console.log(suc, dat)
    //                 message.success({content: '记录已删除成功！', className: 'message_success'});
    //                 loadSkuData(itemData);
    //             }
    //         });
    // }
    // const saveSku = (record: any) => {
    //     console.log('save:', record);
    //     const callback = (suc: boolean, dat: any) => {
    //         if (suc) {
    //             console.log(suc, dat)
    //             message.success({content: '保存成功！', className: 'message_success'});
    //             loadSkuData(itemData);
    //         }
    //     }
    //     const newRecord = {
    //         id: record.skuId,
    //         itemId: record.itemId,
    //         skuName: record.skuName,
    //         price: record.skuPrice,
    //         onSell: record.skuOnSell,
    //         properties: JSON.stringify([
    //             {id: record.prop1, key: "platform"},
    //             {id: record.prop2, key: "server"}
    //         ])
    //     }
    //     AxiosUtil.AxiosRequest('/mallApi/itemSku/edit', [newRecord], callback);
    // }

    // const onSellData: any[] = [
    //     {value: 1, label: '上架'},
    //     {value: 0, label: '下架'},
    // ]

    const skuColumns = [
        {
            title: 'ID',
            dataIndex: 'skuId',
            width: 60,
            align: 'center' as 'center',
        },
        // {
        //     title: 'Sku',
        //     dataIndex: 'skuName',
        //     width: 100,
        //     editor: {type: 'text', required: true}
        // },
        {
            title: 'Platform',
            dataIndex: 'prop1Name',
            width: 120,
            ellipsis: true,
            sorter: (a: any, b: any) => a.prop1Name.localeCompare(b.prop1Name),
        },
        {
            title: 'Server',
            dataIndex: 'prop2Name',
            ellipsis: true,
            width: 120,
            sorter: (a: any, b: any) => a.prop2Name.localeCompare(b.prop2Name),
        },
        {
            title: '价格系数',
            dataIndex: 'skuPriceCoefficient',
            width: 80,
            align: 'center' as 'center',
            render: (v: number, r: ItemSkuDo, i: number) => (
                <Form.Item key={`sku_coef_${r.skuId}_${r.prop1}_${r.prop2}`}
                           name={`sku_coef_${r.skuId}_${r.prop1}_${r.prop2}`}
                           initialValue={v || 1}
                           style={{marginBottom: 0}}
                           rules={[{required: false}]}>
                    <InputNumber style={{width: 72, textAlign: "right"}}></InputNumber>
                </Form.Item>
            ),
            sorter: (a: any, b: any) => a.skuPriceCoefficient - b.skuPriceCoefficient,
        },
        {
            title: '价格USD',
            dataIndex: 'skuPrice',
            width: 80,
            align: 'center' as 'center',
            render: (v: number, r: ItemSkuDo, i: number) => (
                <Form.Item key={`sku_price_${r.skuId}_${r.prop1}_${r.prop2}`}
                           name={`sku_price_${r.skuId}_${r.prop1}_${r.prop2}`}
                           initialValue={v}
                           style={{marginBottom: 0}}
                           rules={[{required: false}]}>
                    <InputNumber style={{width: 72}}></InputNumber>
                </Form.Item>
            ),
            sorter: (a: any, b: any) => a.skuPrice - b.skuPrice,
        },
        {
            title: '原始价',
            dataIndex: 'skuPriceOrig',
            width: 60,
            align: 'center' as 'center',
            render: (v: number, r: any) => r.unit && r.unit != "" ? <>{v}/{r.unit}</> : v
        },
        {
            title: '上架',
            dataIndex: 'skuOnSell',
            width: 60,
            align: 'center' as 'center',
            render: (v: number, r: ItemSkuDo) => (
                <Form.Item key={`sku_onsell_${r.skuId}_${r.prop1}_${r.prop2}`}
                           name={`sku_onsell_${r.skuId}_${r.prop1}_${r.prop2}`}
                           style={{marginBottom: 0}}
                           valuePropName={"checked"}
                           initialValue={v == 1}
                >
                    <Checkbox defaultChecked={v == 1}/>
                </Form.Item>
            ),
            // editor: {
            //     type: 'select',
            //     idField: 'skuOnSell',
            //     valueField: 'value',
            //     textField: 'label',
            //     data: onSellData,
            //     required: true
            // }
        },
    ]

    const packageColumns = [
        {
            title: '数量',
            dataIndex: 'amount',
            width: 60,
            align: 'center' as 'center',
            render: (v: number, r: any, i: number) => (
                <Form.Item key={`amount_${i}`}
                           name={`amount_${i}`}
                           initialValue={v}
                           style={{marginBottom: 0}}
                           rules={[{required: true}]}>
                    <InputNumber style={{width: 150}}></InputNumber>
                </Form.Item>
            ),
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 60,
            align: 'center' as 'center',
            render: (v: string, r: any, i: number) => (
                <Form.Item key={`unit_${i}`}
                           name={`unit_${i}`}
                           initialValue={v}
                           style={{marginBottom: 0}}
                           rules={[{required: true}]}>
                    <Input style={{width: 100, textAlign: "center"}}></Input>
                </Form.Item>
            ),
        },
        {
            title: '折扣%',
            dataIndex: 'discount',
            width: 60,
            align: 'center' as 'center',
            render: (v: string, r: any, i: number) => (
                <Form.Item key={`discount_${i}`}
                           name={`discount_${i}`}
                           initialValue={v}
                           style={{marginBottom: 0}}
                           rules={[{required: true}]}>
                    <InputNumber style={{width: 100}}></InputNumber>
                </Form.Item>
            ),
        },
        {
            title: '删除',
            dataIndex: '_del',
            width: 60,
            align: 'center' as 'center',
            render: (v: string, r: any, i: number) => <CloseCircleOutlined onClick={() => deletePackage(i)}/>
        },
    ]
    const addPackage = () => {
        setPackageData([
            ...packageData,
            {"amount": "", "unit": skuData[0].unit, "discount": "0"}
        ]);
    }
    const deletePackage = (i: number) => {
        const p = packageData.filter((r: any, index) => {
            return index != i
        })
        setPackageData(p)
        // setTimeout(()=>{
        // p.forEach((r: any, index) => {
        //     packageFormRef.setFieldsValue({
        //         ["amount_" + i]: r.amount,
        //         ["unit_" + i]: r.unit,
        //         ["discount_" + i]: r.discount,
        //     })
        // })
        // }, 1000)
    }
    //#endregion

    const onTabChange = (key: string) => {
        let values = (editorFormRef.current as any).getFormValue()
        // props.inputFields.forEach((field) => {
        //     if (field.type === "rich") {
        //         values[field.name] = editor?.getHtml();
        //     }
        // })
        // Save the current datas to itemMulti array as old info
        const oldInfos = itemMulti.items.map((r: any) => {
            if (r.langCode === language) {
                r = {...r, ...values};
            }
            return r;
        });
        let newInfo: any = itemMulti.items.find((r: any) => r.langCode === key)
        if (newInfo === undefined) {
            // if the new info does not found, create a new record and save to the itemMulti array
            newInfo = {
                parentId: itemData?.id,
                langCode: key
            }
            oldInfos.push(newInfo);
        }
        setLanguage(key);
        setItemMulti({...itemMulti, items: oldInfos});
        setItemDataFormat(newInfo, values.categorise);
    }


    return (

        <Drawer placement="right"
                width={1000}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={18} style={{padding: 8}}>
                            <FormOutlined/> 内容编辑 - <span className={"blue"}>{showTitle}</span>
                        </Col>
                        <Col span={6}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64, marginTop: 0, marginBottom: 0}}
                                    onClick={onSave}>
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>

            <Tabs activeKey={language}
                  items={langData}
                  onChange={onTabChange}
                  style={{marginBottom: 24}}
                  centered
                  hidden={!props.multiLanguage}
                  size={"small"}/>

            <EditorForm ref={editorFormRef}
                        inputFields={props.inputFields}
                        initData={itemData}
                        changeCallback={() => changedValues = true}
                        submitCallback={onFinishForm}
            />

            <Form id="skuForm"
                  form={skuFormRef}
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 4, offset: 0}}
                  onValuesChange={() => changedValues = true}
            >
                <div style={{fontWeight: 600, padding: 16}}>{`Total sku: ${skuData.length}`}</div>
                <Table key={'editableTable1'}
                       bordered={true}
                       size="small"
                       rowKey={'id'}
                       columns={skuColumns}
                       dataSource={skuData}
                       pagination={false}
                />
            </Form>
            {["iggm_eso_goods", "iggm_one_goods", "fc24coins", "iggm_wow_goods"].includes(itemMulti?.items[0]?.mappingCode) &&
                <Form id="packageForm"
                      form={packageFormRef}
                      layout="horizontal"
                      colon={false}
                      onFinish={onFinishForm}
                      labelCol={{span: 4, offset: 0}}
                      onValuesChange={() => changedValues = true}
                >
                    <div style={{fontWeight: 600, padding: 16}}>金币包设置</div>
                    <Table key={'editableTable2'}
                           bordered={true}
                           size="small"
                           rowKey={'id'}
                           columns={packageColumns}
                           dataSource={packageData}
                           pagination={false}
                    />
                    <Button key="K1"
                            type="primary"
                            onClick={addPackage}>
                        <PlusCircleOutlined/> 添加
                    </Button>
                </Form>}

            <p><br/><br/><br/></p>
        </Drawer>
    )
}

export default forwardRef(ItemEditor)