import React, { useEffect, useRef, useState} from "react";
import { Button, Col, Form, Input, Layout,
    message, Row, Select, Space, Spin, Table, Tooltip,} from "antd";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavBreadcrumb from "../../../components/NavBreadcrumb";

import {BaseInfoDo} from "../../admin/model/BaseInfoDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import moment from "moment";
import {CopyOutlined, SearchOutlined,} from "@ant-design/icons";
import copyToClipboard from "copy-to-clipboard";
import {ListDo} from "../../admin/model/listDo";
import Link from "antd/es/typography/Link";
import OrderDelivery from "../components/OrderDelivery";
import ReactHtmlParser from "react-html-parser";
import {itemWeb} from "../../../config";

const {Content} = Layout;


const OrderList = (props: any) => {

    // const {typeId, sourceId} = useParams<any>();
    const formRef = useRef();
    const [queryForm] = Form.useForm();

    const [mainData, setMainData] = useState<BaseInfoDo[]>([]);
    const [mainQuery, setMainQuery] = useState<any>();
    const [mainLoading, setMainLoading] = useState<any>(false);
    const [currentMenu, setCurrentMenu] = useState<any>();
    const [categoryTree, setCategoryTree] = useState<ListDo[]>([]);

    useEffect(()=>{
        loadParams();
    },[])

    let type = props.match.params.type || "";
    useEffect(() => {
        type = props.match.params.type || "";
        setMainQuery({
            order: {},
            page: {pageNo: 1, pageSize: 20}
        })
    }, [props])

    useEffect(() => {
        loadMainData()
    }, [mainQuery])


    const loadParams = () => {
        AxiosUtil.AxiosRequest("/mallApi/category/listTree",{parentId: 1},
            (suc, data) => {
                setCategoryTree(data);
            })
    }

    const loadMainData = () => {
        if (!mainQuery) {
            return;
        }
        setMainLoading(true);
        AxiosUtil.AxiosRequest('/mallApi/order/list', mainQuery,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list);
                }
                setMainLoading(false);
            });
    }

    const submitQuery = () => {
        let values = queryForm.getFieldsValue();
        setMainQuery({
            order: {
                orderClass1: values.orderClass1,
                orderNo: values.orderNo,
                deliveryStatus: values.deliveryStatus
            },
            page: {pageNo: 1, pageSize: 20}
        })
    }

    const copyValue = (v: string) => {
        copyToClipboard(v);
        message.success('复制成功');
    }

    const openOrderDeliver = (r?: any) => {
        console.log(r);
        if (!r) {
            r = {
                infoType: currentMenu.paramId,
                categoryId: currentMenu.gameId
            } as BaseInfoDo;
        }
        (formRef.current as any).openForm(r);
        // setShowEdit(true);
    }

    const columns: any[] = [
        {
            title: '订单号',
            dataIndex: 'id',
            width: 200,
            fixed: 'left',
            render: (v:string,r: any) => <>
            <Link style={{fontWeight: 600}} onClick={()=>openOrderDeliver(r)}>
                {r.orderNo}<br/>
                {r.class1Code.substring(0,2).toUpperCase()}-{v}
            </Link>
            </>
        },
        {
            title: '商品信息',
            dataIndex: 'orderNo',
            width: 200,
            render: (v:string,r: any) => <>
                <Link style={{fontWeight: 600}} onClick={()=>openOrderDeliver(r)}>
                    <Space split={"/"}>
                        {r.itemQuantity > 0 && <span>物品({r.itemQuantity})</span>}
                        {r.goldQuantity > 0 && <span>金币({r.goldQuantity})</span>}
                    </Space>
                    &nbsp; <SearchOutlined />
                </Link>
                <br/>
                {r.class1Name}<br/>
                {r.prop1Name} - {r.prop2Name}
            </>
        },
        {
            title: '递送信息',
            dataIndex: 'buyerAddress',
            width: 200,
            render: (v:string,r: any) => <>
                {ReactHtmlParser(r.buyerAddress?.replaceAll("\n", "<br />"))}
                <br/>
                    <a className='copy-icon' onClick={() => copyValue(r.buyerAddress?.replaceAll("\\n", "\n"))}>
                         - Copy <CopyOutlined/></a>
            </>
        },
        {
            title: '价格',
            dataIndex: 'totalAmount',
            width: 200,
            render: (v:string,r: any) => <>
                订单总价: {r.totalAmount?.toFixed(2)}{r.currency} <br/>
                商品总价: {r.totalPrice?.toFixed(2)}{r.currency} <br/>
                供货总价: <br/>
                商品毛利率:
            </>
        },
        {
            title: '用户',
            dataIndex: 'buyerEmail',
            width: 200,
            render: (v:string,r: any) => <>
                {v}<br/>{r.ip} - {r.ipCountry} <br/>
                历史订单: <br/>优惠券：<b className="red">{r.couponInfo}</b> </>
        },
        {
            title: '付款',
            dataIndex: 'payChannel',
            width: 100,
            align: 'center' as 'center'
        },
        {
            title: '状态',
            dataIndex: 'payStatusName',
            width: 100,
            align: 'center' as 'center'
        },
        {
            title: '递送',
            dataIndex: 'deliveryStatusName',
            width: 100,
            align: 'center' as 'center'
        },
        {
            title: '结算',
            dataIndex: '',
            width: 100,
            align: 'center' as 'center',
            render: (v: string) => <>未结算</>,
        },
        {
            title: '下单时间',
            dataIndex: 'createTime',
            width: 150,
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center'
        },
        {
            title: '付款时间',
            dataIndex: 'payTime',
            width: 150,
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center'
        },
        {
            title: '递送时间',
            dataIndex: 'deliveryTime',
            width: 150,
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center'
        },
        {
            title: '结算时间',
            dataIndex: 'settleTime',
            width: 150,
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center'
        },
    ];

    const crumb = [
        {name: '系统管理'},
        {name: '订单管理'},
        {name: itemWeb}
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">

                            <Form form={queryForm}
                                  onFinish={submitQuery}
                                  layout="horizontal"
                                  className={"queryForm"}
                                  labelCol={{span: 8, offset: 0}}
                                  colon={false}>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Form.Item name="orderClass1" label="游戏">
                                            <Select options={categoryTree}
                                                      placeholder="全部"
                                                      allowClear={true}
                                                      fieldNames={{label: "text", value: "id"}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="orderNo" label="订单号">
                                            <Input allowClear={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="deliveryStatus" label="递送状态">
                                            <Select allowClear={true}
                                                    placeholder="全部"
                                                    options={[
                                                {label: "未递送", value: "0"},
                                                {label: "已递送", value: "1"},
                                                {label: "部分递送", value: "2"},
                                            ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Button type={"primary"} htmlType={"submit"}><SearchOutlined/> 查询</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Table loading={mainLoading}
                                   size={"small"}
                                   bordered={true}
                                   key={'Table1'}
                                   columns={columns}
                                   dataSource={mainData}
                                   rowKey={"id"}
                                   scroll={{x: 1400}}
                                   pagination={{
                                       total: mainData?.length,
                                       pageSize: mainQuery?.pageSize,
                                       current: mainQuery?.pageNo,
                                       showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                                       showQuickJumper: true
                                   }}
                            />
                        </div>

                        <OrderDelivery ref={formRef} callback={loadMainData} />

                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default OrderList;

