import React, {useState} from "react";
import ReactJson from "react-json-view";
import {Col, Row, Switch} from "antd";
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';

interface IProps {
    label: string | undefined,
    rawData: string | undefined,

}

const RawDataViewer = (props: IProps) => {

    const [pretty, setPretty] = useState(true);
    // const dataString = props.rawData;
    let dataJson = undefined;

    React.useEffect(() => {
        document.querySelectorAll("pre code").forEach(block => {
            try {
                hljs.highlightBlock(block as HTMLElement);
            } catch (e) {
                console.log(e);
            }
        });
    });

    if (props.rawData !== undefined) {
        try {
            dataJson = JSON.parse(props.rawData);
        } catch {
        }
    }

    const onChange = (checked: boolean) => {
        setPretty(checked);
    };
    return (
        <Row>
            <Col span={4} style={{textAlign: "right", paddingRight: 8}}>{props.label}</Col>
            <Col span={20}>
                <Switch defaultChecked onChange={onChange}/> Pretty
            </Col>
            <Col span={24}>
                {!pretty
                    ? <div style={{padding: 8, margin: 8, backgroundColor: '#f6f7f8'}}>{props.rawData}</div>
                    : dataJson === undefined
                        // ? <div style={{padding: 8, margin: 8}}>{props.rawData}</div>
                        ? <div style={{margin: 8}}><pre><code className="language-html">{props.rawData}</code></pre></div>
                        : <ReactJson src={dataJson}
                                     theme='apathy:inverted'
                                     iconStyle='square'
                                     name={false}
                                     displayDataTypes={false}
                                     style={{padding: 8, margin: 8}}/>
                    // apathy:inverted monokai codeschool
                }
            </Col>
        </Row>
    );
}

export default RawDataViewer;