import React from 'react';
import './pages/css/index.less';
import './pages/css/main.less'
import App from './App';
import reportWebVitals from './reportWebVitals';
import zhCN from "antd/es/locale-provider/zh_CN";
import {ConfigProvider} from "antd";
import {createRoot} from "react-dom/client";


// Warning: ReactDOM.render is no longer supported in React 18.
// Use createRoot instead. Until you switch to the new API, your app will behave as if it's running React 17.
// Learn more: https://reactjs.org/link/switch-to-createroot
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <ConfigProvider locale={zhCN}>
        <App/>
    </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
