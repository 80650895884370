import React, { useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {Button, Col, Drawer, Form, Input, Row,  Modal, Select} from "antd";
import {MoneyCollectOutlined, SaveOutlined,  ExclamationCircleOutlined} from "@ant-design/icons";
import 'antd/es/modal/style';
import * as store from "store";
import moment from "moment";
import DataUtil from "../../../utils/DataUtil";
import {ContractDo} from "../model/contractDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import {CashFlowDo} from "../model/cashFlowDo";
import SimpleList from "../../admin/components/SimpleList";
import {ListDo} from "../../admin/model/listDo";

const {confirm} = Modal

const InvoiceFrom = (props: any, ref: any) => {

    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    const [formRef] = Form.useForm();

    const [cashFlowInfo, setCashFlowInfo] = useState<CashFlowDo | undefined>(undefined);
    const [contractData, setContractData] = useState<ContractDo[]>([]);
    const [typeData, setTypeData] = useState<ListDo[]>(props.typeData);

    const [showForm, setShowForm] = useState(false);
    let changedValues = false;

    useEffect(() => {
        loadContractData();
        SimpleList.param(99, (d) => (setTypeData(d)));
    }, []);

    const loadContractData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setContractData(dat.list);
            }
        }
        let query = {
            pageSize : 100,
            contractStatus: [79, 80, 81, 82, 83, 1262]
        };
        AxiosUtil.AxiosRequest('/crmApi/contract/list', query, callback);
    }

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: CashFlowDo) => openForm(r)
        }
    });
    const openForm = (r: CashFlowDo) => {
        if (r) {
            setCashFlowInfo(r);
            formRef.setFieldsValue(r);
            if (r.contractId && contractData.findIndex((value => value.id == r.contractId)) < 0){
                let newItem = {id:r.contractId,contractTitle:r.contractTitle} as ContractDo;
                setContractData([...contractData,newItem])
            }
        } else {
            setCashFlowInfo(undefined);
            formRef.resetFields();
        }
        setShowForm(true);
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    const onFinishForm = () => {
        let values = formRef.getFieldsValue();
        console.log(values);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/cashFlow/edit', [values], callback);
    }
    return (
        <Drawer placement="right"
                width={600}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12}>
                            <MoneyCollectOutlined/> 资金流水明细
                        </Col>
                        <Col span={12}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64}}
                                    onClick={()=>{formRef.submit()}}
                            >
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>

            <Form id="cashForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 4, offset: 0}}
                  form={formRef}
                  style={{marginRight: 24}}
                  onValuesChange={() => {
                      changedValues = true
                  }}
            >
                <Form.Item hidden name={'id'} initialValue={0}><Input /></Form.Item>

                <Row>
                    <Col span={12}>
                        <Form.Item name={''} label={'流水号'} labelCol={{span: 8}}>
                            {cashFlowInfo?.cashflowNo}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={''} label={'日期'} labelCol={{span: 8}}>
                            {moment(cashFlowInfo?.cashflowDate).format("yyyy-MM-DD HH:mm")}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={''} label={cashFlowInfo?.dc} labelCol={{span: 8}}>
                            <span className="blue">{cashFlowInfo?.inMoney}</span>
                            <span className="red">{cashFlowInfo?.outMoney}</span>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={''} label={'余额'} labelCol={{span: 8}}>
                            {cashFlowInfo?.balance}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={''} label={'对方户名'} labelCol={{span: 8}}>
                            {cashFlowInfo?.customerName}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={''} label={'对方账号'} labelCol={{span: 8}}>
                            {cashFlowInfo?.customerAccount}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={''} label={'摘要'} >
                            {cashFlowInfo?.summary}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={''} label={'用途'} >
                            {cashFlowInfo?.memo1}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'memo2'} label={'备注'}>
                            <Input placeholder={''}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'cashflowType'} label={'科目'}>
                            <Select>
                                {DataUtil.selectOptionsRender(props.typeData, 'value', 'label')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'contractId'} label={'关联合同'}>
                            <Select>
                                {DataUtil.selectOptionsRender(contractData, 'id', 'contractTitle')}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Drawer>
    )
}

export default forwardRef(InvoiceFrom);