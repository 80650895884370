import React from "react";
import {useEffect, useState} from "react";
import {Button, Col, Layout, Menu, MenuProps, message, Row, Space, Table} from "antd";

import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavFooter from "../../../components/NavFooter";
import AxiosUtil from "../../../utils/AxiosUtil";
import NavBreadcrumb from "../../../components/NavBreadcrumb";
import {RedoOutlined, SaveOutlined, StarTwoTone,SmileTwoTone} from "@ant-design/icons";
import {ListDo} from "../../admin/model/listDo";

const {Content} = Layout;

const OrderAllocate = (props: any) => {

    const [rootId, setRootId] = useState(0);
    const [rootName, setRootName] = useState("");
    const [mainData, setMainData] = useState<any[]>();

    const [mainLoading, setMainLoading] = useState(false);
    const [columns, setColumns] = useState<any[]>();

    const [valueChanged, setValueChanged] = useState<any>({});

    const [subMenu, setSubMenu] = useState<any[]>();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [rootKeys, setRootKeys] = useState([""]);

    useEffect(() => {
        loadMainData();
    }, [props]);

    let col: any[] = [
        // {
        //     title: "Key",
        //     dataIndex: "key",
        //     width: 100,
        // },
        {
            title: "品类 / Server / Platform",
            width: 160,
            dataIndex: "text",
        },
    ];
    const loadMainData = (gameId?: number) => {

        setMainLoading(true);
        AxiosUtil.AxiosRequest("/mallApi/supplier/list", {}, (suc, data1) => {
            if (suc && data1) {
                AxiosUtil.AxiosRequest('/mallApi/category/listTree', {id: 1},
                    (suc, data2) => {
                        if (suc && data2) {
                            const sub = data2.map((g: any) => ({
                                label: g.categoryName,
                                key: g.id,
                                icon: <SmileTwoTone twoToneColor={"#722ed1"}/>
                            }));
                            setSubMenu(sub);
                            if (gameId) {
                                makeMainData(data2, data1.list, gameId!)
                            } else {
                                if (rootId !== 0) {
                                    makeMainData(data2, data1.list, rootId!)
                                }
                            }
                            setMainLoading(false);
                        }
                    })
            }
        });
    }

    const makeMainData = (cateList: any[], suppList: any[], gameId: number) => {
        // const rootId = props.match.params.rootId || 3;
        const ok: string[] = []

        const game = cateList?.find(g => (g.id === gameId));
        setRootName(game.text);
        let myData: ListDo[] = [];
        const product = game?.children?.find((r: any) => (r.categoryCode == "products"))?.children;
        const skuProp = game?.children?.find((r: any) => (r.categoryCode == "skuprops"))?.children;
        const servers = skuProp?.find((r: any) => (r.categoryCode == "server"))?.children;
        const platform = skuProp?.find((r: any) => (r.categoryCode == "platform"))?.children;
        product?.forEach((r1: any) => {
            const list2: ListDo[] = [];
            servers?.forEach((r2: any) => {
                const list3: ListDo[] = [];
                platform?.forEach((r3: any) => {
                    let newRow = {...r3, text: r3.categoryName, key: r1.id + "_" + r2.id + "_" + r3.id};
                    const prop = JSON.parse(r3.properties);
                    const val = prop?.oal?.["prd" + r1.id]?.["srv" + r2.id]?.["plt" + r3.id]
                    newRow.supplier = val?.sup;
                    newRow.rem = val?.rem;
                    list3.push(newRow)
                })
                ok.push(r1.id + "_" + r2.id)
                list2.push({...r2, text: r2.categoryName, children: list3, key: r1.id + "_" + r2.id});
            })
            ok.push(r1.id)
            myData.push({...r1, text: r1.categoryName, children: list2, key: r1.id});
        })
        setOpenKeys(ok);

        suppList?.forEach((su: any) => {
            col.push({
                title: su.supplierName,
                dataIndex: "_1",
                width: 120,
                render: (v: string, r: any) => (
                    r.children ?
                        <div style={{marginLeft: 0}}>
                            {/*<input type="checkbox" id={`${v}-${su.id}`} name={`${v}-${su.id}`}/>*/}
                            {/*<label htmlFor={`${v}-${su.id}`}> All</label>*/}
                        </div>
                        :
                        <div style={{marginLeft: 20}}>
                            <input type="checkbox"
                                   id={"sup_" + r.key + "_" + su.id}
                                   name={"sup_" + r.key + "_" + su.id}
                                   value={su.id}
                                   defaultChecked={r.supplier?.includes(su.id)}
                                   onChange={onSupplierChange}
                            />
                            <label htmlFor={"sup_" + r.key + "_" + su.id}> {su.supplierName}</label>
                        </div>
                )
            },)
        })
        col.push({
            title: "上期分配",
            dataIndex: "_2",
            width: 120,
            render: (v: string, r: any) => !r.children &&
                <div>
                    <input type="checkbox"
                           id={"rem_" + r.key}
                           name={"rem_" + r.key}
                           value={1}
                           defaultChecked={r.rem == 1}
                           onChange={onSupplierChange}
                    />
                    <label htmlFor={"rem_" + r.key}> 开启"上期"分配</label>
                </div>
        },)
        setColumns(col)
        setMainData(myData);
    }

    let v: any = {};
    const onSupplierChange = (e: any) => {
        console.log(valueChanged, v)
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        v[name] = checked ? 1 : 0;
        setValueChanged(v);

    }

    const saveRecord = () => {
        console.log(valueChanged, Object.keys(valueChanged))
        if (Object.keys(valueChanged).length == 0) {
            message.warn("Not any record has been changed!")
            return;
        }
        setMainLoading(true);
        // console.log('save:', record);
        AxiosUtil.AxiosRequest('/mallApi/category/editOrderAllocate', valueChanged,
            (suc, data) => {
                if (suc && data) {
                    console.log(suc, data)
                    message.success({content: '保存成功！', className: 'message_success'});
                    setMainLoading(false);
                }
            })
    }


    const onOpenChange: MenuProps['onOpenChange'] = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const onClickMenu = (e: any) => {
        loadMainData(Number.parseInt(e.key));
    }

    const crumb = [
        {name: '系统管理'},
        {name: '订单分配规则'},
        {name: rootName},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain collapsed={true}/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <Row gutter={24}>
                            <Col flex="240px">
                                {/*<div style={{height: 32, fontSize: 14, textAlign: "center"}}>*/}
                                {/*    订单分配规则</div>*/}
                                <Menu mode={"inline"}
                                      openKeys={openKeys}
                                      onOpenChange={onOpenChange}
                                      onClick={onClickMenu}
                                      items={subMenu}/>
                            </Col>
                            <Col flex={"auto"}>
                                <div id="mainTable">
                                    <Row>
                                        <Col span={24} className="right">
                                            <Space size={16} style={{marginBottom: 16}}>
                                                <Button key="K1"
                                                        type="primary"
                                                        onClick={saveRecord}>
                                                    <SaveOutlined/> 保存分配规则
                                                </Button>
                                                <Button key="K2"
                                                        type="default"
                                                        onClick={() => loadMainData()}>
                                                    <RedoOutlined/> 刷新
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                    {mainData &&
                                        <Table key={"momentId"} loading={mainLoading}
                                               bordered
                                               size="small"
                                               columns={columns}
                                               dataSource={mainData}
                                               rowKey="key"

                                               pagination={false}
                                               indentSize={24}
                                               expandable={{
                                                   expandedRowKeys: openKeys,
                                                   defaultExpandAllRows: true,
                                                   expandIconColumnIndex: 0,
                                               }}
                                        />}
                                </div>
                            </Col>
                        </Row>

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}


export default OrderAllocate;