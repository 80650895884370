
import ws from "../../utils/WebSocketUtil"

const WebSocket = () => {

    ws.connect("ws://localhost:10010/ws/chat");

    ws.getWs().onopen = function () {
        console.log("ws open");
    };

    ws.getWs().onmessage = function (evt:any) {
        console.log("ws message");
    }
    ws.getWs().onclose = function () {
        console.log("ws close");

    };

    return (
        <></>
    )

}


export default WebSocket