import AxiosUtil from "../../../utils/AxiosUtil";
import { Layout, Space, Table} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";

import moment from "moment";
import DataUtil from "../../../utils/DataUtil";
import {ContractDo} from "../model/contractDo";
import {CashFlowDo} from "../model/cashFlowDo";
import MoneyUtil from "../../../utils/MoneyUtil";
import CashFlowForm from "./CashFlowForm";


const ContractFormCashflow = (props: any, ref: any) => {

    const formRef = useRef();
    const [contractInfo, setContractInfo] = useState<ContractDo | undefined>(undefined);
    const [mainData, setMainData] = useState<CashFlowDo[]>([]);

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ContractDo) => openForm(r)
        }
    });

    useEffect(() => {
        loadMainData();
    }, [contractInfo])

    const openForm = (r: ContractDo) => {
        if (r) {
            setContractInfo(r);
        } else {
            setContractInfo(undefined);
        }
    }

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list);
            }
        }
        if (contractInfo && contractInfo.id) {
            AxiosUtil.AxiosRequest('/crmApi/cashFlow/list', {contractId: contractInfo.id}, callback);
            console.log("loadMainData - ok")
        } else {
            setMainData([]);
        }
    }


    const openEdit = (r?: CashFlowDo) => {
        console.log(r);
        (formRef.current as any).openForm(r, contractInfo);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 48,
        },
        {
            title: "流水号", dataIndex: "cashflowNo", align: "center" as "center", width: 100,
            ellipsis: true,
            render: (v: string, r: CashFlowDo) => (<a onClick={() => openEdit(r)}>{v}</a>),
        },
        {
            title: "日期", dataIndex: "cashflowDate", align: "center" as "center", width: 100,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD')
            },
        },
        {
            title: "金额", dataIndex: "inMoney", align: "right" as "right", width: 88,
            render: (v: number, r: CashFlowDo) => (
                <span className="blue">{MoneyUtil.format(v == null ? r.outMoney : v)}</span>),
        },
        {
            title: "余额", dataIndex: "balance", align: "right" as "right", width: 88,
            render: (v: number) => (DataUtil.formatNumber(v, 2)),
        },
        {
            title: "摘要", dataIndex: "summary", align: "center" as "center", width: 100,
            ellipsis: true,
        },
        {
            title: "用途", dataIndex: "memo1", width: 100, ellipsis: true,
        },
        {
            title: "备注", dataIndex: "memo2", width: 100, ellipsis: true,
            render: (v: string, r: CashFlowDo) => (
                <a onClick={() => openEdit(r)} style={{width:"100%",display:"block"}}>{v}&nbsp;</a>
            ),
        },
    ]

    return (
        <Layout>
            <Space size={16} style={{margin: 0}}>
                &nbsp;
            </Space>
            <Table key='contract'
                   rowKey='id'
                   columns={columns}
                   dataSource={mainData}
                   size="small"
                   bordered
                   pagination={false}
            />

            <CashFlowForm ref={formRef} callback={loadMainData}/>

        </Layout>
    );
}

export default forwardRef(ContractFormCashflow);