import {Button, Col, Drawer, Dropdown, Form, InputNumber, message, Modal, Row, Space, Table} from "antd";
import {
    ExclamationCircleOutlined, DollarCircleOutlined,
    ShoppingCartOutlined,
    CalculatorOutlined,
    CopyOutlined, SearchOutlined, EditOutlined, MailOutlined
} from "@ant-design/icons";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {ItemDo} from "../model/ItemDo";
import {ListDo} from "../../admin/model/listDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import moment from "moment/moment";
import ReactHtmlParser from 'react-html-parser';
import copyToClipboard from "copy-to-clipboard";
import "../../css/items-d2r.css"
import ItemSkuPrice from "./ItemSkuPrice";

const {confirm} = Modal

interface IProps {
    category?: ListDo[];
    prop1Data?: ListDo[];
    prop2Data?: ListDo[];
    callback: () => void;
}

const OrderDelivery = (props: IProps, ref: any) => {

    const [formRef] = Form.useForm();
    const [showForm, setShowForm] = useState(false);
    const [orderData, setOrderData] = useState<any>();
    const [skuList, setSkuList] = useState<any[]>();
    const [skuQuery, setSkuQuery] = useState<any>();
    const [limitTotal, setLimitTotal] = useState(0);

    const [openSkuBriefForm, setOpenSkuBriefForm] = useState(false);
    const [skuBrief, setSkuBrief] = useState<any>({});
    const [openSkuPriceForm, setOpenSkuPriceForm] = useState(false);
    // const [skuBrief, setSkuBrief] = useState<any>({});

    let changedValues = false;

    useEffect(() => {
        loadMainData();
    }, [skuQuery])

    const loadMainData = () => {
        if (skuQuery) {
            AxiosUtil.AxiosRequest("/mallApi/order/deliveryList", skuQuery,
                (suc, data) => {
                    setSkuList(data.list);
                    let lt = 0;
                    data.list.forEach((r: any) => {
                        lt += (r.priceLimit || r.price * 0.7) * r.quantity;
                    })
                    setLimitTotal(lt);
                })
        }
    }

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ItemDo, class1: number) => openForm(r)
        }
    });

    const openForm = (r: any) => {
        console.log(r);
        setOrderData(r);
        setSkuQuery({
            order: {orderId: r.orderId},
            page: {pageNo: 1, pageSize: 99}
        })
        setShowForm(true);
    }

    const openSkuBrief = (r:any) => {
        setSkuBrief(r)
        setOpenSkuBriefForm(true);
    }
    const openSkuPrice = (r:any) => {
        setSkuBrief(r)
        setOpenSkuPriceForm(true);
    }

    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    const onFinishForm = () => {
        let values = formRef.getFieldsValue();
    }

    const skuColumns = [
        {
            title: '物品名称',
            dataIndex: 'skuName',
            width: 200,
            render: (v: string, r: any) => <>{v} <a onClick={()=>openSkuBrief(r)}><SearchOutlined />查看</a></>
        },
        {
            title: '分类',
            dataIndex: 'categoryName1',
            width: 200,
            render: (v: string, r: any) => <>{v} / {r.categoryName2}</>
        },
        {
            title: "来源",
            dataIndex: 'source',
            width: 100,
        },
        {
            title: "毛利率",
            dataIndex: 'grossProfitRate',
            width: 100,
            align: 'center' as 'center',
            render: (v:number, r: any) => <>
                    {(v || 0).toFixed(2)}% <br/>
                    <a onClick={()=>openSkuPrice(r)}><EditOutlined/> 改价</a>
                </>
        },
        {
            title: '购买数量',
            dataIndex: 'quantity',
            width: 100,
            align: 'center' as 'center',
        },
        {
            title: '递送数量',
            dataIndex: 'deliveryQuantity',
            width: 100,
            render: (v: number, r: any) =>
                <Form.Item style={{marginBottom: 0}}
                           initialValue={v || r.quantity}
                           name={"delivery_" + r.id}>
                    <InputNumber />
                </Form.Item>
        },
        {
            title: '采购单价上限',
            dataIndex: 'priceLimit',
            width: 100,
            align: 'center' as 'center',
            render: (v: number, r: any) => <>
                {(v || r.price * 0.7).toFixed(2)}</>
        },
        {
            title: '供货单价',
            dataIndex: 'categoryName1',
            width: 100,
            render: (v: string, r: any) =>
                <Form.Item style={{marginBottom: 0}}
                           name={"price_" + r.id}>
                    <InputNumber>
                    </InputNumber>
                </Form.Item>
        },
    ]
    const items = [
        {
            key: '1',
            label: '完全递送',
        },
        {
            key: '2',
            label: '部分递送',
        },
        {
            key: '3',
            label: '取消发货',
        },
    ];
    return (
        <Drawer placement="right"
                width={1000}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12} style={{padding: 8}}>
                            <ShoppingCartOutlined/> 订单递送处理
                        </Col>
                        <Col span={12}>
                            {/*<Button type={"primary"}*/}
                            {/*        style={{float: "right", marginRight: 64, marginTop: 0, marginBottom: 0}}*/}
                            {/*        onClick={() => {*/}
                            {/*            formRef.submit()*/}
                            {/*        }}*/}
                            {/*>*/}
                            {/*    <SaveOutlined/> 保 存*/}
                            {/*</Button>*/}
                        </Col>
                    </Row>
                }>
            <Form id="cashForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 4, offset: 0}}
                  form={formRef}
                  onValuesChange={() => {
                      changedValues = true
                  }}
            >
                <Row gutter={[0,16]}>
                    {orderData && <>
                        <Col span={12}>
                            订单编号：
                            <span className={"high-light"}>{orderData.class1Code.substring(0, 2).toUpperCase()}-{orderData.id}</span>
                            （{orderData?.orderNo}）
                        </Col>
                        <Col span={12}>
                            <span className={"high-light"}>
                            {orderData.class1Name} &nbsp;
                            {orderData.prop1Name} - {orderData.prop2Name}
                            </span>
                        </Col>
                        <Col span={24}>
                            状态: {orderData.payStatusName} / {orderData.deliveryStatusName} / 未结算
                        </Col>
                        <Col span={12}>
                            递送信息： <a className='copy-icon' onClick={() => {
                                copyToClipboard(orderData.buyerAddress.replaceAll("\\n", "\n"));
                                message.success('复制成功');}}>
                            <CopyOutlined/> Copy</a>
                            <br/>
                            {ReactHtmlParser(orderData.buyerAddress.replaceAll("\n", "<br/>"))}

                        </Col>
                        <Col span={12}>
                            下单时间: {orderData.createTime === null ? '' : moment(orderData.createTime).format('yyyy-MM-DD HH:mm')} <br/>
                            付款时间: {orderData.payTime === null ? '' : moment(orderData.payTime).format('yyyy-MM-DD HH:mm')} <br/>
                            递送时间: {orderData.deliveryTime === null ? '' : moment(orderData.deliveryTime).format('yyyy-MM-DD HH:mm')} <br/>
                            结算时间: {orderData.settleTime === null ? '' : moment(orderData).format('yyyy-MM-DD HH:mm')}

                        </Col>
                        <Col span={24}>
                            <Space>
                                <Dropdown.Button type={"primary"} menu={{ items, onClick: ()=>{} }}>
                                    <ShoppingCartOutlined/> 递送操作
                                </Dropdown.Button>

                                <Button type={"default"}><MailOutlined/> 上线/加好友</Button>
                                <Button type={"default"}><MailOutlined/> 角色信息错误</Button>
                                <Button type={"default"}><MailOutlined/> 缺货-6-12H</Button>
                                <Button type={"primary"} danger><DollarCircleOutlined /> 退款</Button>
                            </Space>
                        </Col>
                        <Col span={12}>
                        </Col>
                    </>}
                </Row>

                <Table key={'editableTable1'}
                       rowSelection={{type: 'checkbox',}}
                       bordered={true}
                       size="small"
                       rowKey={'skuId'}
                       columns={skuColumns}
                       dataSource={skuList}
                       pagination={false}

                />
                <Row>
                    <Col span={24} className={"right red"} style={{fontSize: 14, fontWeight: 600}}>
                        采购单价上限: {limitTotal.toFixed(2)} CNY &nbsp; &nbsp;
                        供货总价: CNY
                        <Button type={"primary"}
                                style={{marginLeft: 64, marginTop: 16, marginBottom: 0}}
                                onClick={() => {formRef.submit()}}>
                            <CalculatorOutlined/> 计算总价
                        </Button>
                    </Col>
                </Row>

            </Form>

            <Drawer placement="right"
                    width={800}
                    open={openSkuBriefForm}
                    onClose={()=>{setOpenSkuBriefForm(false)}}
                    title={"SKU简介"}>
                <div>
                    <div className="item-card" >
                        <div>
                            <p>名称：{skuBrief.itemName}</p>
                            <p>分类：{skuBrief.categoryName2} / {skuBrief.categoryName2}</p>
                            <p>属性：{skuBrief.prop1Name} {skuBrief.prop2Name}</p>
                            <p><img src={skuBrief.image || skuBrief.imageOrig} alt={skuBrief.itemName}/></p>
                            {(skuBrief.itemDesc && skuBrief.itemDesc.length>4 && skuBrief.itemDesc.substring(0,4) === "http") ?
                                <div ><img src={skuBrief.itemDesc}  alt={''}/></div>
                                : (skuBrief.itemDesc && skuBrief.itemDesc.length>0) ?
                                    <div className="item-card-desc item-info">{ReactHtmlParser(skuBrief.itemDesc)}</div>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </Drawer>
            <Drawer placement="right"
                    width={800}
                    open={openSkuPriceForm}
                    onClose={()=>{setOpenSkuPriceForm(false)}}
                    title={"SKU快速改价"}>
                <div>
                    <ItemSkuPrice sku={skuBrief}></ItemSkuPrice>
                </div>
            </Drawer>
        </Drawer>

    )
}

export default forwardRef(OrderDelivery)
