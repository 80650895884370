import {Drawer} from "antd";
import {ExpandOutlined} from "@ant-design/icons";
import RawDataViewer from "./RawDataViewer";
import React, {useEffect, useState} from "react";

const RawDataDrawer = (props: any) => {

    const [openRowData,setOpenRowData] = useState(false);

    useEffect(()=>{
        if (props.rawData && props.rawData !== "") {
            setOpenRowData(true);
        }
    }, [props])

  return (

      <Drawer placement="right"
              width={600}
              open={openRowData}
              onClose={()=> {setOpenRowData(false)}}
              title={<span><ExpandOutlined /> 元数据</span>}>
          <RawDataViewer rawData={props.rawData} label="元数据"/>
      </Drawer>
  )
}

export default RawDataDrawer;