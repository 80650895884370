import React, { useEffect, useRef, useState} from "react";
import {
    Affix,
    Button,
    Cascader,
    Col,
    Form,
    Input,
    Layout,
    Menu,
    MenuProps,
    message,
    Row,
    Select, Space,
    Spin,
    Table, Tooltip,
} from "antd";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavBreadcrumb from "../../../components/NavBreadcrumb";

import EditableTable1 from "../../../components/EditableTable1";
import {BaseInfoDo} from "../../admin/model/BaseInfoDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import {useParams} from "react-router-dom";
import moment from "moment";
import InfoEditor, {fieldProp} from "../../admin/components/InfoEditor";
import FetchUtil from "../../../utils/FetchUtil";
import * as Icon from "@ant-design/icons";
import {
    AppstoreOutlined, CopyOutlined,
    HomeOutlined,
    PlusCircleOutlined,
    RedoOutlined,
    SearchOutlined,
    StarTwoTone
} from "@ant-design/icons";
import SimpleList from "../../admin/components/SimpleList";
import copyToClipboard from "copy-to-clipboard";

const {Content} = Layout;


const OrderSettle = (props: any) => {

    // const {typeId, sourceId} = useParams<any>();
    const formRef = useRef();
    const [queryForm] = Form.useForm();

    const [mainData, setMainData] = useState<BaseInfoDo[]>([]);
    const [mainQuery, setMainQuery] = useState<any>();
    const [mainLoading, setMainLoading] = useState<any>(false);
    const [subMenu, setSubMenu] = useState<any[]>();
    const [currentMenu, setCurrentMenu] = useState<any>();
    const [listColumn, setListColumn] = useState<any>([]);
    const [inputFields, setInputFields] = useState<any[]>([]);

    const [collapsed, setCollapsed] = useState(false);
    const [openKeys, setOpenKeys] = useState(["item-1"]);
    const [rootKeys, setRootKeys] = useState([""]);

    useEffect(()=>{
        loadParams();
    },[])

    let type = props.match.params.type || "";
    useEffect(() => {
        type = props.match.params.type || "";
        setMainQuery({
            order: {},
            page: {pageNo: 1, pageSize: 20}
        })
    }, [props])

    useEffect(() => {
        loadMainData()
    }, [mainQuery])


    const loadParams = () => {

    }

    const loadMainData = () => {
        if (!mainQuery) {
            return;
        }
        setMainLoading(true);
        AxiosUtil.AxiosRequest('/mallApi/order/list', mainQuery,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list);
                }
                setMainLoading(false);
            });
    }

    const submitQuery = () => {
        let values = queryForm.getFieldsValue();
        if (values.categories && values.categories.length >= 1) {
            values.categoryId1 = values.categories[0];
            if (values.categories.length >= 2) {
                values.categoryId2 = values.categories[1];
            } else {
                values.categoryId2 = null;
            }
        } else {
            values.categoryId1 = null;
            values.categoryId2 = null;
        }
        setMainQuery({...mainQuery, pageNo: 1, ...values});
    }

    const copyValue = (v: string) => {
        copyToClipboard(v);
        message.success('复制成功');
    }

    const openEdit = (r?: BaseInfoDo) => {
        console.log(r);
        if (!r) {
            r = {
                infoType: currentMenu.paramId,
                categoryId: currentMenu.gameId
            } as BaseInfoDo;
        }
        (formRef.current as any).openForm(r);
        // setShowEdit(true);
    }

    const columns: any[] = [
        {
            title: '订单号',
            dataIndex: 'id',
            width: 200,
            fixed: 'left',
            render: (v:string,r: any) => <>
                {r.orderNo}<br/>
                {r.class1Code.substring(0,2).toUpperCase()}-{v}
            </>
        },
        {
            title: '游戏服务器平台',
            dataIndex: 'orderNo',
            width: 240,
            render: (v:string,r: any) => <>
                {r.class1Name}<br/>{r.prop1Name} - {r.prop2Name}
            </>
        },
        {
            title: '商品信息',
            dataIndex: 'sku_quantity',
            width: 160,
            render: (v: string, r: any) => <>
                <Space split={"/"}>
                    {r.itemQuantity > 0 && <span>物品({r.itemQuantity})</span>}
                    {r.goldQuantity > 0 && <span>金币({r.goldQuantity})</span>}
                </Space>
                <Tooltip title="点击复制递送信息">
                    <a className='copy-icon' onClick={() => copyValue(r.buyerAddress.replaceAll("\\n", "\n"))}>
                       &nbsp; <CopyOutlined/></a>
                </Tooltip>
            </>
        },
        {
            title: '用户',
            dataIndex: 'buyerEmail',
            width: 240,
            render: (v:string,r: any) => <>{v}<br/>{r.ip} </>
        },
        {
            title: '货币',
            dataIndex: 'currency',
            width: 120,
            align: 'center' as 'center'
        },
        {
            title: '金额',
            dataIndex: 'totalAmount',
            width: 120,
            align: 'right' as 'right'
        },
        {
            title: '付款',
            dataIndex: 'payChannel',
            width: 120,
            align: 'center' as 'center'
        },
        {
            title: '付款',
            dataIndex: 'payStatusName',
            width: 120,
            align: 'center' as 'center'
        },
        {
            title: '递送',
            dataIndex: 'deliveryStatusName',
            width: 120,
            align: 'center' as 'center'
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center'
        }
    ];

    const crumb = [
        {name: '系统管理'},
        {name: '订单管理'},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">

                            <Form form={queryForm}
                                  onFinish={submitQuery}
                                  layout="horizontal"
                                  className={"queryForm"}
                                  labelCol={{span: 8, offset: 0}}
                                  colon={false}>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Form.Item name="text" label="订单号">
                                            <Input allowClear={true}/>
                                        </Form.Item>
                                    </Col>
                                    {/*<Col span={6}>*/}
                                    {/*    <Form.Item name="categories" label="分类" labelCol={{span: 6}}>*/}
                                    {/*        <Cascader options={category}*/}
                                    {/*                  placeholder="选择物品分类"*/}
                                    {/*                  allowClear={true}*/}
                                    {/*                  changeOnSelect*/}
                                    {/*                  fieldNames={{label: "text", value: "id", children: "children"}}*/}
                                    {/*        />*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    {/*<Col span={4}>*/}
                                    {/*    <Form.Item name="source" label="来源">*/}
                                    {/*        <Input placeholder="输入物品名称" allowClear={true}/>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    {/*<Col span={4}>*/}
                                    {/*    <Form.Item name="onSell" label="状态" initialValue={"1"}>*/}
                                    {/*        <Select options={onSellList} placeholder="选择上架状态" allowClear={true}/>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    <Col span={2}>
                                        <Button type={"primary"} htmlType={"submit"}><SearchOutlined/> 查询</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Table loading={mainLoading}
                                   bordered={true}
                                   key={'Table1'}
                                   columns={columns}
                                   dataSource={mainData}
                                   rowKey={"id"}
                                   scroll={{x: 2000}}
                                   pagination={{
                                       total: mainData?.length,
                                       pageSize: mainQuery?.pageSize,
                                       current: mainQuery?.pageNo,
                                       showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                                       showQuickJumper: true
                                   }}
                            />
                        </div>

                        <InfoEditor ref={formRef} inputFields={inputFields} callback={loadMainData}/>

                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default OrderSettle;