import React, {useEffect, useRef, useState} from "react";
import AxiosUtil from "../../../utils/AxiosUtil";
import {Button, Col, Drawer, Row, Table} from "antd";
import {ExpandOutlined, MailOutlined, PlusCircleOutlined} from "@ant-design/icons";
import RawDataViewer from "../../../components/RawDataViewer";
import moment from "moment/moment";

const EdmLog = (props: any) => {

    const formRef = useRef();

    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState();
    const [mainQuery, setMainQuery] = useState<any>({
        id: 0,
        pageSize: 20,
        pageNo: 1,
        orderBy: "id",
    } as any);

    const [showForm, setShowForm] = useState(false);
    const [showForm2, setShowForm2] = useState(false);
    const [form2Text, setForm2Text] = useState<string>("");

    useEffect(() => {
        if (mainQuery.id != 0) {
            loadMainData();
        }
    }, [mainQuery]);

    useEffect(() => {
        setMainQuery({...mainQuery, id: props.taskId});
        setShowForm(props.showForm);
    }, [props]);

    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/mallApi/edm/logList', mainQuery,
            (suc, dat) => {
                if (suc) {
                    setMainData(dat.list)
                    setTotal(dat.total)
                }
            });
        console.log("loadMainData - ok")
    }
    const onTableChange = (pagination: any, filters: any, sorter: any, extra: {
        currentDataSource: any[],
        action: "paginate" | "sort" | "filter"
    }) => {

        let querys = {...mainQuery};
        switch (extra.action) {
            case "paginate":
                querys.pageNo = querys.pageSize == pagination.pageSize ? pagination.current : 1;
                querys.pageSize = pagination.pageSize
                break;
        }
        setMainQuery(querys)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '发送地址',
            dataIndex: 'edmTo',
        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            align: 'center' as 'center',
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            title: '发送状态',
            dataIndex: 'sendStatus',
            align: 'center' as 'center',
        },
        {
            title: 'Result',
            dataIndex: '_remark',
            align: 'center' as 'center',
            render: (v:string, r:any) => <a onClick={()=>openForm2(r.id)}><ExpandOutlined/> 元数据</a>
        },
    ];

    const openForm2 = (id: number) => {
        AxiosUtil.AxiosRequest("/mallApi/edm/logDetail",{id: id},
            (suc,data)=>{
                if (suc) {
                    setForm2Text(data.remark);
                    setShowForm2(true);
                }
            })
    }
    const closeForm2 = () => {
        setShowForm2(false);
    }
    return (

        <Drawer placement="right"
                width={800}
                open={showForm}
                onClose={()=> {
                    setShowForm(false);
                    props.closeCallback();
                }}
                title={<Row>
                    <Col span={11}><MailOutlined/> EDM 日志</Col>
                    <Col span={11} className="right">
                        <Button key="K1" type="primary" onClick={loadMainData}>
                            <PlusCircleOutlined/> 刷新
                        </Button>
                    </Col>
                </Row>}>


            <Table key='contract'
                   rowKey='id'
                   columns={columns}
                   dataSource={mainData}
                   size="small"
                   onChange={onTableChange}
                   bordered
                   pagination={{
                       // onChange: this.onPageChange,
                       total: total,
                       pageSize: mainQuery.pageSize,
                       current: mainQuery.pageNo,
                       showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                       showQuickJumper: true
                   }}
            />

            <Drawer placement="right"
                    width={680}
                    open={showForm2}
                    onClose={closeForm2}
                    title={<span><ExpandOutlined/> 元数据</span>}>
                <RawDataViewer rawData={form2Text} label="Post Raw"/>
            </Drawer>
        </Drawer>
    );
}
export default EdmLog