import {Button, Col, DatePicker, Divider, Drawer, Form, Input, Layout, Row, Space, Table} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import NavFooter from "../../components/NavFooter";
import React, {useEffect, useState} from "react";
import {Content} from "antd/es/layout/layout";
import AxiosUtil from "../../utils/AxiosUtil";
import moment from "moment";
import {LogDo} from "./model/logDo";
import {ExpandOutlined, PlusCircleOutlined, SearchOutlined} from "@ant-design/icons";
import RawDataViewer from "../../components/RawDataViewer";
import ColumnFilterDateProp from "../../components/ColumnFilterDateProp";
import {logQuery} from "./model/logQuery";
import ColumnFilterSearchProp from "../../components/ColumnFilterSearchProp";

const LogList = (props:any)=> {

    // const formRef = useRef();
    const [queryForm] = Form.useForm();

    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState();
    const [viewDate, setViewData] = useState<LogDo>({} as LogDo);
    const [showForm, setShowForm] = useState(false);
    const [mainQuery, setMainQuery] = useState<any>();

    useEffect(() => {
        loadMainData();
    }, [mainQuery]);

    useEffect(() => {
        // SimpleList.param(99, (d) => (setTypeData(d)));
        const t = props.match.params.type;
        if (t) {
            setMainQuery({pageSize: 20, pageNo: 1, logType: t})
            queryForm.resetFields();
            queryForm.setFieldsValue({logType: t});
            loadMainData();
        }else{
            setMainQuery({pageSize: 20, pageNo: 1})
            queryForm.resetFields();
        }
    }, [props]);

    const loadMainData = () => {
        if (mainQuery) {
            AxiosUtil.AxiosRequest('/adminApi/log/list', mainQuery,
                (suc: boolean, dat: any) => {
                    if (suc) {
                        setMainData(dat.list)
                        setTotal(dat.total)
                    }
                });
            console.log("loadMainData - ok")
        }
    }
    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

        let query = {...mainQuery};
        switch (extra.action) {
            case "paginate":
                query.pageNo = query.pageSize == pagination.pageSize ? pagination.current : 1;
                query.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order == "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    query.orderBy = "";
                }
                break;
            case "filter":
                // 此功能仅适用于本地删选
                break;
        }
        setMainQuery(query)
    }

    // const onSearch = (f: string, v: any) => {
    //     console.log("onSearch", v);
    //     let query = {...logQuery};
    //     switch (f) {
    //         case "type":
    //             query.logType = v.value;
    //             break;
    //         case "relTable":
    //             query.relTable = v.value;
    //             break;
    //         case "relField":
    //             query.relField = v.value;
    //             break;
    //         case "relCode":
    //             query.relCode = v.value;
    //             break;
    //         case "clientIp":
    //             query.clientIp = v.value;
    //             break;
    //     }
    //     setLogQuery(query);
    // }

    // const onSearchDate = (f1: string, v: any) => {
    //     console.log("onSearchDate", v);
    //     let query = {...mainQuery};
    //     if (v.value.length != 2) {
    //         return
    //     }
    //     query.beginDate = v.value[0] ? v.value[0].format('yyyy-MM-DD HH:mm:ss') : null;
    //     query.endDate = v.value[1] ? v.value[1].format('yyyy-MM-DD HH:mm:ss') : null;
    //
    //     setMainQuery(query);
    // }

    const openView = (id: number) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setViewData(dat);
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/log/detail', {id: id}, callback);
        setShowForm(true);
    }
    const closeForm = () => {
        setShowForm(false);
    }
    const submitForm = (values : any) => {
        console.log(values);

        let beginDate=null, endDate=null;
        if (values.createTime && values.createTime.length >= 2) {
            beginDate=  values.createTime[0].format('yyyy-MM-DD HH:mm:ss');
            endDate= values.createTime[1].format('yyyy-MM-DD HH:mm:ss');
        }

        setMainQuery({
            ...values,
            beginDate: beginDate,
            endDate: endDate,
            pageSize: mainQuery.pageSize,
            pageNo: 1
        });
    }

    const crumb = [
        {name: '系统管理'},
        {name: '日志'},
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 40,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 100,
            // ...ColumnFilterSearchProp("type", "Type", onSearch)
        },
        {
            title: 'Table',
            dataIndex: 'relTable',
            width: 100,
            // ...ColumnFilterSearchProp("relTable", "Table", onSearch)
        },
        {
            title: 'Field',
            dataIndex: 'relField',
            width: 100,
            // ...ColumnFilterSearchProp("relField", "Field", onSearch)
        },
        {
            title: 'Code',
            dataIndex: 'relCode',
            width: 100,
            // ...ColumnFilterSearchProp("relCode", "Code", onSearch)
        },
        {
            title: 'Time',
            dataIndex: 'createTime',
            width: 100,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
            // ...ColumnFilterDateProp("createTime", "Time", onSearchDate),
        },
        {
            title: 'User',
            dataIndex: 'loginName',
            width: 100,
            render: (v:string, r:any) => <Space size="small" >
                <>{v}</>
                <>{r.clientIp }</>
            </Space>
        },
        // {
        //     title: 'IP',
        //     dataIndex: 'clientIp',
        //     width: 100,
        // },
        {
            title: 'View',
            dataIndex: 'id',
            key: 'id_2',
            align: 'center' as 'center',
            width: 60,
            render: (v: number) => (<a onClick={() => openView(v)}><span><ExpandOutlined/> 查看</span></a>),
        },
    ];
    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}  />
                        <div>

                            <Form form={queryForm}
                                  onFinish={submitForm}
                                  layout="horizontal"
                                  className={"queryForm"}
                                  labelCol={{span: 8, offset: 0}}
                                  colon={false}>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Form.Item name="logType" label="Type" >
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="relTable" label="Table">
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="relField" label="Field">
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="relCode" label="Code">
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="createTime" label="Time" labelCol={{span: 4, offset: 0}}>
                                            <DatePicker.RangePicker allowClear showTime></DatePicker.RangePicker>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Space size={24} className={'right'} style={{paddingBottom: 16}}>
                                            <Button type={"primary"} htmlType={"submit"}>
                                                <SearchOutlined/> 查询
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>

                        <Table key='logs'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               pagination={{
                                   // onChange: this.onPageChange,
                                   total: total,
                                   pageSize: mainQuery?.pageSize,
                                   current: mainQuery?.pageNo,
                                   showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                                   showQuickJumper: true
                               }}
                        />
                        <Drawer placement="right"
                                width={680}
                                open={showForm}
                                onClose={closeForm}
                                title={<span><ExpandOutlined/> Log Info</span>}>
                            <Form id="Form1"
                                  layout="horizontal"
                                  colon={false}
                                  labelCol={{span: 4, offset: 0}}
                                  style={{marginRight: 24}}
                            >
                                <Row>
                                    <Col span={12}>
                                        <Form.Item label={'ID'} labelCol={{span: 8}}>
                                            {viewDate?.id}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'Type'} labelCol={{span: 8}}>
                                            {viewDate?.type}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'Table'} labelCol={{span: 8}}>
                                            {viewDate?.relTable}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'Field'} labelCol={{span: 8}}>
                                            {viewDate?.relField}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={'Code'}>
                                            {viewDate?.relCode}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'Time'} labelCol={{span: 8}}>
                                            {moment(viewDate?.createTime).format('yyyy-MM-DD HH:mm:ss')}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={'IP'} labelCol={{span: 8}}>
                                            {viewDate?.clientIp}
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <RawDataViewer rawData={viewDate.postRaw} label="Post Raw"/>
                                    </Col>
                                    <Col span={24}>
                                        <RawDataViewer rawData={viewDate.postResult} label="Post Result"/>
                                    </Col>

                                </Row>
                            </Form>
                        </Drawer>

                        </div>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default LogList;