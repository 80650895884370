import {useEffect, useRef, useState} from "react";
import {Button, Layout, message, Space, Tree} from "antd";

import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavFooter from "../../components/NavFooter";

import React from "react";
import AxiosUtil from "../../utils/AxiosUtil";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import {
    GlobalOutlined,
    SortAscendingOutlined,
    StopOutlined
} from "@ant-design/icons";
import {FunctionProps} from "./model/function";
import EditableTable1 from "../../components/EditableTable1";
import MulitLanguageEditor from "../../components/MulitLanguageEditor";
import store from "store";
import * as Icon from "@ant-design/icons";
import Link from "antd/es/typography/Link";

const {Content} = Layout;

const Function = (props: any) => {

    // document.title="123";

    const mlRef = useRef();

    const [rootId, setRootId] = useState(0);
    const [mainData, setMainData] = useState<any[]>([]);
    const [sorting, setSorting] = useState(false);

    useEffect(() => {
        setRootId(props.match.params.rootId || 3);
        console.log(Icon)
    }, [props])

    useEffect(() => {
        loadMainData();
    }, [rootId])

    const loadMainData = () => {
        if (!rootId || rootId == 0) {
            return;
        }
        AxiosUtil.AxiosRequest('/adminApi/function/allTree', {id: rootId},
            (suc, d) => {
                const cleanData = (data: FunctionProps[]) => {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].children && data[i].children!.length === 0) {
                            data[i].children = undefined;

                        } else if (data[i].children && data[i].children!.length > 0) {
                            data[i].children = cleanData(data[i].children!);

                        }
                        data[i].title = data[i].functionName;
                        data[i].key = data[i].functionId;
                        // data[i].icon= <MenuOutlined className='grey' />;
                    }
                    return data;
                }
                let myData = cleanData(d);
                setMainData(myData);
            });
    }

    const onDragEnter = (info: any) => {
        console.log('onDragEnter', info);
        // expandedKeys 需要受控时设置
        // this.setState({
        //   expandedKeys: info.expandedKeys,
        // });
    };

    const onDrop = (info: any) => {
        console.log('onDrop', info);
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        const loop = (data: any, key: any, callback: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...mainData];

        // Find dragObject
        let dragObj: any;
        loop(data, dragKey, (item: any, index: any, arr: any[]) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item: { children: any[]; }) => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 && // Has children
            info.node.props.expanded && // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item: { children: any[]; }) => {
                item.children = item.children || [];
                // where to insert 示例添加到头部，可以是随意位置
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });
        } else {
            let ar: any[] = [];
            let i = 0;
            loop(data, dropKey, (item: any, index: any, arr: any) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        setMainData(data);
    };

    const openSort = () => {
        setSorting(true);
    }
    const cancelSort = () => {
        setSorting(false);
    }
    const saveSort = () => {
        // console.log('saveSort', mainData);
        let saveData: any[] = [];
        let sidx = 1;
        const getSortedData = (d: any[], p?: number) => {
            d.forEach((v: any) => {
                saveData.push({...v, sortIndex: sidx, children: null, parentId: p || null});
                sidx++;
                if (v.children && v.children.length > 0) {
                    getSortedData(v.children, v.functionId);
                }
            })
        }
        getSortedData([...mainData]);
        console.log('saveSorted', saveData);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                message.success({content: '排序保存成功！', className: 'message_success'});
                loadMainData();
                cancelSort();
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/function/edit', saveData, callback);
    }
    const saveRecord = (record: any) => {
        if (!record.parentId || record.parentId == 0) {
            record.parentId = rootId;
        }
        console.log('save:', record);
        AxiosUtil.AxiosRequest('/adminApi/function/edit', [record],
            (suc, data) => {
                if (suc && data) {
                    console.log(suc, data)
                    message.success({content: '保存成功！', className: 'message_success'});
                    loadMainData();
                }
            })
    }
    const deleteRecord = (record_key: string) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '记录已删除成功！', className: 'message_success'});
                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/function/delete', [{functionId: record_key}], callback);
    }

    const openMl = (r : any) => {

        (mlRef.current as any).openEditor({type: "function", id: r.functionId});
    }

    let columns = [
        {
            title: "ID",
            key: "functionId",
            dataIndex: "functionId",
            width: 60,
            align: 'center' as 'center',
        },
        {
            title: "菜单",
            key: "functionName",
            dataIndex: "functionName",
            editor: {type: 'text', required: true}
        },
        {
            title: "链接",
            key: "pageUrl",
            dataIndex: "pageUrl",
            editor: {type: 'text', required: true}
        },
        {
            title: "图标",
            key: "icon",
            dataIndex: "icon",
            align: 'center' as 'center',
            render: (v : string, r : any) => <Link onClick={()=>uploadIcon(r.functionId)}>
                {// @ts-ignore
                    (v in Icon) ? React.createElement(Icon[v]) : v}
            </Link>,
            editor: {type: 'text'}
        },
        {
            title: "多语言",
            key: "_ml",
            dataIndex: "_ml",
            align: 'center' as 'center',
            render: (v : string, r : any) =>
                <a onClick={(e:any)=>openMl(r)}>
                    <GlobalOutlined/> 设置
                </a>
        },
        {
            title: "类型",
            key: "menuList",
            width: 120,
            dataIndex: "menuList",
            align: 'center' as 'center',
            render: (v: number) => v == 1 ? "基础" : v == 2 ? "定制功能" : "内部功能",
            editor: {type: 'number', required: false},
        },
        {
            title: "类型",
            width: 120,
            dataIndex: "functionType",
            align: 'center' as 'center',
            render: (v: number) => v == 1 ? "Power" : v == 2 ? "Menu" : "",
            editor: {type: 'number', required: false},
        },
    ];

    const uploadIcon = (id: number) => {

    }

    const globalSetting = store.get('globalSetting') || {} as any;
    if (globalSetting.multiLanguage !== true) {
        columns = columns.filter((r: any) => r.key !== "_ml");
    }

    const crumb = [
        {name: '系统管理'},
        {name: '功能菜单'},
    ];



    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>

                        <div id="mainTable" hidden={sorting}>
                            <EditableTable1
                                key={"momentId"}
                                columns={columns}
                                datas={mainData}
                                record_key="functionId"
                                saveCallback={saveRecord}
                                deleteCallback={deleteRecord}
                                reloadCallback={loadMainData}
                                addChild={true}
                                exButtons={[
                                    <Button type="primary" key="Kx"
                                            onClick={openSort}
                                            hidden={sorting}>
                                        <SortAscendingOutlined/> 排序
                                    </Button>
                                ]}
                            />
                        </div>
                        <div id="mainTree" hidden={!sorting}>

                            <Space size={16} style={{marginBottom: 16}}>
                                <Button type="primary"
                                        onClick={saveSort}><SortAscendingOutlined/> 保存排序</Button>
                                <Button onClick={cancelSort}><StopOutlined/> 取消</Button>
                            </Space>

                            {mainData && mainData.length > 0 &&
                                <Tree
                                    key={"mTree"}
                                    className="draggable-tree"
                                    defaultExpandAll={true}
                                    draggable
                                    blockNode
                                    // showIcon
                                    onDragEnter={onDragEnter}
                                    onDrop={onDrop}
                                    treeData={mainData}
                                />
                            }
                        </div>
                        <MulitLanguageEditor ref={mlRef} />
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}


export default Function;