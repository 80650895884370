let ws: any = null;

export default {
    //连接
    connect(wsUrl:string) {
        ws = new WebSocket(wsUrl);
    },
    //获取实例
    getWs() {
        return ws;
    },
    //关闭连接，删除实例

    removeWs() {
        ws.close();
    },
};

