import AxiosUtil from "../../../utils/AxiosUtil";
import {Button, Layout, Space, Table} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import moment from "moment";
import { PlusCircleOutlined} from "@ant-design/icons";
import {ContractDo} from "../model/contractDo";
import MoneyUtil from "../../../utils/MoneyUtil";
import {RewardDo} from "../model/rewardDo";
import RewardForm from "./RewardForm";


const ContractFormReward = (props: any, ref: any) => {

    const formRef = useRef();
    const [contractInfo, setContractInfo] = useState<ContractDo | undefined>(undefined);
    const [mainData, setMainData] = useState<RewardDo[]>([]);

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ContractDo) => openForm(r)
        }
    });

    useEffect(() => {
        loadMainData();
    }, [contractInfo])

    const openForm = (r: ContractDo) => {
        if (r) {
            setContractInfo(r);
        } else {
            setContractInfo(undefined);
        }
    }

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list);
            }
        }
        if (contractInfo && contractInfo.id) {
            AxiosUtil.AxiosRequest('/crmApi/reward/list', { id : contractInfo.id}, callback);
            console.log("loadFile - ok")
        } else {
            setMainData([]);
        }
    }


    const openEdit = (r?: RewardDo) => {
        console.log(r);
        (formRef.current as any).openForm(r, contractInfo);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 48,
        },
        {
            title: 'Should',
            dataIndex: 'totalMoney',
            align: 'right' as 'right',
            width: 100,
            render: (v: number, r: RewardDo) => {
                return v === null ? '' : <a onClick={() => openEdit(r)}>{MoneyUtil.format(v)}</a>
            },
        },
        {
            title: 'Paid',
            dataIndex: 'payMoney',
            align: 'right' as 'right',
            width: 100,
        },
        {
            title: 'Total',
            dataIndex: 'totalPay',
            align: 'right' as 'right',
            width: 100,
        },
        {
            title: "Date",
            dataIndex: "payDate",
            align: "center" as "center",
            width: 100,
            render: (v: number, r: RewardDo) => {
                return v === null ? '' : <a onClick={() => openEdit(r)} >{moment(v).format('yyyy-MM-DD')}</a>
            },
        },
        {
            title: "Channel",
            dataIndex: "payChannel",
            width: 80,
        },
        {
            title: "To",
            dataIndex: "payTo",
            width: 80,
        },
        {
            title: "Remark", dataIndex: "remark", width: 120, ellipsis: true,
        },
    ]

    return (
        <Layout>
            <Space size={16} style={{margin: 8}}>
                <Button type="primary"
                        key='showEdit'
                        size={"small"}
                        onClick={() => openEdit()}
                >
                    <PlusCircleOutlined /> Add
                </Button>
            </Space>
            <Table key='contract'
                   rowKey='id'
                   columns={columns}
                   dataSource={mainData}
                   size="small"
                   bordered
                   pagination={false}
            />

            <RewardForm ref={formRef} callback={loadMainData}/>

        </Layout>
    );
}

export default forwardRef(ContractFormReward);