import {Component, useEffect, useState} from "react";
import {Button, Layout, message, Typography} from "antd";
import {EnterOutlined, ApartmentOutlined} from "@ant-design/icons";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavFooter from "../../components/NavFooter";
import React from "react";
import AxiosUtil from "../../utils/AxiosUtil";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import EditableTable1 from "../../components/EditableTable1";

const {Content} = Layout;

const Param = () => {

    const [parents, setParents] = useState<any[]>([{paramId: 0, paramName: ''}])
    const [mainData, setMainData] = useState<any[]>([])

    useEffect(() => {
        loadMainData();
    }, [])

    const loadMainData = () => {
        const query = {parentId: parents[parents.length - 1].paramId};
        AxiosUtil.AxiosRequest('/adminApi/param/list', query,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list);
                }
            });
    }

    const reloadChildren = (item: any) => {
        const p = parents;
        p.push(item);
        setParents(p)
        loadMainData();
    }
    const  reloadParent = () => {
        if (parents.length > 1) {
            const p = parents;
            p.pop();
            setParents(p)
            loadMainData();
        }
    }

    const saveRecord = (record: any) => {
        if (parents.length > 1) {
            record.parentId = parents[parents.length - 1].paramId;
        }
        console.log('save:', record);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '保存成功！', className: 'message_success'});

                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/param/edit',  [record], callback);
    }
    const deleteRecord = (record_key: string) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '记录已删除成功！', className: 'message_success'});

                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/param/delete', [record_key], callback);
    }
    const  sortRecord = (records: any[]) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '排序保存成功！', className: 'message_success'});
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/param/edit',  records, callback);
    }

    const columns = [
        {
            title: 'ID',
            key: 'paramId',
            dataIndex: 'paramId',
            width: 40,
            align: 'center' as 'center',
        },
        {
            title: '系统',
            dataIndex: 'systemId',
            width: 80,
            align: 'center' as 'center',
            editor: {type: 'text'}
        },
        {
            title: '所属层级',
            key: 'parentName',
            dataIndex: 'parentName',
            width: 200,
            render: () => parents[parents.length - 1].paramName
        },
        {
            title: '参数名',
            key: 'paramName',
            dataIndex: 'paramName',
            width: 200,
            editor: {type: 'text', required: true},
            render: (v: string, item: any) => <Typography.Link
                onClick={() => reloadChildren(item)}>{v} </Typography.Link>
        },
        {
            title: '参数Code',
            key: 'paramCode',
            dataIndex: 'paramCode',
            width: 200,
            editor: {type: 'text'}
        },
        {
            title: '参数值',
            dataIndex: 'paramValue',
            width: 200,
            editor: {type: 'number'}
        },
        {
            title: '关联说明',
            key: 'mappingCode',
            dataIndex: 'mappingCode',
            width: 200,
            editable: true,
            editor: {type: 'text'}
        },
        {
            title: '子项',
            key: 'next',
            dataIndex: 'paramId',
            width: 88,
            align: 'center' as 'center',
            render: (id: number, item: any) => <Typography.Link
                onClick={() => reloadChildren(item)}><ApartmentOutlined/></Typography.Link>
        },
    ];
    const exButtons = [
        <Button type='link' onClick={reloadParent} hidden={parents.length <= 1}
                key='reloadParent'><EnterOutlined rotate={90}/> 返回上级</Button>
    ];
    const crumb = [
        {name: '系统管理'},
        {name: '参数设置'},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <EditableTable1
                                key={'r'}
                                columns={columns}
                                datas={mainData}
                                record_key="paramId"
                                saveCallback={saveRecord}
                                deleteCallback={deleteRecord}
                                reloadCallback={loadMainData}
                                sortCallback={sortRecord}
                                exButtons={exButtons}
                            />
                        </div>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default Param;
