import React, { useEffect, useState} from 'react';
// import App from "../App";

import './css/login.less';
import {Form, Input, Button, Checkbox, Layout, Spin, message, Row, Col} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';

import store from "store";
import NavFooter from "../components/NavFooter";
import AxiosUtil from "../utils/AxiosUtil";
import cookie from 'react-cookies'
import moment from "moment";
import axios from "axios";

const Login1 = () => {

    let login_info : any;

    const [showLoading, setShowLoading] = useState(false)
    const [verifyCodeImage, setVerifyCodeImage] = useState("")
    const [globalSetting, setGlobalSetting] = useState<any>()

    useEffect(() => {
        AxiosUtil.AxiosRequest('/authApi/login/getVerifyCode', {},
            (suc: boolean, d?: any) => {
                if (suc) {
                    setVerifyCodeImage(d.codeImage);
                }
            });
        const gs = store.get('globalSetting') ;
        if (gs) {
            setGlobalSetting(gs);
        } else {
            axios({method: "GET", url: "/adminApi/param/globalSetting"})
                .then((res) => {
                    if (res.status === 200 && res.data.code === 200) {
                        const rtn = JSON.parse(res.data.data);
                        store.set('globalSetting', rtn);
                        setGlobalSetting(rtn);
                    }
                })
        }
    }, []);

    const getPowerMenu = () => {
        AxiosUtil.AxiosRequest('/adminApi/function/tree', {systemId: login_info.systemId},
            (suc: boolean, d?: any) => {
                if (suc) {
                    const menus = d;
                    store.set('login_power', menus);
                    // console.log('login_power: ', menus);
                    if (menus && menus.length > 0 && menus[0].pageUrl && menus[0].pageUrl !== "") {
                        window.setTimeout(() => {
                            window.location.replace('#/' + menus[0].pageUrl)
                        }, 1000)
                    } else {
                        window.setTimeout(() => {
                            window.location.replace('#/')
                        }, 1000)
                    }
                }
            });
    }

    const onFinish = (values: any) => {
        setShowLoading(true);
        console.log(values);
        console.log(moment().add(7, 'day'));

        const post = {
            loginName: values.loginName,
            loginPassword: values.loginPassword
        }
        AxiosUtil.AxiosRequest('/authApi/login/login', post,
            (suc: boolean, dat?: any) => {
                if (suc) {
                    login_info = dat;
                    store.set('login_info', dat);
                    if (values.remember) {
                        cookie.save(
                            "isLogin",
                            "true",
                            {path: "/", expires: values.remember
                                ? new Date(moment().add(7, 'day').format('YYYY-MM-DD HH:mm:ss'))
                                : undefined });
                    }else {
                        cookie.save("isLogin", "true", {path: "/"});
                    }
                    message.success('登录成功，即将跳转');

                    getPowerMenu();
                }
                setShowLoading(false);
            });
    };

    const d = new Date();
    store.set("time", d.toString());

    return (
        <div className={'layout-main'}>
            <Row>
                <Col span={24}>
                    <Layout.Header id={'main-header'} style={{background: globalSetting?.background}}>
                        <div className={'logo'} style={{backgroundImage: `url(${globalSetting?.logo})`}}></div>
                        <div className={'flex-blank'}/>
                    </Layout.Header>
                </Col>
            </Row>
            <Row>
                <Col span={11} className={'layout-left'}/>
                <Col span={2}/>
                <Col span={11} className={'layout-right'}>
                    {/*<Col span={24}  className={'layout-right'}>*/}
                    <div className={'login-div'}>
                        <div className={'login-logo'}></div>
                        <Spin className={'loading-spin'} tip="Loading..." size="large"
                              spinning={showLoading}>
                            <Form
                                name="normal_login"
                                className="login-form"
                                // initialValues={{remember: true}}
                                onFinish={onFinish}
                            >
                                <Form.Item name="loginName"
                                           rules={[{required: true, message: '请输入用户名!'}]}
                                           style={{height: 80, marginBottom: 0}}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                           placeholder="用户名/手机号/邮箱"/>
                                </Form.Item>
                                <Form.Item name="loginPassword"
                                           rules={[{required: true, message: '请输入您的密码!'}]}
                                           style={{height: 80, marginBottom: 0}}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon"/>}
                                        type="password"
                                        placeholder="密码"
                                    />
                                </Form.Item>
                                {/*<Form.Item name="velifyCode">*/}
                                {/*    <Input placeholder="验证码"*/}
                                {/*           prefix={<SafetyCertificateOutlined  className="site-form-item-icon"/>}*/}
                                {/*           addonAfter={<img  style={{width: 120, height: 32}} src={this.state.verifyCodeImage}  />} />*/}
                                {/*</Form.Item>*/}
                                {globalSetting?.remindme &&
                                <Form.Item>
                                    <Form.Item name="remember" noStyle valuePropName={"checked"} initialValue={"checked"}>
                                        <Checkbox className='remindme'>记住我</Checkbox>
                                    </Form.Item>

                                    <a className="login-form-forgot" href="/">
                                        忘记密码
                                    </a>
                                </Form.Item>
                                }
                                <Form.Item>
                                    <Button type="primary" htmlType="submit"
                                            className="login-form-button">
                                        登录
                                    </Button>
                                    {/* <a href="/">立即注册!</a> */}
                                </Form.Item>
                            </Form>
                        </Spin>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={'layout-bottom'}>
                    <NavFooter/>
                </Col>
            </Row>
        </div>
    );
}

export default Login1;