import React from "react";
import {Layout} from 'antd';
import store from "store";
//import { AppstoreAddOutlined,CoffeeOutlined,DollarOutlined} from '@ant-design/icons';


const NavFooter = () => {

    const globalSetting = store.get('globalSetting') || {} as any;

    return (
        <Layout.Footer id={'main-footer'}>
            <div className="copyright">
                {/*{globalSetting.copyright}*/}
                Copyright 2020-2023, HONGKONG ETERNAL UTOPIA LIMITED.  All Rights Reserved.
                &nbsp; &nbsp; &nbsp;
                <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer" className="beian">
                {globalSetting.beian}
            </a></div>

        </Layout.Footer>
    );
}

export default NavFooter