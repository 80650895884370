
import {ParamProps} from "../model/param";
import AxiosUtil from "../../../utils/AxiosUtil";
import {CategoryDo} from "../../mall/model/CategoryDo";

/**
 * 参数及分类的简易读取方法类
 */
export default class SimpleList {

    /**
     * 参数的简易读取方法
     * @param parentId 根结点
     * @param callback 读取完成后回调
     */
    static param = (parentId: number, callback: (d: any[]) => void) => {
        const call = (suc: boolean, dat: any) => {
            if (suc) {
                const data: any[] = dat.list.map((r: ParamProps) => {
                    return {
                        label: r.paramName,
                        text: r.paramName,
                        value: r.paramId,
                    }
                });
                callback(data)
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/param/list', {parentId: parentId}, call);
    }

    /**
     * 商品分类的简易读取方法
     * @param parentId 根结点
     * @param callback 读取完成后回调
     */
    static category = (parentId: number, callback: (d: any[]) => void) => {
        AxiosUtil.AxiosRequest('/mallApi/category/list', {parentId: parentId},
            (suc: boolean, dat: any) => {
                if (suc) {
                    const data: any[] = dat.list.map((r: CategoryDo) => {
                        return {
                            id: r.id,
                            value: r.id,
                            label: r.categoryName,
                            code: r.categoryCode
                        }
                    });
                    callback(data)
                }
            });
    }

    /**
     * 商品分类（树状）简易读取方法
     * @param parentId 根结点
     * @param callback 读取完成后回调
     */
    static categoryTree = (parentId: number, callback: (d: any[]) => void) => {
        AxiosUtil.AxiosRequest('/mallApi/category/listTree', {id: parentId},
            (suc: boolean, data: any) => {
                if (suc) {
                    callback(data);
                }
            });
    }
}