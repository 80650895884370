import React, { useEffect, useRef, useState} from "react";
import {Button, Col, Divider, Form, Input, Layout, message, Popconfirm, Row, Space, Table,} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";

import {BaseInfoDo} from "./model/BaseInfoDo";
import AxiosUtil from "../../utils/AxiosUtil";
import {useParams} from "react-router-dom";
import moment from "moment";
import InfoEditor, {fieldProp} from "./components/InfoEditor";
import {DeleteOutlined, FormOutlined, PlusCircleOutlined, SearchOutlined} from "@ant-design/icons";
import Link from "antd/es/typography/Link";

const {Content} = Layout;


const Info = (props : any) => {

    const formRef = useRef();

    const [queryForm] = Form.useForm();
    let {typeId, typeName} = useParams<{ typeId: string, typeName: string }>();

    const [mainData, setMainData] = useState<BaseInfoDo[]>([]);
    const [mainQuery, setMainQuery] = useState<any>({
        info: {langCode: "en"},
        page: {pageSize: 20, pageNo: 1}});
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState<any>(false);
    const [langData, setLangData] = useState<any[]>();

    let type = props.match.params.type || "";
    useEffect(() => {
        loadParams();
    }, [props])

    useEffect(() => {
        loadMainData()
    }, [mainQuery])


    const loadParams = async () => {

    }
    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/adminApi/info/list', mainQuery,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setTotal(dat.total);
                    setMainData(dat.list);
                }
            });
    }

    const openEdit = (r?: BaseInfoDo) => {
        console.log(r);
        if (!r) {
            r = {} as BaseInfoDo;
            r.infoType = typeId;
        }
        (formRef.current as any).openForm(r);
        // setShowEdit(true);
    }

    const deleteRecord = (record_key: string) => {
        AxiosUtil.AxiosRequest('/adminApi/info/delete', [record_key],
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '记录已删除成功！', className: 'message_success'});
                    loadMainData();
                }
            });
    }

    const onTableChange = (pagination: any) => {
        setMainQuery({
            info: mainQuery.info,
            page: {
                pageSize: pagination.pageSize,
                pageNo: (mainQuery.page.pageSize == pagination.pageSize) ? pagination.current : 1
            }
        });
    }

    const submitForm = (values : any) => {
        console.log(values);
        setMainQuery({
            info: values,
            page: {
                pageSize: mainQuery.page.pageSize,
                pageNo: 1
        }
        });
    }

    const columns: any[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 40,
            align: 'center' as 'center'
        },
        {
            title: 'Lang',
            dataIndex: 'langCode',
            width: 40,
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
            width: 60,
        },
        {
            title: 'Type',
            dataIndex: 'typeName',
            width: 60,
        },
        {
            title: 'resume',
            dataIndex: 'resume',
            width: 60,
            render: (v: string, r:any) => <Link onClick={() => openEdit(r)}>{v}</Link>
        },
        {
            title: 'title',
            dataIndex: 'title',
            width: 160,
            render: (v: string, r:any) => <Link onClick={() => openEdit(r)}>{v}</Link>
        },
        {
            title: 'mapping',
            dataIndex: 'mappingCode',
            width: 100,
            render: (v: string, r:any) => <Link onClick={() => openEdit(r)}>{v}</Link>
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center',
            width: 100,
        },
        {
            title: '编辑',
            key: 'operation',
            dataIndex: 'operation',
            width: 100,
            align: 'center' as 'center',
            render: (_1: any, r: any) =>
                <Space size="small" split={<Divider type="vertical"/>}>
                    <a key={3} onClick={() => openEdit(r)}>
                        <FormOutlined/>
                    </a>
                    <Popconfirm title="确定要删除这条记录吗"
                                okText="删除"
                                cancelText
                                onConfirm={() => deleteRecord(r.id)}>
                        <a key={4}><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>
        }
    ];
    const inputFields: fieldProp[] = [
        {type: 'number', name: 'infoType', label: 'Type'},
        {type: 'number', name: 'categoryId', label: 'Category'},
        // {type: 'input', name: 'parent_id', label: ''},
        // {type: 'input', name: 'lang_code', label: '语言'},

        {type: 'input', name: 'title', label: '标题'},
        {type: 'input', name: 'resume', label: '内容概要'},
        {type: 'rich', name: 'contents', label: '内容详情'},
        {type: 'input', name: 'link', label: '链接'},
        {type: 'input', name: 'author', label: '作者'},
        {type: 'input', name: 'source', label: '来源'},
        {type: 'input', name: 'mappingCode', label: '字段映射'},
        {type: 'number', name: 'sortIndex', label: '排序'},
        {type: 'image', name: 'image', label: '图片'},
    ];
    const crumb = [
        {name: '系统管理'},
        {name: '内容管理'},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div>
                            <Form form={queryForm}
                                  onFinish={submitForm}
                                  layout="horizontal"
                                  className={"queryForm"}
                                  labelCol={{span: 8, offset: 0}}
                                  colon={false}>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Form.Item name="langCode" label="语言" initialValue={"en"}>
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="groupCode" label="Group">
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="typeCode" label="Type">
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="title" label="Title">
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="mappingCode" label="Mapping">
                                            <Input allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Space size={24} className={'right'} style={{paddingBottom: 16}}>
                                            <Button type={"primary"} htmlType={"submit"}>
                                                <SearchOutlined/> 查询
                                            </Button>
                                            <Button type={"primary"} onClick={() => openEdit()}>
                                                <PlusCircleOutlined/> 新建
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                            <Table loading={loading}
                                   bordered={true}
                                   key={'table1'}
                                   size={"small"}
                                   columns={columns}
                                   dataSource={mainData}
                                   rowKey="id"
                                   onChange={onTableChange}
                                   pagination={{
                                       total: total,
                                       pageSize: mainQuery?.page.pageSize,
                                       current: mainQuery?.page.pageNo,
                                       showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                                       showQuickJumper: true
                                   }}
                            />
                        </div>

                        <InfoEditor ref={formRef} inputFields={inputFields} callback={loadMainData} multiLanguage={true}/>

                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default Info;