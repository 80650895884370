
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Editor, Toolbar} from "@wangeditor/editor-for-react";
import {Button, Col, Drawer, Form, Image, Input, InputNumber, message, Modal, Row, Select, Tabs, Upload} from "antd";
import AxiosUtil from "../../../utils/AxiosUtil";
import '../../css/InfoEditor.less'
import {ExclamationCircleOutlined, FormOutlined, LoadingOutlined, PlusOutlined, SaveOutlined,} from "@ant-design/icons";
import store from "store";
import {UploadFile, UploadProps} from "antd/es/upload/interface";
import {BaseInfoDo} from "../model/BaseInfoDo";
import {ListDo} from "../model/listDo";
import LanguageUtil from "../../../utils/LanguageUtil";

const {confirm} = Modal
const defaultLanguage = store.get("defaultLang");
// 声明类型
type InsertFnType = (url: string, alt: string, href: string) => void;
type InsertFnVideoType = (url: string, poster: string) => void

export interface fieldProp {
    type: 'input' | 'text' | 'rich' | 'image' | 'date' | 'number' | 'select' | 'bool' | "hidden" | "readonly" | "imageUp",
    name: string,
    label: string,
    list?: boolean,
    data?: ListDo[],
}
interface IProps {
    inputFields: fieldProp[];
    callback: ()=>void,
    multiLanguage?: boolean
}

const InfoEditor = (props: IProps, ref: any) => {

    const [formRef] = Form.useForm();
    const [baseInfo, setBaseInfo] = useState<BaseInfoDo | undefined>(undefined);
    const [baseInfoMulti, setBaseInfoMulti] = useState<BaseInfoDo[]>([]);
    const [showForm, setShowForm] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loadingImg, setLoadingImg] = useState(false);
    const [preview, setPreview] = useState(false);
    const [language, setLanguage] = useState(defaultLanguage);

    const [isRich, setIsRich] = useState(false);

    // let baseInfo: any;
    // let baseInfoMulti: any[] = [];
    // let language = "en";

    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)
    // 编辑器内容
    const [html, setHtml] = useState<string>("")

    let changedValues = false;

    const langData : any = LanguageUtil.getLangData();
    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: BaseInfoDo) => openForm(r)
        }
    });
    const openForm = (r: any) => {
        setLanguage(defaultLanguage)
        formRef.resetFields();
        setHtml("");
        if (r) {
            if (r.id && r.id !== 0) {
                AxiosUtil.AxiosRequest("/adminApi/info/detailMulti",
                    {id: (r.parentId !== null && r.parentId !== r.id) ? r.parentId : r.id , orderBy: "id"},
                    (suc, data) => {
                        if (suc && data && data.length > 0) {
                            setBaseInfoMulti(data);
                            // r = {...data[0], ...r};    // Because there are some values from join and readonly in form
                            r = { ...r,...data[0]};    // But don't let page's data replace the raw data from database
                        } else {
                            r = {}
                            setBaseInfoMulti([r]);
                        }
                        setBaseInfo(r);
                        formRef.resetFields();
                        formRef.setFieldsValue(r);
                        props.inputFields.forEach((field) => {
                            if (field.type === "rich") {
                                let h = r[field.name];
                                setHtml(h || "");
                                // values[field.name] = editor?.getHtml;
                            }
                        })
                    })
            } else {
                formRef.resetFields();
                formRef.setFieldsValue(r);
            }
            if (r.image && r.image != '') {
                setFileList([{
                    uid: r.id?.toString() || '',
                    name: r.image || '',
                    status: 'done',
                    url: r.image,
                    thumbUrl: r.image
                }]);
            } else {
                setFileList([]);
            }
        } else {
            // setBaseInfo(undefined);
            formRef.resetFields();
            setFileList([]);

        }
        setShowForm(true);
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    // 模拟 ajax 请求，异步设置 html
    // useEffect(() => {
    //     setTimeout(() => {
    //         setHtml('<p>hello <span style="color: #00ff80">world</span></p>')
    //     }, 1500)
    // }, [])

    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        // toolbarKeys: ['headerSelect', 'blockquote',
        //     '|', 'bold', 'underline', 'italic', 'color', 'bgColor',
        //     '|', 'fontSize', 'fontFamily', 'lineHeight',
        //     '|', 'bulletedList', 'numberedList',"uploadImage",
        //     '|', 'emotion', 'insertLink', 'insertTable', 'codeBlock', 'divider',
        //     '|', 'undo', 'redo', 'fullScreen'],
        excludeKeys: ["emotion", "lineHeight", "group-video", ""],
        // insertKeys: []
    }

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入内容...',
        // customPaste: (editor: IDomEditor, e:ClipboardEvent) => {
        //     console.log(e)
        //     return true
        // },
        MENU_CONF : {}
    };
    // editorConfig.MENU_CONF['uploadImage'] = { // 官网是这样写的
    editorConfig.MENU_CONF!.uploadImage = { // 项目设置了eslint，所以我是这种写法
        // 自定义上传
        async customUpload(file: File, insertFn: InsertFnType) {
            // file 即选中的文件
            const formData = new FormData();
            formData.append('file', file);
            // 自己实现上传，并得到图片 url alt href
            AxiosUtil.AxiosRequest("/adminApi/files/upload", formData, (suc, dat)=>{
                if (suc && dat) {
                    console.log(dat);
                    insertFn(dat, dat, dat);
                }
            })
        },
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])


    const onImgChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        console.log("onChange", newFileList);
        if (newFileList.length > 0) {
            const r = newFileList[0];
            if (r.status === "done" && r.response) {
                if (r.response.code === 200) {
                    // setPreviewImg(r.response.data)

                    // let info = baseInfo || {} as BaseInfoDo;
                    let values = formRef.getFieldsValue();
                    values.image = r.response.data;
                    openForm(values);
                    return;
                } else {
                    message.error(r.response.msg)
                }
            }
            setFileList(newFileList);
        } else {
            setFileList([]);
        }
    };

    const onImgRemove = (e: any) => {
        setFileList([]);
        formRef.setFieldsValue({image: ''});
        // setPreviewImg('');
    }

    const onImgPreview = async (file: UploadFile) => {
        if (file.url && file.url!.toLowerCase().indexOf(".pdf") > 0) {
            window.open(file.url);
        } else {
            setPreview(true);
        }
    };

    const onFinishForm = () => {
        let values = formRef.getFieldsValue();
        props.inputFields.forEach((field) => {
            if (field.type === "rich") {
                values[field.name] = editor?.getHtml();
            }
        })
        console.log(values);
        let editInfo;
        if (baseInfoMulti == undefined || baseInfoMulti.length == 0) {
            values.langCode = defaultLanguage;
            editInfo = [values];
        } else {
            editInfo = baseInfoMulti.map((r: any) => {
                if (r.langCode === language) {
                    r = {...r, ...values};
                }
                return r;
            });

        }
        AxiosUtil.AxiosRequest('/adminApi/info/edit', editInfo,
            (suc: boolean, dat: any) => {
                if (suc) {
                    changedValues = false;
                    closeForm();
                    message.success("保存成功！")
                    if (props.callback) {
                        props.callback();
                    }
                }
            });
    }

    let hidden = <></>;

    const onTabChange = (key: string) => {
        let values = formRef.getFieldsValue();
        props.inputFields.forEach((field) => {
            if (field.type === "rich") {
                values[field.name] = editor?.getHtml();
            }
        })
        const oldInfos = baseInfoMulti.map((r: any) => {
            if (r.langCode === language) {
                r = {...r, ...values};
            }
            return r;
        });
        let newInfo : any = baseInfoMulti.find((r: any) => r.langCode === key)
        if (newInfo === undefined) {
            newInfo = {
                infoType: baseInfo?.infoType,
                categoryId: baseInfo?.categoryId,
                parentId: baseInfo?.id,
                langCode: key
            }
            oldInfos.push(newInfo);
        }
        setBaseInfoMulti([...oldInfos]);
        setLanguage(key);
        formRef.resetFields();
        formRef.setFieldsValue(newInfo);
    }

    return (

        <Drawer placement="right"
                width={800}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12}>
                            <FormOutlined/> 内容编辑
                        </Col>
                        <Col span={12}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64}}
                                    onClick={() => {formRef.submit()}}
                            >
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>
            <Tabs activeKey={language}
                  items={langData}
                  onChange={onTabChange}
                  style={{marginBottom: 24}}
                  centered
                  hidden={!props.multiLanguage}
                // type="card"
                  size={"small"}/>
            <Form id="cashForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 4, offset: 0}}
                  form={formRef}
                  onValuesChange={() => {changedValues = true}}
            >
                {props.inputFields.map((field, index) => {
                    let input;
                    switch (field.type) {
                        case "input":
                            input = <Input placeholder={'请输入' + field.label}/>;
                            break
                        case "text":
                            input = <Input.TextArea placeholder={'请输入' + field.label} style={{height: 300}}/>;
                            break
                        case "number":
                            input = <InputNumber placeholder={'请输入' + field.label}/>;
                            break
                        case "select":
                            input = <Select options={field.data}/>;
                            break;
                        case "readonly":
                            input = <Input readOnly={true} style={{border: "none"}}/>;
                            break;

                        case "rich":
                            input = <div style={{border: '1px solid #CCC', zIndex: 100, display: ''}}>
                                <Toolbar
                                    editor={editor}
                                    defaultConfig={toolbarConfig}
                                    mode="default"
                                    style={{borderBottom: '1px solid #ccc'}}
                                />
                                <Editor
                                    defaultConfig={editorConfig}
                                    value={html}
                                    onCreated={setEditor}
                                    onChange={editor => setHtml(editor.getHtml())}
                                    mode="default"
                                    style={{height: '300px', overflowY: 'hidden'}}
                                />
                                <div style={{marginTop: '15px'}}>
                                    {html}
                                </div>
                            </div>
                            break;

                        case "image":
                        case "imageUp":
                            hidden = <>{hidden}<Form.Item hidden={field.type=="image"}
                                                          name={field.name} label=''><Input/></Form.Item></>
                            input = <>
                                <Upload accept=".jpg,.jpeg,.png,.bmp,.webp,.gif"
                                        headers={{"accessToken": access_token}}
                                        action="/adminApi/files/upload"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onImgChange}
                                        onPreview={onImgPreview}
                                        onRemove={onImgRemove}>
                                    {fileList.length < 1 &&
                                        <div>
                                            {loadingImg ? <LoadingOutlined/> : <PlusOutlined/>}
                                            <div style={{marginTop: 8}}>Upload</div>
                                        </div>
                                    }
                                </Upload>
                                <Image preview={{
                                    visible: preview,
                                    src: baseInfo?.image,
                                    onVisibleChange: value => {
                                        setPreview(value);
                                    },
                                }}/>
                            </>
                    }
                    return (
                        <Row key={index}>
                            <Col span={24}>
                                <Form.Item name={field.name}
                                           label={<>
                                               {field.label}<br />
                                               {/*<Switch checkedChildren="富文本" */}
                                               {/*        unCheckedChildren="纯文本"*/}
                                               {/*        checked={isRich}*/}
                                               {/*        onChange={(e)=>setIsRich(e)}></Switch>*/}
                                           </>}>
                                    {input}
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                })}

                <Form.Item hidden={true} name={'id'} initialValue={0}><Input/></Form.Item>
                <Form.Item hidden={true} name={'infoType'} initialValue={0}><Input/></Form.Item>
                <Form.Item hidden={true} name={'categoryId'} initialValue={0}><Input/></Form.Item>
                {hidden}


            </Form>
        </Drawer>
    )
}

export default forwardRef(InfoEditor)