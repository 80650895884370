import {Button, Col, Divider, Drawer, Layout, message, Modal, Popconfirm, Row, Space, Table} from "antd";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavBreadcrumb from "../../../components/NavBreadcrumb";
import NavFooter from "../../../components/NavFooter";
import React, {useEffect, useRef, useState} from "react";
import {Content} from "antd/es/layout/layout";
import {Query} from "../../../model/query";
import AxiosUtil from "../../../utils/AxiosUtil";
import moment from "moment";
import {
    MailOutlined,
    ExclamationCircleOutlined,
    SaveOutlined,
    PlusCircleOutlined,
    RedoOutlined, FormOutlined, DeleteOutlined, SearchOutlined
} from "@ant-design/icons";
import EditorForm, {EditorFieldProp} from "../../../components/EditorForm";
import {EdmTaskDo} from "../model/EdmTaskDo";
import ReactDOM from "react-dom";
import EdmLog from "../components/EdmLog";
import Link from "antd/es/typography/Link";

const {confirm} = Modal

const EdmTask =()=> {

    const formRef = useRef();

    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState();
    const [mainQuery, setMainQuery] = useState<Query>({
        pageSize: 20,
        pageNo: 1,
        orderBy: "update_time desc",
    } as Query);

    const [editingData, setEditingData] = useState<EdmTaskDo>({} as EdmTaskDo);
    const [showForm, setShowForm] = useState(false);

    const [showLog,setShowLog] = useState(false);
    const [logTaskId,setLogTaskId] = useState(0);

    useEffect(() => {
        loadMainData();
    }, [mainQuery]);

    useEffect(() => {
    }, []);

    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/mallApi/edm/list', mainQuery,
            (suc, dat) => {
                if (suc) {
                    setMainData(dat.list)
                    setTotal(dat.total)
                }
            });
        console.log("loadMainData - ok")
    }
    const onTableChange = (pagination: any, filters: any, sorter: any, extra: {
        currentDataSource: any[],
        action: "paginate" | "sort" | "filter"
    }) => {

        let querys = {...mainQuery};
        switch (extra.action) {
            case "paginate":
                querys.pageNo = querys.pageSize === pagination.pageSize ? pagination.current : 1;
                querys.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order === "ascend" ? " ASC" : " DESC";
                    querys.orderBy = orderBy;
                } else {
                    querys.orderBy = "";
                }
                break;
            case "filter":
                break;
        }
        setMainQuery(querys)
    }


    let changedValues = false;
    const changeForm = (change: boolean, values: any) => {
        changedValues = change;
        console.log("changeForm", values);
        if (values.sendTime && values.intervalMin && values.intervalMax && values.edmToList) {
            const mailCount = JSON.parse(values.edmToList).total;
            const processTime = (values.intervalMin + values.intervalMax) / 2 * mailCount
            ;
            //setFinishTime(moment(values.sendTime).add(processTime,"seconds").format("yyyy-MM-DD HH:mm:ss"))
            ReactDOM.render(<span className="blue strong">
                    {moment(values.sendTime).add(processTime, "seconds").format("yyyy-MM-DD HH:mm:ss")}
                    （邮件数：{mailCount}）
                </span>,
                document.getElementById("EdmPlanTime"))
        }
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    const openEdit = (r?: EdmTaskDo) => {
        // console.log(r);
        if (!r) {
            r = {intervalMin: 10, intervalMax: 20} as EdmTaskDo;
            setEditingData(r);
            setShowForm(true);
        } else {
            AxiosUtil.AxiosRequest("/mallApi/edm/detail", {id: r.id},
                (suc: boolean, data: any) => {
                    if (suc) {
                        const r = data;
                        // console.log(r);
                        r.sendTime = r.sendTime == null || r.sendTime == "" ? "" : moment(r.sendTime)
                        setEditingData(r);
                        setShowForm(true);
                        changeForm(false, r);
                    }
                })
        }
        // (formRef.current as any).openForm(r);
    }

    const crumb = [
        {name: '线上商城'},
        {name: 'EDM 任务'},
    ];

    const columns = [
        {
            title: 'EDM任务标题',
            dataIndex: 'taskTitle',
            render: (v: string, r: EdmTaskDo) => <Link onClick={() => openEdit(r)}>{v}</Link>
        },
        {
            title: 'EDM标题',
            dataIndex: 'edmSubject',
        },
        {
            title: '发件人',
            dataIndex: 'fromName',
        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            align: 'center' as 'center',
            render: (v: any) => moment(v).format('yyyy-MM-DD HH:mm:ss')
        },
        {
            title: '发送间隔',
            dataIndex: 'in',
            align: 'center' as 'center',
            render: (v: string, r: EdmTaskDo) => <>{r.intervalMin} - {r.intervalMax}</>
        },
        {
            title: '发送状态',
            dataIndex: 'sendStatus',
            align: 'center' as 'center',
            render: (v: number) => <>{v}</>
        },
        {
            title: '成功/失败/总数',
            dataIndex: 'sendNum',
            align: 'center' as 'center',
            render: (v: number, r: EdmTaskDo) => <>{r.n2} / {r.n9} / {r.n}</>
        },
        {
            title: '发送日志',
            dataIndex: '_t',
            align: 'center' as 'center',
            render: (v: number, r: EdmTaskDo) => (<a onClick={()=>{
                setLogTaskId(r.id);
                setShowLog(true);
            }}><SearchOutlined/> 查看</a>)
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            align: 'center' as 'center',
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm')
            },
        },
        {
            title: '预计完成',
            dataIndex: '_time',
            align: 'center' as 'center',
            render: (_:any, r:EdmTaskDo) => {
                if (r.sendTime && r.intervalMin && r.intervalMax && r.edmToList) {
                    const mailCount = JSON.parse(r.edmToList).total;
                    const processTime = (r.intervalMin + r.intervalMax) / 2 * mailCount;
                    return moment(r.sendTime).add(processTime, "seconds").format("yyyy-MM-DD HH:mm:ss")
                }else{
                    return "";
                }
            },
        },
        {
            title: '操作',
            dataIndex: '_',
            width: 60,
            align: "center" as "center",
            render: (v: string, r: EdmTaskDo) => {
                return (<Space size={0} split={<Divider type="vertical"/>}>
                    <a onClick={() => openEdit(r)}><FormOutlined/></a>
                    <Popconfirm disabled={false}
                                title="确定要删除这条记录吗"
                                okText="删除"
                                cancelText
                                onConfirm={() => deleteRecord(r)}
                    >
                        <a><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>)
            },
        },
    ];
    let inputFields: EditorFieldProp[] = [
        {type: "hidden", name: "id", label: "id"},
        {type: "input", name: "taskTitle", label: "EDM任务标题", required: true},
        {type: "input", name: "fromName", label: "发件人名称", required: true, width: 12},
        {type: "input", name: "replyEmail", label: "回复地址", required: true, width: 12},
        {type: "input", name: "edmSubject", label: "EDM标题", required: true},
        {type: "rich", name: "edmContent", label: "EDM内容", required: true},
        {type: "file", name: "edmToList", label: "EDM发送用户", required: true},
        {type: "input", name: "edmLabel", label: "标签", required: true, width: 12},
        {type: "datetime", name: "sendTime", label: "EDM发送时间", required: true, width: 12},
        {type: "number", name: "intervalMin", label: "发送间隔（秒）", required: true, width: 12},
        {type: "number", name: "intervalMax", label: "至", required: true, width: 12},
        // { label: "EDM预计发送完成时间"},
    ];

    const beforeSubmitForm = () => {
        (formRef.current as any).submitForm();
    }

    const submitForm = (values: any) => {
        console.log(values);
        AxiosUtil.AxiosRequest("/mallApi/edm/edit", [values],
            (suc: boolean, data: any) => {
                if (suc) {
                    message.success("保存成功！");
                    setShowForm(false);
                    loadMainData();
                } else {
                    message.error({content: data});
                }
            })
    }

    const deleteRecord = (r: EdmTaskDo) => {
        AxiosUtil.AxiosRequest('/mallApi/edm/delete', [r.id],
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '记录已删除成功！', className: 'message_success'});
                    loadMainData();
                }
            });
    }
    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Space size={16} style={{marginBottom: 16}}>
                                <Button key="K1"
                                        type="primary"
                                        onClick={() => openEdit()}>
                                    <PlusCircleOutlined/> 新增
                                </Button>
                                <Button key="K2"
                                        type="primary"
                                        onClick={loadMainData}>
                                    <RedoOutlined/> 刷新
                                </Button>
                            </Space>
                        </div>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               pagination={{
                                   // onChange: this.onPageChange,
                                   total: total,
                                   pageSize: mainQuery.pageSize,
                                   current: mainQuery.pageNo,
                                   showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                                   showQuickJumper: true
                               }}
                        />

                        <Drawer placement="right"
                                width={800}
                                open={showForm}
                                onClose={closeForm}
                                title={<Row>
                                    <Col span={11}><MailOutlined/> EDM 任务</Col>
                                    <Col span={11} className="right">
                                        <Button type={"primary"} onClick={beforeSubmitForm}><SaveOutlined/> 保
                                            存</Button>
                                    </Col>
                                </Row>}>

                            <EditorForm key="myForm"
                                        ref={formRef}
                                        inputFields={inputFields}
                                        initData={editingData}
                                        changeCallback={changeForm}
                                        submitCallback={submitForm}
                            />
                            <Row><Col>
                                EDM预计发送完成时间： <span id={"EdmPlanTime"}></span>
                            </Col></Row>
                        </Drawer>

                        <EdmLog showForm={showLog} taskId={logTaskId} closeCallback={()=> {
                            setShowLog(false);
                            setLogTaskId(0);
                        }}></EdmLog>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default EdmTask;