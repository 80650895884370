import {ActionType, PageContainer, ProCard, ProLayout, ProTable} from '@ant-design/pro-components';
import React, {Key, useEffect, useRef, useState} from "react";
import {Button, Divider, Dropdown, message, Popconfirm, Space, Table, Tag} from "antd";
import {DeleteOutlined, FormOutlined, LogoutOutlined, UnlockOutlined, UserOutlined} from "@ant-design/icons"
import store from "store";
import {useHistory} from "react-router-dom";
import Link from "antd/es/typography/Link";
import * as Icon from "@ant-design/icons";
import MyProLayout from "../../../components/MyProLayout";
import FetchUtil from "../../../utils/FetchUtil";
import {ItemDo} from "../../mall/model/ItemDo";
import moment from "moment/moment";
import AxiosUtil from "../../../utils/AxiosUtil";

const ItemIndex = (props: any) => {

    const actionRef = useRef<ActionType>();
    const history = useHistory()
    const hashPath = window.location.hash
    const [mainQuery, setMainQuery] = useState<any>();
    const [tableSelected, setTableSelected] = useState<Key[]>([]);

    let class1 = props.match.params.class1;
    const defaultSort: string = "IFNULL(sort_index, 999999), item_name";

    useEffect(() => {
        class1 = props.match.params.class1;
        setMainQuery({
            item: {onSell: ""},
            category: {classId1: class1},
            page: {pageSize: 20, pageNo: 1, orderBy: defaultSort}
        });
    }, [props])


    const openEdit = (r?: ItemDo) => {
        console.log(r);
        if (!r) {
            r = {
                classId1: class1,
            };
            // if (mainData.length > 0) {
            //     r.classCode1 = mainData[0].classCode1;
            //     r.className1 = mainData[0].className1;
            // }
        }
        r.classId1 = class1;
        // (formRef.current as any).openForm(r);
        // setShowEdit(true);
    }

    const deleteRecord = (record: ItemDo) => {
        AxiosUtil.AxiosRequest('/mallApi/item/delete', [record.itemId],
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '记录已删除成功！', className: 'message_success'});
                    // loadMainData();
                }
            });
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'itemId',
            width: 40,
            align: 'center' as 'center',
            hideInSearch: true
        },
        {
            title: '名称',
            dataIndex: 'itemName',
            width: 100,
            render: (v: any, r: ItemDo) => <a onClick={() => openEdit(r)}>{v}</a>,
            order: 100
        },
        {
            title: '原始名',
            dataIndex: 'itemNameOrig',
            width: 100,
            render: (v: any, r: ItemDo) => <a onClick={() => openEdit(r)}>{v}</a>
        },
        // {
        //     title: '分类',
        //     dataIndex: 'categoryName1',
        //     width: 100,
        //     render: (v: string, r: ItemDo) => {
        //         return (<> {v}
        //             {r.categoryId1 === r.categoryId2 ? '' : ` / ${r.categoryName2}`}
        //         </>)
        //     }
        // },
        {
            title: '来源',
            dataIndex: 'source',
            width: 60,
            align: "center" as "center",
            order: 99
        },
        {
            title: class1 == 3 ? '皇冠数' : '原始价格',
            dataIndex: 'price',
            width: 60,
            align: "center" as "center",
            render: (v: any, r: any) => class1 == 3 ?
                <>{r.pricePoint}
                    {r.priceDiscount != null && r.priceDiscount != 0 && <>({r.priceOrig} / {r.priceDiscount * 100}%)</>}
                </>
                : r.priceOrig,
            hideInSearch: true
        },
        {
            title: '到期时间',
            dataIndex: 'expiryDate',
            width: 60,
            align: "center" as "center",
            valueType: "dateTime",
            hideInSearch: true
            // render: (v: any) => v == null || v == 0 || isNaN(v) ? v :
            //     moment(v * 1000).format('yyyy-MM-DD HH:mm'),
        },
        // {title: '映射', dataIndex: 'mappingCode',},
        {
            title: 'SKU数',
            dataIndex: 'skuCount',
            width: 60,
            align: "center" as "center",
            hideInSearch: true
        },
        {
            title: 'SKU价格',
            dataIndex: '_skuCount',
            width: 60,
            align: "center" as "center",
            render: (v: any, r: ItemDo) => {
                return (<>{r.skuPriceMin === r.skuPriceMax ? r.skuPriceMin : `${r.skuPriceMin} ~ ${r.skuPriceMax}`}</>)
            },
            hideInSearch: true
        },
        {
            title: '状态',
            dataIndex: 'onSell',
            width: 60,
            align: "center" as "center",
            // render: (v: any) => v == 1 ? <Tag color="green">上架</Tag> : <Tag color="red">下架</Tag>,
            valueType: 'select',
            valueEnum: {
                1: {text: <Tag color="green">上架</Tag>,},
                0: {text: <Tag color="red">下架</Tag>,}
            },
            order: 90
        },
        {
            title: '更新',
            dataIndex: 'updateTime',
            width: 60,
            align: "center" as "center",
            valueType: "dateTime",
            hideInSearch: true
        },
        {
            title: '操作',
            dataIndex: '_',
            width: 60,
            align: "center" as "center",
            render: (v: any, r: ItemDo) =>
                <Space size="small" split={<Divider type="vertical"/>}>
                    <a onClick={() => openEdit(r)}><FormOutlined/></a>
                    <Popconfirm disabled={false}
                                title="确定要删除这条记录吗"
                                okText="删除"
                                cancelText
                                onConfirm={() => deleteRecord(r)}
                    >
                        <a><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>
            ,
            hideInSearch: true
        },
    ];

    const batchOption = async (opt: number) => {
        if (!tableSelected || tableSelected.length == 0) {
            message.warn("请选择要操作的记录！");
        }
        const editItems = tableSelected.map((itmeId: Key) => {
            return (opt == 2) ?
                {id: itmeId, onSell: null, stockBalance: 0}
                : {id: itmeId, onSell: opt, stockBalance: null}
        })
        const res = await FetchUtil.awaitFetch("/mallApi/item/edit", editItems)
        if (res.code == 200) {
            message.success("成功更新 " + res.data.updated + " 条");
            // actionRef.current?.clearSelected();
            actionRef.current?.reload();
        } else {
            message.error(res.data.msg);
        }
    }

    return (
        <MyProLayout>
            <PageContainer title={false}>
                <ProTable
                    actionRef={actionRef}
                    columns={columns}
                    rowKey="itemId"
                    rowSelection={{
                        type: "checkbox",
                        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
                        onChange: (s) => setTableSelected(s)
                    }}
                    tableAlertOptionRender={() => {
                        return (
                            <Space size={16}>
                                <Button onClick={() => batchOption(0)}>批量下架</Button>
                                <Button onClick={() => batchOption(1)}>批量上架</Button>
                                <Button onClick={() => batchOption(2)}>批量设置库存</Button>
                            </Space>
                        );
                    }}
                    cardBordered={true}
                    // params 是需要自带的参数
                    // 这个参数优先级更高，会覆盖查询表单的参数
                    params={mainQuery}
                    request={async (
                        params,
                        sort,
                        filter,
                    ) => {
                        if (!class1 || !mainQuery) {
                            return {success: false}
                        }
                        let query = mainQuery;
                        query.item.itemName = params.itemName;
                        query.page.pageSize = params.pageSize;
                        query.page.pageNo = params.current;
                        setMainQuery(query)

                        const res = await FetchUtil.awaitFetch("/mallApi/item/list", query);
                        console.log(res)
                        return {
                            data: res.data.list,
                            // success 请返回 true，
                            // 不然 table 会停止解析数据，即使有数据
                            success: true,
                            // 不传会使用 data 的长度，如果是分页一定要传
                            total: res.data.total,
                        };
                    }}
                />
            </PageContainer>
        </MyProLayout>
    )
}

export default ItemIndex;