import React from 'react';
import zhCN from 'antd/es/locale-provider/zh_CN';
import {HashRouter, Switch} from 'react-router-dom';
import {MyRouter} from './router/router';
import {NoLoginRoute} from "./router/NoLoginRoute";
import {ConfigProvider, message, notification} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.less';
import './pages/css/App.less'
import "./css/antd-override.css"
import store from "store";
import axios from "axios";
import FetchUtil from "./utils/FetchUtil";

moment.locale('zh-cn'); // 将moment.js设置为中文

// const store = storeConfig(); // 初始化redux的store，如不需要状态管理器，redux相关可以忽略。

message.config({ // antd的message组件配置，during持续时间单位为秒，maxcount最多显示个数
    duration: 2,
    maxCount: 2
});
notification.config({
    placement: 'topRight', // antd的通知组件配置，placement弹出位置。bottom距离底部的距离，during持续时间单位为秒
    bottom: 50,
    duration: 2,
});

// const rootPath = "/v2/";

function App() {

    const login_info = store.get('login_info');
    const initBaseInfo = async () => {
        if (login_info && login_info.userId) {
            let access_token = login_info ? login_info.accessToken || "" : "";
            let url = "/adminApi/function/tree";
            let data = {systemId: login_info.systemId};
            let res = await FetchUtil.awaitFetch(url, data)
            if (res.code == 200) {
                store.set('login_power', res.data);
            }
        }
        let url = "/adminApi/language/list";
        let res = await FetchUtil.awaitFetch(url, {})
        if (res.code === 200) {
            store.set("langData", res.data.list);
            store.set("defaultLang", res.data?.list.find((r: any) =>
                r.defaultLang === 1)?.langCode || "en");
        }
    }
    
    initBaseInfo();


    //     axios({
    //         headers: {"accessToken": access_token},
    //         method: 'post',
    //         url: "/adminApi/function/tree",
    //         data: {systemId: login_info.systemId}
    //     })
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 if (res.data.code === 200) {
    //                     store.set('login_power', res.data.data);
    //                 }
    //             }
    //
    //         })
    // } else {
    //     // myRouter = NoLoginRoute;
    // }

    // axios({method: "GET", url: "/adminApi/param/globalSetting"})
    //     .then((res) => {
    //         if (res.status === 200 && res.data.code === 200) {
    //             const rtn = JSON.parse(res.data.data);
    //             store.set('globalSetting', rtn);
    //         }
    //     });

    // axios({method: 'post', url: "/adminApi/language/list", data: {}})
    //     .then((res) => {
    //         if (res.status === 200 && res.data.code === 200) {
    //             store.set("langData", res.data.data.list);
    //             store.set("defaultLang", res.data.data?.list.find((r: any) =>
    //                 r.defaultLang === 1)?.langCode || "en");
    //         }
    //     });


    return (
        <ConfigProvider locale={zhCN}>
            <HashRouter>
                {login_info && login_info.userId ? <MyRouter/> : <NoLoginRoute/>}
            </HashRouter>
        </ConfigProvider>
    );
}

export default App;