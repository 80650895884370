import React, {useRef} from "react";
import {useEffect, useState} from "react";
import {Button, Col, Drawer, Form, Input, InputNumber, Layout, Menu, MenuProps, message, Row, Select, Space, Table
} from "antd";

import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavFooter from "../../../components/NavFooter";
import AxiosUtil from "../../../utils/AxiosUtil";
import NavBreadcrumb from "../../../components/NavBreadcrumb";
import {RedoOutlined, SaveOutlined, StarTwoTone, SmileTwoTone, SearchOutlined, ExpandOutlined} from "@ant-design/icons";
import {ListDo} from "../../admin/model/listDo";
import moment from "moment";
import ReactDOM from "react-dom";
import RawDataViewer from "../../../components/RawDataViewer";
import RawDataDrawer from "../../../components/RawDataDrawer";
import {createRoot, Root} from "react-dom/client";

const {Content} = Layout;

const Coefficient = (props: any) => {

    const [inputForm] = Form.useForm();
    const [queryForm] = Form.useForm();

    // const [rootId, setRootId] = useState(0);
    const [rootName, setRootName] = useState("");
    const [column, setColumn] = useState<any[]>();
    const [mainData, setMainData] = useState<any[]>();
    const [mainQuery, setMainQuery] = useState<any>();
    const [categoryTree, setCategoryTree] = useState<ListDo[]>([]);
    const [supplierList, setSupplierList] = useState<any[]>([]);

    const [mainLoading, setMainLoading] = useState(false);

    const [valueChanged, setValueChanged] = useState<any>({});

    const [subMenu, setSubMenu] = useState<any[]>();
    // const [openKeys, setOpenKeys] = useState<string[]>([]);
    // const [rootKeys, setRootKeys] = useState([""]);

    const [productList, setProductList] = useState<any[]>();
    const [platList, setPlatList] = useState<any[]>();
    const [serverList, setServerList] = useState<any[]>();

   const [rawText, setRawText] = useState("");

    useEffect(() => {
        loadMainData();
    }, [props]);

    const loadMainData = () => {

        setMainLoading(true);
        AxiosUtil.AxiosRequest("/mallApi/supplier/list", {}, (suc, data1) => {
            if (suc && data1) {
                setSupplierList(data1.list);

                AxiosUtil.AxiosRequest('/mallApi/category/listTree', {id: 1},
                    (suc, data2) => {

                        setCategoryTree(data2);
                        if (suc && data2) {
                            const sub = data2.map((g: any) => ({
                                label: g.categoryName,
                                key: g.id,
                                icon: <SmileTwoTone twoToneColor={"#722ed1"}/>
                            }));
                            setSubMenu(sub);
                            if (mainQuery) {
                                makeMainData(mainQuery, data2);
                            }
                            setMainLoading(false);
                        }
                    })
            }
        });
    }

    let formRoot : Root;
    // let mainQuery: any;
    const makeMainData = (query : any, nowDatas? : any) => {
        const allData : any = nowDatas || categoryTree;

        let game: any;
        if (!query || !query.gameId) {
            game = allData?.[0];
            query.gameId = game!.id;
        } else {
            game = allData?.find((g:any) => (g.id === query.gameId));
        }
        setRootName(game!.text);

        const product = game?.children?.find((r: any) => (r.categoryCode == "products"))?.children || [];
        const skuProp = game?.children?.find((r: any) => (r.categoryCode == "skuprops"))?.children || [];
        const platform = skuProp?.find((r: any) => (r.categoryCode == "platform"))?.children || [];
        const servers = skuProp?.find((r: any) => (r.categoryCode == "server"))?.children || [];
        setProductList(product.map((r: any) => ({label: r.categoryName, value: r.id})))
        setPlatList(platform.map((r: any) => ({label: r.categoryName, value: r.id})))
        setServerList(servers.map((r: any) => ({label: r.categoryName, value: r.id})))

        query.product = query.product || product[0].id;
        query.platform = query.platform || platform[0].id;
        query.server = query.server || servers[0]?.id;
        queryForm.setFieldsValue(query);
        // setMainQuery(query);
        setMainQuery(query);

        const myData = product.find((r: any) => (r.id == query.product))?.children?.map((r: any) => {
            r.children = undefined;
            r.text = r.categoryName;
            return r;
        });

        // const randomKey = moment().format("X");
        // const column = getColumns(query);

        setMainData(myData);
        setColumn(getColumns(query));

        // const container = document.getElementById('mainTableForm');
        // if (formRoot === undefined) {
        //     formRoot = createRoot(container!);
        // }
        // formRoot.render(
        //     <Form id="mainForm" form={inputForm}>
        //         <Table key={randomKey}
        //                loading={mainLoading}
        //                bordered
        //                size="small"
        //                columns={column}
        //                dataSource={mainData}
        //                rowKey="id"
        //                pagination={false}
        //         />
        //     </Form>
        // )
    }

    const getColumns = (query: any) => {
        let col: any[] = [
            {
                title: "一级分类",
                width: 160,
                dataIndex: "text",
                render: (v: number, r: any) => <>{r.text}
                    <a onClick={()=>{setRawText(r.properties)}}>&nbsp;</a>
                    </>
            },
            {
                title: "前台价格系数",
                dataIndex: "_",
                width: 120,
                render: (v: number, r: any) =>
                    <Form.Item style={{marginBottom: 0}}
                               name={"price_" + query.server + "_" + query.platform + "_" + r.id}
                               initialValue={JSON.parse(r.properties)?.prc
                                       ?.["srv" + query.server]
                                       ?.["plt" + query.platform]?.price
                                   || JSON.parse(r.properties)?.purchase_price?.[0]?.price
                                   || 0.7}
                    >
                        <InputNumber style={{width: 64,}}>
                        </InputNumber>
                    </Form.Item>
            },
            ...supplierList?.map((su: any) => ({
                // col.push({
                title: su.supplierName,
                dataIndex: "_1",
                width: 120,
                render: (v: string, r: any) =>
                    <Form.Item style={{marginBottom: 0}} key={su.id + "_" + r.id}
                               initialValue={JSON.parse(r.properties)?.prc
                                       ?.["srv" + query.server]
                                       ?.["plt" + query.platform]?.["sup" + su.id]
                                   || JSON.parse(r.properties)?.purchase_price?.[0]?.price
                                   || 0.7}
                               name={"supplier_" + query.server + "_" + query.platform + "_" + r.id + "_" + su.id}>
                        <InputNumber style={{width: 64}} />
                    </Form.Item>
                // },)
            }))
            ,
            {
                title: "",
                width: 160,
                dataIndex: "text",
                align: "center" as "center",
                render: (v: number, r: any) =>
                    <Space size={16}>
                        <Button key="K1"
                                type="primary"
                                onClick={()=>saveRecord(false, r.id)}>
                            <SaveOutlined/> 保存
                        </Button>
                        <Button key="K2"
                                type="default"
                                onClick={()=>saveRecord(true, r.id)}>
                            <RedoOutlined/> 同步
                        </Button>
                    </Space>
            }
        ];
        return col;
    }

    const saveRecord = (sync : boolean, cateId : string) => {
        let value = inputForm.getFieldsValue();
        if (cateId != "") {
            // Return only the recorde whose category-id is equals that you provide
            let newvalue : any = {} ;
            Object.keys(value).forEach(r=> {
                if (r.split("_")[3] == cateId) {
                    newvalue[r] = value[r];
                }
            })
            value = newvalue;
        }
        console.log(value);
        if (sync) {
            let sy = "sync";
            platList?.forEach(r=> {
                sy += "_" + r.value
            })
            value[sy] = 1; //[];
        }

        setMainLoading(true);
        // console.log('save:', record);
        AxiosUtil.AxiosRequest('/mallApi/category/editPriceCoefficient', value,
            (suc, data) => {
                if (suc && data) {
                    console.log(suc, data)
                    message.success({content: '保存成功！', className: 'message_success'});
                    setMainLoading(false);
                    loadMainData();
                }
            })
    }

    const onClickMenu = (e: any) => {
        makeMainData({gameId: Number.parseInt(e.key)});
    }

    const crumb = [
        {name: '系统管理'},
        {name: '分类价格系数'},
        {name: rootName},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain collapsed={true}/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <Row gutter={24}>
                            <Col flex="240px">
                                {/*<div style={{height: 32, fontSize: 14, textAlign: "center"}}>*/}
                                {/*    订单分配规则</div>*/}
                                <Menu mode={"inline"}
                                      onClick={onClickMenu}
                                      items={subMenu}/>
                            </Col>
                            <Col flex={"auto"}>
                                <div id="mainTable">

                                    <Form form={queryForm}
                                        // onFinish={submitQuery}
                                          layout="horizontal"
                                          className={"queryForm"}
                                          labelCol={{span: 8, offset: 0}}
                                          colon={false}>
                                        <Row gutter={24}>
                                            <Col span={4}>
                                                <Form.Item name="product" label="品类">
                                                    <Select options={productList}
                                                            onChange={(e) => makeMainData({...mainQuery, product: e})}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item name="platform" label="平台">
                                                    <Select options={platList}
                                                            onChange={(e) => makeMainData({...mainQuery, platform: e})}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item name="server" label="服务器">
                                                    <Select options={serverList}
                                                            onChange={(e) => makeMainData({...mainQuery, server: e})}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {/*<Col span={4}>*/}
                                            {/*    <Button type={"primary"} htmlType={"submit"}><SearchOutlined/> 查询</Button>*/}
                                            {/*</Col>*/}
                                            <Col span={9} className="right">
                                                <Space size={16}>
                                                    <Button key="K1"
                                                            type="primary"
                                                            onClick={()=>saveRecord(false, "")}>
                                                        <SaveOutlined/> 保存价格系数
                                                    </Button>
                                                    <Button key="K2"
                                                            type="default"
                                                            onClick={()=>saveRecord(true, "")}>
                                                        <RedoOutlined/> 同步所有平台
                                                    </Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div id="mainTableForm">
                                        <Form id="mainForm" form={inputForm}>
                                            <Table key={"randomKey"}
                                                   loading={mainLoading}
                                                   bordered
                                                   size="small"
                                                   columns={column}
                                                   dataSource={mainData}
                                                   rowKey="id"
                                                   pagination={false}
                                            />
                                        </Form>
                                    </div>
                                </div>

                            </Col>
                        </Row>

                        <RawDataDrawer rawData={rawText}></RawDataDrawer>

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}


export default Coefficient;