import AxiosUtil from "../../../utils/AxiosUtil";
import {Button, Image, Layout, Space, Table} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import moment from "moment";
import { CheckCircleTwoTone, ExclamationCircleTwoTone, UploadOutlined} from "@ant-design/icons";
import DataUtil from "../../../utils/DataUtil";
import {ContractDo} from "../model/contractDo";
import MoneyUtil from "../../../utils/MoneyUtil";
import CashFlowForm from "./CashFlowForm";
import {FileDo} from "../model/fileDo";
import FileForm from "./FileForm";
import IconUtil from "../../../utils/IconUtil";


const ContractFormFile = (props: any, ref: any) => {

    const formRef = useRef();
    const [contractInfo, setContractInfo] = useState<ContractDo | undefined>(undefined);
    const [mainData, setMainData] = useState<FileDo[]>([]);

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ContractDo) => openForm(r)
        }
    });

    useEffect(() => {
        loadMainData();
    }, [contractInfo])

    const openForm = (r: ContractDo) => {
        if (r) {
            setContractInfo(r);
        } else {
            setContractInfo(undefined);
        }
    }

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list);
            }
        }
        if (contractInfo && contractInfo.id) {
            AxiosUtil.AxiosRequest('/crmApi/files/list', {relType:257, relId : contractInfo.id}, callback);
            console.log("loadFile - ok")
        } else {
            setMainData([]);
        }
    }


    const openEdit = (r?: FileDo) => {
        console.log(r);
        (formRef.current as any).openForm(r, contractInfo);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 48,
        },
        {
            title: "类型",
            dataIndex: "typeName",
            align: "center" as "center",
            width: 64,
            ellipsis: true,
        },
        {
            title: "名称",
            dataIndex: "fileName",
            width: 160,
            ellipsis: true,
            render: (v: string, r: FileDo) => (<a onClick={() => openEdit(r)}>{v}</a>),
        },
        {
            title: "文件",
            dataIndex: "fileExt",
            width: 48,
            align: "center" as "center",
            render: (v: string, r: FileDo) => (
                <a href={r.fileUrl} target="_blank">{IconUtil.docIcon(v)}</a>)
        },
        {
            title: "存档", dataIndex: "filePaper", align: "center" as "center", width: 48,
            render: (v: number) => (
                v == 1 ? <CheckCircleTwoTone twoToneColor="#52c41a"/>
                    : v == 0 ? <ExclamationCircleTwoTone twoToneColor="#d48806"/>
                        : ''),
        },
        {
            title: "上传时间", dataIndex: "updateTime", align: "center" as "center", width: 80,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD')
            },
        },

        {
            title: "备注", dataIndex: "remark", width: 80, ellipsis: true,
        },
    ]

    return (
        <Layout>
            <Space size={16} style={{margin: 8}}>
                <Button type="primary"
                        key='showEdit'
                        size={"small"}
                        onClick={() => openEdit()}
                >
                    <UploadOutlined/> 上传附件
                </Button>
            </Space>
            <Table key='contract'
                   rowKey='id'
                   columns={columns}
                   dataSource={mainData}
                   size="small"
                   bordered
                   pagination={false}
            />

            <FileForm ref={formRef} relType={257} relId={contractInfo?.id} callback={loadMainData}/>

        </Layout>
    );
}

export default forwardRef(ContractFormFile);