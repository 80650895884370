import {Form, InputNumber, Table} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {CashFlowDo} from "../../crm/model/cashFlowDo";

const PropertiesTable = (props: any, ref: any) => {

    const [formRef] = Form.useForm();
    const [mainData, setMainData] = useState<any[]>(props.datas);
    const [columns, setColumns] = useState<any[]>([]);

    useEffect(()=>{
        initPropertiesColumns();
    },[props])

    const initPropertiesColumns = () => {
        const col: any[] = [
            {
                title: 'ID',
                key: 'id',
                align: 'center' as 'center',
                render: (v: number, r: any, i: number) => i + 1
            },
        ];

        for (let i = 0; i < props.properties.length; i++) {
            col.push({
                title: props.properties[i].name,
                key: props.properties[i].id,
                dataIndex: props.properties[i].code+'_name'
            });
        }

        for (let i = 0; i < props.values.length; i++) {
            col.push({
                title: props.values[i].name,
                dataIndex: props.values[i].id,
                key: props.values[i].id,
                render: (v: string, r: any, idx: number) =>
                    <Form.Item
                        initialValue={v}
                        name={r.key + '_' + props.values[i].id}
                        style={{marginBottom: 0}}>
                        <InputNumber style={{width: 64}}/>
                    </Form.Item>
            });
        }
        setColumns(col);

        if (!mainData || mainData.length === 0) {
            let dataArr: any[] = [];
            const doArrayComb = (propsArr: any[]) => {
                if (propsArr.length === 0) {
                    return;
                } else if (dataArr.length === 0) {
                    for (let i = 0; i < propsArr[0].data.length; i++) {
                        let r: any = {};
                        r.key = propsArr[0].data[i].id;
                        r[propsArr[0].code+'_id'] = propsArr[0].data[i].id;
                        r[propsArr[0].code+'_code'] = propsArr[0].data[i].catalogCode;
                        r[propsArr[0].code+'_name'] = propsArr[0].data[i].catalogName;
                        dataArr.push(r);
                    }
                    propsArr.shift();
                    doArrayComb([...propsArr]);
                } else {
                    let newArr: any[] = [];
                    for (let j = 0; j < dataArr.length; j++) {

                        for (let i = 0; i < propsArr[0].data.length; i++) {
                            let r: any = {...dataArr[j]};
                            r.key += '_' + propsArr[0].data[i].id;
                            r[propsArr[0].code+'_id'] = propsArr[0].data[i].id;
                            r[propsArr[0].code+'_code'] = propsArr[0].data[i].catalogCode;
                            r[propsArr[0].code+'_name'] = propsArr[0].data[i].catalogName;
                            newArr.push(r);
                        }
                    }
                    dataArr = [...newArr];
                    propsArr.shift();
                    doArrayComb([...propsArr]);
                }
            }
            doArrayComb([...props.properties]);
            setMainData(dataArr)
        }
    }

    useImperativeHandle(ref, () => {
        return {
            getFormValues: (r: CashFlowDo) => getFormValues()
        }
    });
    const getFormValues = () => {
        const values = formRef.getFieldsValue();
        const datas = mainData;
        console.log(values);
        console.log(datas)
        for (let i = 0; i < datas.length; i++) {
            for (let v = 0; v < props.values.length; v++) {
                datas[i][props.values[v].id]=values[datas[i].key+'_'+props.values[v].id] | 1;
            }
        }
        console.log(datas)
        return formRef.getFieldsValue();
    }

    return (
        <div>
            <Form form={formRef} component={false} >
                <Table
                    id={'propTable'}
                    key={'propTable'}
                    rowKey={'key'}
                    bordered
                    size="small"
                    dataSource={mainData}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    indentSize={24}
                    defaultExpandAllRows={true}
                    expandIconColumnIndex={1}
                />
            </Form>
        </div>
    )
}
export default forwardRef(PropertiesTable);