import {Button, Col, Form, Input, InputNumber, Layout, message, Row, Space, Table} from "antd";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavBreadcrumb from "../../../components/NavBreadcrumb";
import NavFooter from "../../../components/NavFooter";
import React, {useEffect, useRef, useState} from "react";
import {Content} from "antd/es/layout/layout";
import {Query} from "../../../model/query";
import moment from "moment";
import { SearchOutlined, ExportOutlined} from "@ant-design/icons";
import FetchUtil from "../../../utils/FetchUtil";


const CouponIndex =()=>{

    const formRef = useRef();
    const [queryForm] = Form.useForm();
    const [createForm] = Form.useForm();

    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState();
    const [query, setQuery] = useState<Query>({
        pageSize: 20,
        pageNo: 1,
        orderBy: "create_time desc",
    } as Query);


    useEffect(() => {
        loadMainData();
    }, [query]);

    useEffect(() => {
        // SimpleList.param(99, (d) => (setTypeData(d)));
    }, []);

    const loadMainData = async () => {

        const rtn: any = await FetchUtil.awaitFetch('/mallApi/coupon/list', query);
        if (rtn.code == 200 && rtn.data != null) {
            setMainData(rtn.data.list)
            setTotal(rtn.data.total)
        }
    }
    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

        let querys = {...query};
        switch (extra.action) {
            case "paginate":
                querys.pageNo = querys.pageSize == pagination.pageSize ? pagination.current : 1;
                querys.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order == "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    querys.orderBy = "";
                }
                break;
            case "filter":
                break;
        }
        setQuery(querys)
    }

    const submitForm = () => {
        let values = queryForm.getFieldsValue();
        setQuery({...query, ...values});
    }
    const submitCreate = async () => {
        let values = createForm.getFieldsValue();
        console.log(values);

        const rtn: any = await FetchUtil.awaitFetch('/mallApi/coupon/createCoupon', {...values,coupon:0});
        if (rtn.code == 200 && rtn.data != null) {
            message.success("优惠券已生成")
            loadMainData()
        }else{
            message.error(rtn.msg);
        }
    }


    const crumb = [
        {name: '线上商城'},
        {name: '优惠券管理'},
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
        },
        {
            title: 'Code',
            dataIndex: 'couponCode',
            align: 'center' as 'center',
        },
        {
            title: '折扣',
            dataIndex: 'discount',
            align: 'center' as 'center',
        },
        {
            title: '用户邮箱',
            dataIndex: 'userEmail',
            align: 'center' as 'center',
        },
        {
            title: '有效期',
            dataIndex: 'expiryDate',
            align: 'center' as 'center',
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center' as 'center',
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
    ];
    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <Row>
                            <Col span={10}>
                                <Form form={queryForm} onFinish={submitForm} layout="horizontal"
                                      className={"queryForm"} colon={false}>
                                    <Space size={8}>
                                        <Form.Item name="text" label=""  >
                                            <Input style={{width:200}} placeholder="输入邮箱或者优惠码查询" allowClear={true}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type={"primary"}  htmlType={"submit"}><SearchOutlined /> 查询</Button>
                                        </Form.Item>
                                    </Space>
                                </Form>
                            </Col>
                            <Col span={14} className="right">
                                <Form form={createForm} onFinish={submitCreate} layout="horizontal"
                                      labelAlign={"right"}
                                      className={"queryForm"} colon={false}>
                                    <Space size={8}>
                                        <Form.Item name="couponCode" label="自定义优惠码"
                                                   rules={[{required: true}]}  >
                                            <Input style={{width:120}} placeholder="优惠码"/>
                                        </Form.Item>
                                        <Form.Item name="discount" label="折扣(%)"
                                                   rules={[{required: true, min: 0, max: 100, type: "number"}]} >
                                            <InputNumber style={{width:100}} placeholder="%" />
                                        </Form.Item>
                                        <Form.Item name="userEmail" label="Email"
                                                   rules={[{required: true, type: "email"}]}>
                                            <Input style={{width:200}} placeholder="发送到用户邮箱"/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type={"primary"} htmlType={"submit"}><ExportOutlined /> 生成</Button>
                                        </Form.Item>
                                    </Space>
                                </Form>
                            </Col>
                        </Row>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               pagination={{
                                   // onChange: this.onPageChange,
                                   total: total,
                                   pageSize: query.pageSize,
                                   current: query.pageNo
                               }}
                        />



                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default CouponIndex;