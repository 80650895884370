import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import Page404 from "../pages/page404";

import Function from "../pages/admin/function";
import Param from "../pages/admin/param";
import User from "../pages/admin/user";
import Info from "../pages/admin/info";
import Log from "../pages/admin/log";
import AliSecurity from "../pages/admin/aliSecurity";

import SyncList from "../pages/dbsync/list";

import Contract from "../pages/crm/contract"
import Invoice from "../pages/crm/invoice";
import CashFlow from "../pages/crm/cashFlow";
import WxAppList from "../pages/wx/appList";
import WxAppCache from "../pages/wx/appCache";
import authOk from "../pages/wx/authOk";

import MallIndex from  "../pages/mall/index"
import Category1 from "../pages/mall/sku/category";
import MallExchange from "../pages/mall/support/exchangeList";
import Item from "../pages/mall/sku/item";
import Coupon from "../pages/mall/coupon";
import Coefficient from "../pages/mall/sku/coefficient";
import PaymentConfig from "../pages/mall/support/paymentConfig";
import EdmTask from "../pages/mall/support/edmTask";
import AdminRole from "../pages/admin/role";
import MallInfo from "../pages/mall/info"
import OrderIndex from "../pages/mall/order/orderList";
import OrderAllocate from "../pages/mall/order/orderAllocate";
import OrderSettle from "../pages/mall/order/orderSettle";
import EaTest from "../pages/eaTest";
import WebSocket from "../pages/admin/ws";
import Dist from "../pages/admin/dist";
import Ntest from "../pages/ntest";
import New1 from "../pages/newMenu"
import Test2 from "../pages/test2";

import ItemIndex from "../pages/games/item/index"
import Login from "../pages/login";
import ChangePass from "../pages/system/changePass";
import Category from "../pages/games/category";

export const MyRouter = function () {
    return (
        <Switch>
            <Route exact={true} path="/login" component={Login}/>
            <Route exact={true} path="/system/changePass" component={ChangePass}/>
            <Route exact={true} path="/page404" component={Page404}/>

            <Route exact={true} path="/games/category" component={Category}/>

            <Route exact={true} path="/ntest" component={Ntest}/>
            <Route exact={true} path="/new" component={New1}/>
            <Route exact={true} path="/test2" component={Test2}/>

            <Route exact={true} path="/help" component={Page404}/>
            <Route exact={true} path="/about" component={Page404}/>
            <Route exact={true} path="/eaTest" component={EaTest}/>
            <Route exact={true} path="/ws" component={WebSocket}/>

            <Route exact={true} path="/admin/function/:rootId?" component={Function}/>
            <Route exact={true} path="/admin/user/:rtype?" component={User}/>
            <Route exact={true} path="/admin/param" component={Param}/>
            <Route exact={true} path="/admin/dist" component={Dist}/>
            <Route exact={true} path="/admin/role" component={AdminRole}/>
            <Route exact={true} path="/admin/log/:type?" component={Log}/>
            <Route exact={true} path="/admin/aliSecurity" component={AliSecurity}/>

            <Route exact={true} path="/admin/info" component={Info}/>
            <Route exact={true} path="/admin/info/:typeId?/:typeName?" component={Info}/>

            <Route exact={true} path="/mall/" component={MallIndex}/>
            <Route exact={true} path="/mall/sku/category/:parentId?/:parentName?" component={Category1}/>
            <Route exact={true} path="/mall/sku/coefficient" component={Coefficient}/>
            <Route exact={true} path="/mall/sku/item/:class1?" component={Item}/>
            <Route exact={true} path="/mall/exchange" component={MallExchange}/>
            <Route exact={true} path="/mall/coupon" component={Coupon}/>
            <Route exact={true} path="/mall/paymentConfig" component={PaymentConfig}/>
            <Route exact={true} path="/mall/edm" component={EdmTask}/>
            <Route exact={true} path="/mall/info/:type/:pid?" component={MallInfo}/>
            <Route exact={true} path="/mall/order/" component={OrderIndex}/>
            <Route exact={true} path="/mall/order/orderAllocate/:rootId?" component={OrderAllocate}/>
            <Route exact={true} path="/mall/order/orderSettle" component={OrderSettle}/>

            <Route exact={true} path="/mall/item/:class1?" component={ItemIndex}/>

            <Route exact={true} path="/crm/contract/:code?" component={Contract}/>
            <Route exact={true} path="/crm/invoice" component={Invoice}/>
            <Route exact={true} path="/crm/cashFlow" component={CashFlow}/>

            <Route exact={true} path="/dbsync/list" component={SyncList}/>

            <Route exact={true} path="/wx/appList" component={WxAppList}/>
            <Route exact={true} path="/wx/appCache" component={WxAppCache}/>
            <Route exact={true} path="/wx/authOk" component={authOk}/>

            <Redirect path="/games" to="/games/category" />

            <Redirect from="/admin" to="/admin/log"/>
            <Redirect from="/crm" to="/crm/contract"/>
            <Redirect from="/wx" to="/wx/appList"/>
            <Redirect from="/*" to="/page404"/>

        </Switch>
    )
}
