import { useEffect, useState} from "react";
import React from "react";
import {Button, Col, Divider, Layout, message, Row, Space, Table, Tag, Typography} from "antd";
import AxiosUtil from "../../utils/AxiosUtil";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import NavFooter from "../../components/NavFooter";
import {
    PlayCircleOutlined, PauseCircleOutlined,
    RedoOutlined, StopOutlined, CodeOutlined
} from "@ant-design/icons";
import moment from "moment";

const {Content} = Layout;

// interface IProps {
//     match: any
// }

const SyncList = () => {

    const [mainData,setMainData] = useState<any[]>([]);
    const [mainStatus,setMainStatus] = useState("");
    const [mainTime,setMainTime] = useState("");

    // let timer: NodeJS.Timeout | undefined;

    useEffect(()=>{
        loadMainData();
        // timer = setInterval(() => loadMainData(), 10000);
    })


    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/adminApi/dbSync/list', {id: 1},
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list);
                    setMainStatus(dat.status);
                    setMainTime(dat.time);
                }
            });
    }
    const callbackCommon = (suc: boolean, dat: any) => {
        if (suc) {
            message.success({content: dat.msg});
        }
    }
    const startSync = () => {
        AxiosUtil.AxiosRequest('/dbsync/action.ashx?act=startDbSyncList', {}, callbackCommon);
    }
    const stopSync = () => {
        AxiosUtil.AxiosRequest('/dbsync/action.ashx?act=stopDbSyncList', {}, callbackCommon);
    }
    const restartSync = () => {
        AxiosUtil.AxiosRequest('/dbsync/action.ashx?act=restartDbSyncList', {}, callbackCommon);
    }
    const disableSync = (id: number) => {
        AxiosUtil.AxiosRequest('/dbsync/action.ashx?act=disableTable&tid=' + id, {}, callbackCommon);
    }
    const enableSync = (id: number) => {
        AxiosUtil.AxiosRequest('/dbsync/action.ashx?act=enableTable&tid=' + id, {},callbackCommon);
    }

    const crumb = [
        {name: '数据同步'},
        {name: '数据同步'},
    ];

    // @ts-ignore
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 1,
        },
        // {
        //     title: '库名',
        //     key: 'target_db',
        //     dataIndex: 'target_db',
        //     width: 80,
        // },
        {
            title: '表名',
            dataIndex: 'tableName',
            width: 150,
            sorter: {compare: (a: any, b: any) => a.table_name.localeCompare(b.table_name)},
            render: (t: string, record: any) => (record.sync_model === 1 ? t + "（★）" : t)
        },
        {
            title: '结构',
            dataIndex: 'tableName',
            width: 150,
            sorter: {compare: (a: any, b: any) => a.table_name.localeCompare(b.table_name)},
            render: (t: string, record: any) => (record.sync_model === 1 ? t + "（★）" : t)
        },
        {
            title: '状态',
            dataIndex: 'sync_status',
            width: 70,
            align: 'center' as 'center',
            sorter: {compare: (a: any, b: any) => b.status - a.status == 0 ? a.sync_status - b.sync_status : b.status - a.status},
            defaultSortOrder: 'ascend' as 'ascend',
            render: (s: number, record: any) => (
                record.status === 0 ? <Tag>禁用</Tag> :
                    s === 1 ? <Tag color="blue">等待</Tag> :
                        s === 2 ? <Tag color="#87d068">同步中</Tag> :
                            s === 3 ? <Tag color="#108ee9">完成</Tag> :
                                s === 4 ? <Tag color="magenta">停止中</Tag> :
                                    s === 5 ? <Tag color="red">已停止</Tag> :
                                        s === 9 ? <Tag color="#f50">出错</Tag> :
                                            s === 10 ? <Tag color="green">休眠</Tag> :
                                                s === 99 ? <Tag>禁用</Tag> :
                                                    <Tag color="cyan">Init</Tag>
            ),
        },
        {
            title: 'MaxTs',
            dataIndex: 'maxTs',
            width: 100,
            align: 'right' as 'right',
        },
        {
            title: '记录数',
            dataIndex: 'totalCount',
            width: 150,
            align: 'right' as 'right',
        },
        {
            title: '最新',
            dataIndex: 'lastCount',
            width: 70,
            align: 'center' as 'center',
            render: (v: number) => (`${v?.toFixed(0)}`),
        },
        {
            title: '更新时间',
            dataIndex: 'lastTime',
            width: 150,
            render: (text: any) => {
                return text === null ? '' : moment(text).format('yyyy-MM-DD HH:mm:ss')
            },
            sorter: {compare: (a: any, b: any) => a.last_time.localeCompare(b.last_time)},
        },
        {
            title: '操作',
            key: 'operation',
            dataIndex: 'operation',
            width: 100,
            align: 'center' as 'center',
            render: (_: any, record: any) => {
                return (
                    <Space size="small" split={<Divider type="vertical"/>}>
                        <Typography.Link key={'d' + record.id}
                                         hidden={record.status === 1}
                                         onClick={() => enableSync(record.id)}><PlayCircleOutlined/>
                        </Typography.Link>
                        <Typography.Link key={'e' + record.id}
                                         hidden={record.status === 0}
                                         onClick={() => disableSync(record.id)}><StopOutlined/>
                        </Typography.Link>
                        <Typography.Link key={'l' + record.id}><CodeOutlined/>
                        </Typography.Link>
                    </Space>
                )
            }
        },
    ];

    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Row>
                                <Col span={12}>
                                    <Space size={16} style={{marginBottom: 16}}>
                                        <Button type="primary" key='start' onClick={startSync}
                                                disabled={mainStatus !== "Unstarted"}>
                                            <PlayCircleOutlined/> 开始
                                        </Button>
                                        <Button type="primary" key='stop' onClick={stopSync}
                                                disabled={mainStatus !== "WaitSleepJoin"}>
                                            <PauseCircleOutlined/> 停止
                                        </Button>
                                        <Button type="primary" key='restart' onClick={restartSync}
                                                disabled={mainStatus !== "WaitSleepJoin"}>
                                            <RedoOutlined/> 重启
                                        </Button>
                                    </Space>
                                </Col>
                                <Col span={12} style={{textAlign: "right"}}>
                                    {mainTime}（{mainStatus}）
                                </Col>
                            </Row>
                            <Table key='user'
                                   rowKey='id'
                                   columns={columns}
                                   dataSource={mainData}
                                   size="small"
                                   bordered
                                   pagination={false}
                            />
                        </div>

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );


}
export default SyncList;
