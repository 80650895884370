import {Button,  Layout, Space, Table} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import { RedoOutlined, UploadOutlined} from "@ant-design/icons";
import NavFooter from "../../components/NavFooter";
import React, {useEffect, useRef, useState} from "react";
import AxiosUtil from "../../utils/AxiosUtil";
import moment from "moment";
import DataUtil from "../../utils/DataUtil";
import {ListDo} from "../admin/model/listDo";
import {CashFlowDo} from "./model/cashFlowDo";
import CashFlowUpload from "./components/CashFlowUpload";
import {CashFlowQuery} from "./model/cashFlowQuery";
import SimpleList from "../admin/components/SimpleList";
import CashFlowForm from "./components/CashFlowForm";
import ColumnFilterSearchProp from "../../components/ColumnFilterSearchProp";
import ColumnFilterDateProp from "../../components/ColumnFilterDateProp";
import ColumnFilterNumberProp from "../../components/ColumnFilterNumberProp";

const {Content} = Layout;

 const  CashFlow = () => {

     const formRef = useRef();
     const uploadRef = useRef();
     const defaultSort = "cashflow_date DESC, cashflow_no DESC";

     const [total, setTotal] = useState(0);
     const [mainData, setMainData] = useState<CashFlowDo[]>([]);
     const [typeData, setTypeData] = useState<ListDo[]>([]);
     const [cashFlowQuery, setCashFlowQuery] = useState<CashFlowQuery>({
         pageSize: 20,
         pageNo: 1,
         orderBy: defaultSort,
     } as CashFlowQuery);

     useEffect(() => {
         loadMainData();
     }, [cashFlowQuery]);

     useEffect(() => {
         SimpleList.param(99, (d) => (setTypeData(d)));
     }, []);

     const loadMainData = () => {
         const callback = (suc: boolean, dat: any) => {
             if (suc) {
                 setMainData(dat.list)
                 setTotal(dat.total)
             }
         }
         AxiosUtil.AxiosRequest('/crmApi/cashFlow/list', cashFlowQuery, callback);
         console.log("loadMainData - ok")
     }
     const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

         let query = {...cashFlowQuery};
         switch (extra.action) {
             case "paginate":
                 query.pageNo = query.pageSize == pagination.pageSize ? pagination.current : 1;
                 query.pageSize = pagination.pageSize
                 break;
             case "sort":
                 if (sorter.order) {
                     let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                     orderBy += sorter.order == "ascend" ? " ASC" : " DESC";
                     query.orderBy = orderBy;
                 } else {
                     query.orderBy = defaultSort;
                 }
                 break;
             case "filter":
                 query.dc = filters.dc;
                 query.cashflowType = filters.cashflowType;
                 break;
         }
         setCashFlowQuery(query)
     }

     const onSearch = (f: string, v: any) => {
         console.log("onSearch", v);
         let query: any = {...cashFlowQuery};
         query[f]  = v.value;
         setCashFlowQuery(query);
     }

     const onSearchNumber = (f: string, v: any) => {
         console.log("onSearchDate", v);
         let query: any = {...cashFlowQuery};
         query[f + "Min"] = v.value1;
         query[f + "Max"] = v.value2;
         setCashFlowQuery(query);
     }

     const onSearchDate = (f: string, v: any) => {
         console.log("onSearchDate", v);
         let query: any = {...cashFlowQuery};
         if (v.value.length != 2) {
             return
         }
         query[f + "Min"] = v.value[0] ? v.value[0].format('yyyy-MM-DD') : null;
         query[f + "Max"] = v.value[1] ? v.value[1].format('yyyy-MM-DD') : null;
         setCashFlowQuery(query);
     }


     const crumb = [
         {name: '客户管理'},
         {name: '资金流水'},
     ];

     const columns = [
         {
             title: 'ID',
             dataIndex: 'id',
             align: 'center' as 'center',
             width: 48,
         },
         {
             title: "流水号", dataIndex: "cashflowNo", align: "center" as "center", width: 100,
             sorter: true,
             ellipsis: true,
             render: (v: string, r: CashFlowDo) => (<a onClick={() => openEdit(r)}>{v}</a>),
             ...ColumnFilterSearchProp("cashflowNo", "流水号", onSearch)
         },
         {
             title: "日期", dataIndex: "cashflowDate", align: "center" as "center", width: 100,
             sorter: true,
             render: (v: number) => {
                 return v === null ? '' : moment(v).format('yyyy-MM-DD')
             },
             ...ColumnFilterDateProp("cashflowDate", "日期", onSearchDate),
         },
         // {
         //     title: "借贷", dataIndex: "dc", align: "center" as "center", width: 64,
         //     sorter: true,
         // },
         {
             title: "借方", dataIndex: "outMoney", align: "right" as "right", width: 88,
             sorter: true,
             render: (v: number) => (<span className="red">{DataUtil.formatNumber(v, 2)}</span>),
             ...ColumnFilterNumberProp("outMoney","借方金额",onSearchNumber),
         },
         {
             title: "贷方", dataIndex: "inMoney", align: "right" as "right", width: 88,
             sorter: true,
             render: (v: number) => (<span className="blue">{DataUtil.formatNumber(v, 2)}</span>),
             ...ColumnFilterNumberProp("inMoney","贷方金额",onSearchNumber),
         },
         {
             title: "余额", dataIndex: "balance", align: "right" as "right", width: 88,
             sorter: true,
             render: (v: number) => (DataUtil.formatNumber(v, 2)),
             ...ColumnFilterNumberProp("balance","余额",onSearchNumber),
         },
         {
             title: "摘要", dataIndex: "summary", align: "center" as "center", width: 100,
             sorter: true,
             ellipsis: true,
             ...ColumnFilterSearchProp("summary", "摘要", onSearch),
         },
         {
             title: "对方户名", dataIndex: "customerName", width: 100, ellipsis: true,
             ...ColumnFilterSearchProp("customerName", "对方户名", onSearch),},
         {
             title: "用途", dataIndex: "memo1", width: 100, ellipsis: true,
             ...ColumnFilterSearchProp("memo1", "用途", onSearch),},
         {
             title: "备注", dataIndex: "memo2", width: 100, ellipsis: true,
             render: (v: string, r: CashFlowDo) => (
                 <a onClick={() => openEdit(r)} style={{width:"100%",display:"block"}}>{v}&nbsp;</a>
             ),
             ...ColumnFilterSearchProp("memo2", "备注", onSearch),
         },
         {
             title: "科目", dataIndex: "typeName", width: 100, ellipsis: true,
             render: (v: string, r: CashFlowDo) => (
                 <a onClick={() => openEdit(r)} style={{width:"100%",display:"block"}}>{v}&nbsp;</a>
             ),
             sorter: true,
             filters: typeData,
             filteredValue: cashFlowQuery.cashflowType
         },
         {
             title: "合同", dataIndex: "contractTitle", width: 100, ellipsis: true,
             render: (v: string, r: CashFlowDo) => (
                 <a onClick={() => openEdit(r)} style={{width: "100%", display: "block"}}>{v}&nbsp;</a>
             ),
             ...ColumnFilterSearchProp("contractTitle", "合同", onSearch),
         },
     ]

     const openUpload = () => {
         (uploadRef.current as any).openForm();
     };

     const openEdit = (r?: CashFlowDo) => {
         console.log(r);
         (formRef.current as any).openForm(r);
     }

     return (

         <Layout>
             <NavHeader/>
             <Content className={"main-body"}>
                 <Layout>
                     <SiderMenuMain/>
                     <Content className={'main-content'}>
                         <NavBreadcrumb itemList={crumb}/>
                         <div id="mainTable">
                             <Space size={16} style={{marginBottom: 16}}>
                                 <Button type="primary" key='showUpload'
                                         onClick={openUpload}><UploadOutlined/> 上传银行账单</Button>
                                 <Button type="primary" key='reload'
                                         onClick={loadMainData}><RedoOutlined/> 刷新</Button>
                             </Space>
                         </div>

                         <Table key='contract'
                                rowKey='id'
                                columns={columns}
                                dataSource={mainData}
                                size="small"
                                onChange={onTableChange}
                                bordered
                                pagination={{
                                    // onChange: this.onPageChange,
                                    total: total,
                                    pageSize: cashFlowQuery.pageSize,
                                    current: cashFlowQuery.pageNo
                                }}
                         />

                         <CashFlowUpload ref={uploadRef} callback={loadMainData}/>
                         <CashFlowForm ref={formRef} callback={loadMainData} typeData={typeData}/>


                     </Content>
                 </Layout>
             </Content>
             <NavFooter/>
         </Layout>
     );
 }

 export default CashFlow