import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Login from "../pages/login1";
import Login2 from "../pages/login";

export const NoLoginRoute = function () {
    return (
        <Switch>
            <Route exact={true} path="/login" component={Login} />
            <Route exact={true} path="/login2" component={Login2} />
            <Redirect from="/*" to="/login"/>
        </Switch>
    )
}
