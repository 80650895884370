import store from "store";
import FetchUtil from "./FetchUtil";
import React from "react";


export default class LanguageUtil {

    static getLangData = () => {
        let langData : any = store.get("langData");
        // if (langData == undefined || langData.length == undefined || langData.length == 0) {
        //     langData : let any = this.getL();
        //     store.set('langData', langData);
        // }
        return langData.map((r: any) => {
            return {
                langCode:r.langCode,
                langName:r.langName,
                icon: r.icon,
                key: r.langCode,
                value:  r.langCode,
                label: <span><img src={r.icon} style={{height: 12}} alt=""/> {r.langName}</span>
            }
        })
    }

    static getLanguage = async () => {
        const d: any = await FetchUtil.awaitFetch("/adminApi/language/list", {});
        return  d.data.list;
    }
}