import React, { useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {Button, Col, Drawer, Form, Input, Row,  Modal, InputNumber} from "antd";
import {WechatOutlined, SaveOutlined, InfoCircleOutlined, ExpandOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import 'antd/es/modal/style';
import AxiosUtil from "../../../utils/AxiosUtil";
import {AppConfigDo} from "../model/AppConfigDo";
import RawDataViewer from "../../../components/RawDataViewer";
import Link from "antd/es/typography/Link";

const {confirm} = Modal

const AppForm = (props: any, ref: any) => {

    const [formRef] = Form.useForm();

    const [appInfo, setAppInfo] = useState<AppConfigDo | undefined>(undefined);

    const [showForm, setShowForm] = useState(false);
    const [showForm2, setShowForm2] = useState(false);
    const [form2Text, setForm2Text] = useState<string>("");

    let changedValues = false;

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: AppConfigDo) => openForm(r)
        }
    });

    useEffect(() => {
        // loadContractData();
        // SimpleList.param(99, (d) => (setTypeData(d)));
    }, []);

    const openForm = (r: AppConfigDo) => {
        if (r) {
            setAppInfo(r);
            formRef.setFieldsValue(r);

            AxiosUtil.AxiosRequest('/appApi/config/detail', {id: r.id},
                (suc: boolean, dat: any) => {
                    if (suc) {
                        setAppInfo(dat)
                        formRef.setFieldsValue(dat);
                    }
                });
        } else {
            setAppInfo(undefined);
            formRef.resetFields();
        }
        setShowForm(true);
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    const onFinishForm = () => {
        let values = formRef.getFieldsValue();
        console.log(values);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        AxiosUtil.AxiosRequest('/appApi/config/edit', [values], callback);
    }

    const openForm2 = (text: string | undefined) => {
        if (text === undefined)
            text = '';
        setForm2Text(text);
        setShowForm2(true);
    }
    const closeForm2 = () => {
        setShowForm2(false);
    }
    return (
        <Drawer placement="right"
                width={800}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12}>
                            <WechatOutlined/> App Info
                        </Col>
                        <Col span={12}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64}}
                                    onClick={() => {
                                        formRef.submit()
                                    }}
                            >
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>

            <Form id="appForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 8, offset: 0}}
                  form={formRef}
                  style={{marginRight: 24}}
                  onValuesChange={() => {
                      changedValues = true
                  }}
            >
                <Form.Item hidden name={'id'} initialValue={0}><Input/></Form.Item>

                <Row>
                    <Col span={12}>
                        <Form.Item name={'appId'} label={'App Id'}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'origId'} label={'原始ID'}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'mchId'} label={'商户ID'}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'appName'} label={'APP名称'}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'appType'} label={'类型'}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'componentId'} label={'componentId'}>
                            <Input suffix={<InfoCircleOutlined/>}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'sortIndex'} label={'排序'}>
                            <InputNumber/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'token'} label={'token'} labelCol={{span: 4}}>
                            <Input.Password/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'appSecret'} label={'App Secret'} labelCol={{span: 4}}>
                            <Input.Password/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'encodingAesKey'} label={'Aes Key'} labelCol={{span: 4}}>
                            <Input.Password/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'authInfo'} label={
                            <span>授权信息
                                <Link onClick={() => openForm2(appInfo?.authInfo)}> <ExpandOutlined/></Link>
                            </span>
                        } labelCol={{span: 4}}>
                            <Input.TextArea readOnly={true} style={{height: 80}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'rawData'} label={
                            <span>原始数据
                                <Link onClick={() => openForm2(appInfo?.rawData)}> <ExpandOutlined/></Link>
                            </span>
                        } labelCol={{span: 4}}>
                            <Input.TextArea readOnly={true} style={{height: 80}}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'remark'} label={'备注'} labelCol={{span: 4}}>
                            <Input.TextArea style={{height: 80}}/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            <Drawer placement="right"
                    width={680}
                    visible={showForm2}
                    onClose={closeForm2}
                    title={<span><ExpandOutlined/> 元数据</span>}>
                <RawDataViewer rawData={form2Text} label="Post Raw"/>
            </Drawer>
        </Drawer>
    )
}

export default forwardRef(AppForm);