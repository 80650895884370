import {PageContainer, ProCard, ProLayout} from "@ant-design/pro-components";
import {LogoutOutlined, UnlockOutlined, UserOutlined} from "@ant-design/icons";
import {Dropdown, Image, message} from "antd";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import store from "store";
import Link from "antd/es/typography/Link";
import * as Icon from "@ant-design/icons";
import dLogo from "../images/game.svg"
import cookie from "react-cookies";

interface IProps {
    children: React.ReactNode;
    siderCollapse?: boolean;
}

const MyProLayout = (props: IProps) => {
    const login_info = store.get('login_info');
    const history = useHistory()
    const hashPath = window.location.hash
    const pathName = hashPath.substring(1);
    console.log(pathName)

    // const [pathname, setPathname] = useState(hashPath);
    const [siderCollapse,serSiderCollapse] =useState(false)

    useEffect(()=>{
        serSiderCollapse(props.siderCollapse || false);
    },[props])
    const getMenu: any = (funList: any[]) => {
        return funList.map((r: any) => {
            return {
                // key: r.functionId,
                // locale: r.pageUrl,
                name: <Link href={`#/${r.pageUrl}`} >{r.functionName}</Link>,
                path: "/"+r.pageUrl,
                // @ts-ignore
                icon: r.icon in Icon ? React.createElement(Icon[r.icon]) : r.icon,
                routes: getMenu(r.children),
            }
        })
    }
    const fun = store.get("login_power");
    const routes = getMenu(fun);

    console.log("routes", routes);
    const menus = {path: '/', routes: routes};

    const changePass = (e:any) => {
        console.log(e)
        history.push("/system/changePass")
    }
    const logout = (e:any) => {
        console.log(e)
        store.set('login_info', undefined);
        cookie.save("isLogin", "false", {path: "/"});
        message.success('已退出，请重新登录');
        window.setTimeout(() => {
            history.push('/login')
        }, 1000)
    }
    return (
        <ProLayout
            title={"EasyGameNow"}
            logo={dLogo}
            // logo={<img src="https://www.itemd2r.com/images/logo2.webp" alt={""}/>}
            // appList={[{icon:"", title:"A", desc:"", url:"", target: "_blank", children:undefined}]}
            // onMenuHeaderClick={onTopMixMenuHeaderClick}
            headerTitleRender={(logo, title, props)=>
                <div style={{width: 208, marginRight:8}}>{logo}{title}</div>}
            token={{
                header: {
                    // colorHeaderTitle: 'rgba(255,255,255,0.8)',
                    // colorTextMenu: 'rgba(255,255,255,0.8)',
                    // colorTextMenuActive: 'rgba(255,255,255,0.8)',
                    // colorTextMenuSelected: 'rgba(255,255,255,0.8)',
                    // colorTextRightActionsItem: 'rgba(255,255,255,0.8)',
                    // colorBgHeader: "rgba(0,0,0,0.75)",
                    // colorBgMenuItemHover: 'rgba(255,255,255,0.2)',
                    // colorBgMenuItemSelected: 'rgba(255,255,255,0.16)',

                    // colorHeaderTitle: 'rgba(0,0,0,0.8)',
                    // colorTextMenu: 'rgba(0,0,0,0.8)',
                    // colorTextMenuActive: 'rgba(0,0,0,0.8)',
                    // colorTextMenuSelected: 'rgba(0,0,0,0.8)',
                    // colorTextRightActionsItem: 'rgba(0,0,0,0.8)',
                    // colorBgHeader: "rgba(255,255,255,0.75)",
                    // colorBgMenuItemHover: 'rgba(0,0,0,0.2)',
                    // colorBgMenuItemSelected: 'rgba(0,0,0,0.08)',
                },
                pageContainer: {paddingBlockPageContainerContent: 24, paddingInlinePageContainerContent: 24},
                // sider
            }}
            avatarProps={{
                icon: <UserOutlined/>,
                // size: 'small',
                title: login_info?.loginName,
                render: (props, dom) => {
                    return (
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: 'password',
                                        icon: <UnlockOutlined/>,
                                        label: '修改密码 ',
                                        onClick: changePass
                                    },
                                    {
                                        key: 'logout',
                                        icon: <LogoutOutlined/>,
                                        label: '退出登录',
                                        onClick: logout
                                    },
                                ],
                            }}
                        >
                            {dom}
                        </Dropdown>
                    );
                },
            }}
            layout={"mix"}
            fixSiderbar={true}
            splitMenus={true}
            siderMenuType={"sub"}

            // suppressSiderWhenMenuEmpty={false}
            route={menus}
            // {...defaultProps}
            location={{pathname: pathName}}
            // menuItemRender={(item, dom) => (
            // <Link href={item.path}>{dom}</Link>
            // <div
            //     onClick={() => {
            //         setPathname(item.path || '/');
            //         console.log(item, dom)
            //         history.push(item.locale || "/")
            //
            //     }}
            // >
            //     {dom}
            // </div>
            // )}
            // menu={{}}
            collapsed={siderCollapse}
            onCollapse={(e)=>serSiderCollapse(e)}
            // defaultCollapsed={true}
        >
            {props.children}
        </ProLayout>
    )
}
export default MyProLayout