import { useEffect, useState} from "react";
import {Button, Layout, message, Modal, Select} from "antd";
import {
    EnterOutlined,
    ExportOutlined,
    ImportOutlined,
    UploadOutlined, InboxOutlined
} from "@ant-design/icons";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavFooter from "../../components/NavFooter";
import React from "react";
import AxiosUtil from "../../utils/AxiosUtil";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import EditableTable1 from "../../components/EditableTable1";
import {ParamProps} from "./model/param";
import Dragger from "antd/es/upload/Dragger";
import {RcFile, UploadProps} from "antd/es/upload/interface";

const {Content} = Layout;

const Dist = () => {

    const initParents = [{id: 1, name: "", parentId: 1, systemId: 1, level: -1, brother: []}]

    const [parents, setParents] = useState(initParents)
    const [mainData, setMainData] = useState<any[]>([]);

    const [systemData, setSystemData] = useState<ParamProps[]>([]);
    const [systemId, setSystemId] = useState("");
    const [showUpload, setShowUpload] = useState(false);

    useEffect(() => {
        loadParam();
    },[]);

    useEffect(()=>{
        loadMainData();
    },[parents, systemId])

    const loadParam = () => {
        AxiosUtil.AxiosRequest('/adminApi/user/getUserSystem', {},
            (suc, data) => {
                if (suc) {
                    setSystemData(data.list);
                    if (data.list.length >= 1) {
                        const sys = data.list[0].paramCode;
                        setParents([{id: sys, name: "",
                            parentId: sys, systemId: sys,
                            level: -1,
                            brother: []}])
                        setSystemId(sys);
                    }else{
                        setSystemId("0");
                    }
                }
            });
    }
    const loadMainData = () => {
        const query : any = {
            systemId: systemId,
            parentId: parents.length == 1 ? systemId : parents[parents.length - 1].id
        };
        AxiosUtil.AxiosRequest('/adminApi/dist/list', query,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list)
                }
            });
    }
    const changeSystem= (e:any) =>{
        // setParents(initParents);
        setSystemId(e);
    }

    const reloadChildren = (item: any) => {
        item.brother = mainData;
        setParents([...parents, item])
    }
    const reloadParent = () => {
        if (parents.length > 1) {
            const p = [...parents];
            p.pop();
            setParents(p)
        }
    }

    const saveRecord = (record: any) => {
        if (parents.length == 1){
            record.parentId = parents[parents.length - 1].id;
        }
        if (parents.length >= 1) {
            // record.parentId = parents[parents.length - 1].id;
            record.systemId = parents[parents.length - 1].systemId;
            record.level = parents[parents.length - 1].level + 1;
        }
        console.log('save:', record);
        AxiosUtil.AxiosRequest('/adminApi/dist/edit', [record],
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '保存成功！', className: 'message_success'});
                    loadMainData();
                }
            });
    }
    const deleteRecord = (record_key: string) => {
        AxiosUtil.AxiosRequest('/adminApi/dist/delete', [record_key],
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '记录已删除成功！', className: 'message_success'});
                    loadMainData();
                }
            });
    }
    const sortRecord = (records: any[]) => {
        AxiosUtil.AxiosRequest('/adminApi/dist/edit', records,
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '排序保存成功！', className: 'message_success'});
                }
            });
    }


    const submitExport = () => {
        AxiosUtil.AxiosRequest('/adminApi/dist/export', {systemId: systemId},
            (suc, data) => {
            if (suc)
                window.open(data);
            else
                Modal.error({content: data});
            });
    }

    const uploadProps: UploadProps = {
        name: 'file',
        accept: ".xlsx, .xls",
        multiple: false,
        beforeUpload: file => {
            const formData = new FormData();
            formData.append("file", file as RcFile);
            formData.append("systemId", systemId);

            AxiosUtil.AxiosRequest("/adminApi/dist/import", formData,
                (suc: boolean, data: any) => {
                    if (suc) {
                        setShowUpload(false);
                        loadMainData();

                        Modal.info({
                            title: '上传信息完成',
                            content: (<div>
                                新建{data.inserted}条，更新{data.updated}条<br/>
                                {data.deleted == null || data.deleted == 0 ? '' :
                                    <a href={data.msg}>点此下载“导入失败清单”（{data.deleted}条）</a>
                                }
                            </div>),
                            onOk() {}
                        });
                    } else {

                    }
                });
            return false;
        },
        maxCount: 1,
        fileList: [],
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 40,
            align: 'center' as 'center',
        },
        {
            title: '客户项目',
            dataIndex: 'systemName',
            width: 200,
        },
        {
            title: parents.length == 2 ? "区域" : parents.length == 3 ? "省份" : "",
            dataIndex: 'parentId',
            width: 200,
            editor: {
                type: 'select',
                data: parents[parents.length-1].brother,
                idField: "parentId",
                valueField: "id",
                textField: "name",
            },
            render: () => parents[parents.length - 1].name
        },
        {
            title: parents.length == 1 ? "区域" : parents.length == 2 ? "省份" : parents.length == 3 ? "城市" : "",
            dataIndex: 'name',
            width: 200,
            editor: {type: 'text', required: true},
            render: (v: number, r: any) =>
                parents.length >= 3 ? <>{v}</> : <a onClick={() => reloadChildren(r)}>{v}</a>
        },
        {
            title: '层级',
            dataIndex: 'level',
            width: 200,
            editable: true,
        },
        // {
        //     title: '下级',
        //     key: 'next',
        //     dataIndex: 'paramId',
        //     width: 88,
        //     align: 'center' as 'center',
        //     render: (id: number, item: any) => <a onClick={() => reloadChildren(item)}
        //                                           hidden={parents.length >= 3}>
        //         <ApartmentOutlined/></a>
        // },
    ];
    const exButtons = [
        <Button type='link' onClick={reloadParent} hidden={parents.length <= 1}
                key='reloadParent'><EnterOutlined rotate={90}/> 返回上级</Button>
,
        <span>客户系</span>,
        <Select options={systemData}
                fieldNames={{label: "paramName", value: "paramCode"}}
                onChange={changeSystem}
                style={{width: 200}}
                value={systemId}
        />,
        <Button type={"primary"} onClick={submitExport}>
            <ExportOutlined/> 导出
        </Button>
        ,
        <Button type={"primary"} onClick={() => setShowUpload(true)}>
            <ImportOutlined/> 导入
        </Button>
        ,
            <a href="/import/标准地区模版.xlsx">下载标准地区模版</a>
    ];

    const crumb = [
        {name: '系统管理'},
        {name: '区域设置'},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <EditableTable1
                                key={"t"}
                                columns={columns}
                                datas={mainData}
                                record_key="id"
                                saveCallback={saveRecord}
                                deleteCallback={deleteRecord}
                                reloadCallback={loadMainData}
                                sortCallback={sortRecord}
                                actionButtons={["add"]}
                                exButtons={exButtons}
                                // exButtonsRight={exButtonsRight}
                            />


                            <Modal title={<div><UploadOutlined/> 上传地区信息 </div>}
                                   key='storeList'
                                   open={showUpload}
                                   footer={null}
                                   width={600}
                                   onCancel={() => setShowUpload(false)}
                                   centered>
                                <Dragger  {...uploadProps}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                                    <p className="ant-upload-hint">支持Excel文件类型（.xlsx、xls）</p>
                                    <p className="red strong">
                                    </p>
                                </Dragger>
                                <p className="strong">★下载导入模版：
                                    <a href="/import/标准地区模版.xlsx">标准地区模版</a>
                                </p>
                            </Modal>

                        </div>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default Dist


