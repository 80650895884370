import {Component, useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import { Layout, message, Modal, Tree, Typography} from "antd";
import AxiosUtil from "../../utils/AxiosUtil";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import NavFooter from "../../components/NavFooter";
import React from "react";
import {SettingOutlined} from "@ant-design/icons";
import DataUtil from "../../utils/DataUtil";
import moment from "moment";
import {ParamProps} from "./model/param";
import EditableTable1 from "../../components/EditableTable1";
import store from "store";

const {Content} = Layout;

const AdminRole = () => {

    const [showPermission, setShowPermission] = useState(false);
    const [settingRoleId, setSettingRoleId] = useState(0);
    const [settingRoleName, setSettingRoleName] = useState("");
    const [settingRoleFunctions, setSettingRoleFunctions] = useState<any[]>([]);
    const [settingRoleFunctionsFull, setSettingRoleFunctionsFull] = useState<any[]>([]);
    const [mainData, setMainData] = useState<any[]>([]);
    // const [paramData, setParamData] = useState<ParamProps[]>([]);
    const [functionData, setFunctionData] = useState<any[]>([]);
    const [functionCheckedKeys, setFunctionCheckedKeys] = useState<any[]>([]);

    useEffect(() => {
        loadParam();
        loadFunction();
        loadMainData();
    }, [])

    const loadParam = () => {
        // AxiosUtil.AxiosRequest('/adminApi/param/list', {parentId: 1},
        //     (suc: boolean, data: any) => {
        //         if (suc) {
        //             setParamData(data.list);
        //         }
        //     });
    }
    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/adminApi/role/list', {},
            (suc: boolean, data: any) => {
                if (suc) {
                    setMainData(data.list);
                }
            });
    }
    const saveRecord = (record: any) => {
        console.log('save:', record);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '保存成功！', className: 'message_success'});
                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/role/edit', [record], callback);
    }
    const deleteRecord = (record_key: string) => {
        AxiosUtil.AxiosRequest('/adminApi/role/delete', [{roleId: record_key}],
            (suc: boolean, data: any) => {
                if (suc) {
                    console.log(suc, data)
                    message.success({content: '记录已删除成功！', className: 'message_success'});
                    loadMainData();
                }
            });
    }
    const sortRecord = (records: any[]) => {
        AxiosUtil.AxiosRequest('/adminApi/role/edit', records,
            (suc: boolean, data: any) => {
                if (suc) {
                    console.log(suc, data)
                    message.success({content: '排序保存成功！', className: 'message_success'});
                }
            });
    }

    const login_info = store.get('login_info');
    const systemId = login_info?.systemId;
    let systemPower: any = []

    // 读取所有功能菜单
    const loadFunction = () => {
        AxiosUtil.AxiosRequest('/adminApi/role/getRoleFunction', {id:systemId * -1},
            (suc: boolean, data: any) => {
                if (suc) {
                    systemPower = data;
                    console.log("systemPower",systemPower)
                    AxiosUtil.AxiosRequest('/adminApi/function/allTree', {},
                        (suc: boolean, data: any) => {
                            if (suc) {
                                setFunctionData(treeDataClean2(data));
                            }
                        });
                }
            });

    }
    const treeDataClean2 = (data: any) => {
        const cleanData2 = (data: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].children && data[i].children.length === 0) {
                    data[i].children = undefined;
                } else if (data[i].children && data[i].children.length > 0) {
                    data[i].children = cleanData2(data[i].children);
                }
                if (data[i].menuList == 2) {
                    data[i].title = "";
                    for (let i2=0 ; i2<systemPower.length; i2++) {
                        if (systemPower[i2].functionId == data[i].functionId) {
                            data[i].title = data[i].functionName  + " [定制功能]";
                        }
                    }
                }else{
                    data[i].title = data[i].functionName ;
                }

                data[i].key = data[i].functionId;

            }
            return data.filter((r:any)=> r.title!="");
        }
        return cleanData2(data);
    }

    const loadRoleFunction = (id: number, name: string) => {
        AxiosUtil.AxiosRequest('/adminApi/role/getRoleFunction', {id: id},
            (suc: boolean, data: any) => {
                if (suc) {
                    let keys: any[] = [];
                    data.forEach((item: any) => {
                        if (item.w && DataUtil.isEndpoint(item.functionId, functionData))
                            keys.push(item.functionId)
                    })
                    console.log(keys)
                    setFunctionCheckedKeys(keys);
                    setShowPermission(true);
                    setSettingRoleId(id);
                    setSettingRoleName(name);
                    setSettingRoleFunctions([]);

                    // this.loadFunction(id,name);
                }
            });
    }

    const savePermission = () => {
        if (!settingRoleFunctions) {
            message.error({content: '权限设置没有任何改变！', className: 'message_error'})
            return;
        }

        const powers = [];
        for (let i = 0; i < settingRoleFunctions.length; i++) {
            const fid = settingRoleFunctions[i];
            powers.push({
                roleId: settingRoleId,
                functionId: settingRoleFunctions[i],
                r: 1,
                w: settingRoleFunctionsFull.indexOf(fid) >= 0 ? 1 : 0
            });
        }
        if (powers.length === 0) {
            powers.push({
                roleId: settingRoleId,
                functionId: 0, r: 0, w: 0
            });
        }
        AxiosUtil.AxiosRequest('/adminApi/role/setRoleFunction', powers,
            (suc: boolean, data: any) => {
                if (suc) {
                    console.log(suc, data)
                    message.success({content: '权限设置成功！', className: 'message_success'});
                    //this.reload();
                }
            });
        setShowPermission(false);
    }

    const onFunctionCheck = (checkedKeys: any, e: any) => {
        console.log(checkedKeys,e)
        setSettingRoleFunctions([...checkedKeys, ...e.halfCheckedKeys]);
        setSettingRoleFunctionsFull([...checkedKeys]);
        setFunctionCheckedKeys([...checkedKeys])
    }

    const crumb = [
        {name: '系统管理'},
        {name: '角色权限'},
    ];
    const columns = [
        {
            title: 'ID',
            dataIndex: 'roleId',
            width: 40,
            align: 'center' as 'center',
        },
        {
            title: '角色',
            key: 'roleName',
            dataIndex: 'roleName',
            width: 200,
            editable: true,
            editor: {type: 'text', required: true}
        },
        // {
        //     title: '类型',
        //     key: 'paramName',
        //     dataIndex: 'paramName',
        //     width: 200,
        //     // render: (text: number, record: any) =>
        //     //     <span>{this.state.paramData.find((item: ParamProps) => item.paramId == text)!.paramName}</span>
        //     editor: {
        //         type: 'select',
        //         idField: 'roleType',
        //         valueField: 'paramId',
        //         textField: 'paramName',
        //         data: this.state.paramData,
        //             // [{paramId:2,paramName:"2"},{paramId:3,paramName:"3"},],
        //         required: true
        //     }
        // },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            width: 200,
            editable: true,
            editor: {type: 'text'}
        },
        {
            title: '权限',
            dataIndex: 'roleId',
            width: 88,
            align: 'center' as 'center',
            render: (v: number, record: any) =>
                <Typography.Link onClick={() => loadRoleFunction(v, record.roleName)}>
                    <SettingOutlined/>
                </Typography.Link>
        },
    ];

    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <EditableTable1
                                key={'mainTable'}
                                columns={columns}
                                datas={mainData}
                                record_key="roleId"
                                saveCallback={saveRecord}
                                deleteCallback={deleteRecord}
                                reloadCallback={loadMainData}
                                sortCallback={sortRecord}
                            />
                        </div>

                        <Modal title={<div><SettingOutlined/> 设置权限：
                            <span className="blue">{settingRoleName}</span></div>}
                               open={showPermission}
                               onOk={savePermission}
                               onCancel={() => {
                                   setShowPermission(false)
                               }}
                               forceRender={true}
                               centered>
                            <div id="functionTree">
                                {functionData.length > 0 &&
                                    <Tree key={'functionTree'}
                                          showLine={true}
                                          defaultExpandAll={true}
                                          checkable
                                          onCheck={onFunctionCheck}
                                          treeData={functionData}
                                          checkedKeys={functionCheckedKeys}
                                    />
                                }
                            </div>
                        </Modal>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>


    );
}

export default AdminRole;