import { Drawer, Layout, message, Space, Table} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import NavFooter from "../../components/NavFooter";
import React, {useEffect, useState} from "react";
import {CopyOutlined, ExpandOutlined} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {Query} from "../../model/query";
import AxiosUtil from "../../utils/AxiosUtil";
import moment from "moment";
import {AppServerCacheDo} from "./model/AppServerCacheDo";
import copyToClipboard from "copy-to-clipboard";
import RawDataViewer from "../../components/RawDataViewer";


const AppList =()=>{

    // const formRef = useRef();

    const [showForm2, setShowForm2] = useState(false);
    const [form2Text, setForm2Text] = useState<string | undefined>("");
    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState<AppServerCacheDo[]>();
    const [query, setQuery] = useState<Query>({
        pageSize: 20,
        pageNo: 1,
        orderBy: "",
    } as Query);


    useEffect(() => {
        loadMainData();
    }, [query]);

    useEffect(() => {
        // SimpleList.param(99, (d) => (setTypeData(d)));
    }, []);

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list)
                setTotal(dat.total)
            }
        }
        AxiosUtil.AxiosRequest('/appApi/cache/list', query, callback);
        console.log("loadMainData - ok")
    }
    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

        let querys = {...query};
        switch (extra.action) {
            case "paginate":
                querys.pageNo = querys.pageSize == pagination.pageSize ? pagination.current : 1;
                querys.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order == "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    querys.orderBy = "";
                }
                break;
            case "filter":
                break;
        }
        setQuery(querys)
    }

    const copyValue = (v: string) => {
        copyToClipboard(v);
        message.success('复制成功');
    }

    const openForm2 = (v: string | undefined) => {
        setForm2Text("Loading...");
        setShowForm2(true);
        if (v) {

            const callback = (suc: boolean, dat: any) => {
                if (suc) {
                    setForm2Text(dat.rawData);
                }
            }
            AxiosUtil.AxiosRequest('/appApi/cache/detail', {id: v}, callback);
        } else {
            setForm2Text("Load Error!");
        }
    }

    const closeForm2 = () => {
        setShowForm2(false);
    }

    const crumb = [
        {name: '微信平台'},
        {name: 'APP Cache Data'},
    ];

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     align: 'center' as 'center',
        //     width: 24,
        // },
        {
            title: 'AppId',
            dataIndex: 'appId',
            width: 60,
        },
        {
            title: 'AppName',
            dataIndex: 'appName',
            width: 60,
        },
        {
            title: '缓存名称',
            dataIndex: 'cacheName',
            width: 60,
        },
        // {
        //     title: 'OrigId',
        //     dataIndex: 'wxOrigId',
        //     width: 60,
        // },
        // {
        //     title: 'AppType',
        //     dataIndex: 'wxAppType',
        //     width: 60,
        // },
        {
            title: '缓存值',
            dataIndex: 'cacheValue',
            width: 80,
            render: (v: string) => (
                <span>
                    <span className='copy- value'>{v.substr(0,16)}... </span>
                    <a className='copy-icon' onClick={() => copyValue(v)}> <CopyOutlined /></a>
                </span>)
        },
        {
            title: '有效期',
            dataIndex: 'expiresTime',
            width: 120,
            align: 'center' as 'center',
            render: (v: number) => {
                // return <span>{new Date(v).getTime()} <br /> {new Date().getTime()}</span>
                return v === null ? ''
                    : new Date(v).getTime() >= new Date().getTime() ? moment(v).format('yyyy-MM-DD HH:mm:ss')
                        : <span style={{color:'#ff0000'}}>{moment(v).format('yyyy-MM-DD HH:mm:ss')}</span>
            },
        },
        {
            title: '更新时间',
            dataIndex: 'updateTime',
            width: 120,
            align: 'center' as 'center',
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            key:'rawData',
            title: '元数据',
            dataIndex: 'id',
            width: 60,
            align: 'center' as 'center',
            render: (v: string) => (<a onClick={() => openForm2(v)}><span><ExpandOutlined /> 查看</span></a>),
        },
    ];
    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Space size={16} style={{marginBottom: 16}}>
                            </Space>
                        </div>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               pagination={{
                                   // onChange: this.onPageChange,
                                   total: total,
                                   pageSize: query.pageSize,
                                   current: query.pageNo
                               }}
                        />

                        <Drawer placement="right"
                                width={600}
                                open={showForm2}
                                onClose={closeForm2}
                                title={<span><ExpandOutlined /> 元数据</span>}>
                            <RawDataViewer rawData={form2Text} label="元数据"/>
                        </Drawer>

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default AppList;