import {Affix, Layout, Menu} from "antd";
import React, {useEffect, useState} from "react";

// 创建类型接口
interface IProps {
    onClickMenu? : (e:any)=>void,
    items?: any[] ,
}

const SiderMenuSub = (props: IProps) => {

    const [items, setItems]=useState<any[]>();

    useEffect(()=>{
        console.log(props.items);
        setItems(props.items);
    },[props])

    return(<Affix offsetTop={8}>
        <Layout.Sider width={220}
                      style={{overflow: 'auto', height: '100vh'}}>
            <Menu mode={"inline"} onClick={props.onClickMenu} items={items}/>
            <div style={{height: 40}}></div>
        </Layout.Sider>
    </Affix>)
}

export default SiderMenuSub;