import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Button, Cascader, Col, DatePicker, Form, Image, Input, InputNumber, message, Row, Select, Switch, Upload} from "antd";
import {ListDo} from "../pages/admin/model/listDo";
import { PlusOutlined, UploadOutlined} from "@ant-design/icons";
import store from "store";
import {UploadFile, UploadProps} from "antd/es/upload/interface";
import {IDomEditor, IEditorConfig, IToolbarConfig} from "@wangeditor/editor";
import {Editor, Toolbar} from "@wangeditor/editor-for-react";


export interface EditorFieldProp {
    type?: "input" | "text" | "rich" | "hidden" | "readonly"| "select" | "cascader" | "bool"
        | "image" | "show" | "file" | "date" | "datetime" | "number",
    name: string,
    label?: string,
    list?: boolean,
    selectEnum?: any,
    width?:number,
    height?: number,
    data?: ListDo[],
    fieldNames?: any,
    required?: boolean,
    maxImages? : number,
    placeholder?:string,
    format?: "date" | "datetime"
}
interface IProps {
    inputFields: EditorFieldProp[];
    initData: any;
    submitCallback: (values:any) => void;
    changeCallback: (change:boolean, values:any) => void;
    defaultWidth?: number;
}

const EditorForm = (props: IProps, ref: any) => {

    const [formRef] = Form.useForm();
    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    const [fileList, setFileList] = useState<any>({});
    const [preview, setPreview] = useState(false);
    const [previewSrc, setPreviewSrc] = useState("");
    const [initImg, setInitImg] = useState<any>()

    useEffect(() => {
        props.inputFields.forEach((field) => {
            if (field.type === "image") {
                if (props.initData?.[field.name]) {
                    let url = props.initData?.[field.name] || '';
                    if (url != '' && url.substring(0,1) == '/') {
                        url = 'https://d2stock.com/' + url;
                    }
                    setFileList({
                        ...fileList,
                        [field.name]: [{
                            uid: props.initData?.[field.name] || '',
                            name: props.initData?.[field.name] || '',
                            status: 'done',
                            url: url,
                            thumbUrl: props.initData?.[field.name] || '',
                        }]
                    })
                } else {
                    setFileList({...fileList, [field.name]: []});
                }
            } else if (field.type === "show") {
                let url = props.initData?.[field.name] || '';
                if (url != '' && url.substring(0,1) == '/') {
                    url = 'https://d2stock.com' + url;
                }
                setInitImg(url)

            } else if (field.type === "rich") {
                setHtml(props.initData?.[field.name] || '');
                formRef.setFieldsValue({[field.name]: props.initData?.[field.name] || ''})

            } else if (field.type === "readonly") {
            }
        });
        formRef.resetFields();
        formRef.setFieldsValue(props.initData);
        console.log("props",props)
    }, [props])


    useImperativeHandle(ref, () => {
        return {
            submitForm: () => {
                formRef.submit()
            },
            getFormValue: () => {
                return formRef.getFieldsValue()
            }
        }
    });

    // const submitForm = () => {
    //     formRef.submit();
    // }

    const onImgChange = (newFileList: any, fieldName: string) => {
        console.log("onChange", newFileList);
        let value = "";
        // let files = [];
        for (let i in newFileList) {
            const r = newFileList[i];
            if (r.status === "done" && r.response) {
                if (r.response.code === 200) {
                    value += ", " + r.response.data;
                } else {
                    message.error(r.response.msg)
                }
            } else if (r.status === "done" && r.url) {
                value += ", " + r.url;
            }
        }
        formRef.setFieldsValue({[fieldName]: value.length > 1 ? value.substring(1) : ""});
        setFileList({...fileList, [fieldName]: newFileList});
    };

    const onImgPreview = async (file: UploadFile) => {
        setPreviewSrc(file.url || "");
        setPreview(true);
    };

    // region ### WangEditor配置 ###

    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)
    // 编辑器内容
    const [html, setHtml] = useState("")
    // 模拟 ajax 请求，异步设置 html
    // useEffect(() => {
    //     setTimeout(() => {
    //         setHtml("");
    //     }, 1500)
    // }, [])

    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        // toolbarKeys: ['headerSelect', 'blockquote',
        //     '|', 'bold', 'underline', 'italic', 'color', 'bgColor',
        //     '|', 'fontSize', 'fontFamily', 'lineHeight',
        //     '|', 'bulletedList', 'numberedList',
        //     '|', 'emotion', 'insertLink', 'insertTable', 'codeBlock', 'divider',
        //     '|', 'undo', 'redo', 'fullScreen']
        excludeKeys: ["emotion", "lineHeight", "group-video", "uploadImage"],
        // insertKeys: []
    }
    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入内容...',
    }
    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) {
                return;
            }
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    // console.log("getAllMenuKeys",editor?.getAllMenuKeys());
    // if (editor) {
    //     const toolbar = DomEditor.getToolbar(editor)
    //     const curToolbarConfig = toolbar?.getConfig();
    //     console.log( curToolbarConfig?.toolbarKeys ) // 当前菜单排序和分组
    // }
    // endregion

    const displayRender = (labels: string[], selectedOptions: any) => (
        labels.map((label, i) => {
            console.log("option"  ,labels, selectedOptions)
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return <span key={option?.value}>{label}</span>;
            }
            return <span key={option?.value}>{label} / </span>;
        })
    );

    let hidden = <></>;
    return (
        <Form id="editorForm"
              layout="horizontal"
              colon={false}
              onFinish={props.submitCallback}
              labelCol={{span: 4, offset: 0}}
              form={formRef}
              onValuesChange={(changedValues, values) => props.changeCallback(true, values)}
        >
            <Row>
                {props.inputFields.map((field, index) => {
                    let input;
                    let valuePropName;
                    switch (field.type) {
                        case "hidden":
                        case "input":
                            input = <Input placeholder={'请输入' + field.label}/>;
                            break;

                        case "readonly":
                            input = <Input readOnly={true} style={{border: "none"}}/>;
                            break;

                        case "text":
                            input = <Input.TextArea placeholder={'请输入' + field.label} style={{height: 80}}/>;
                            break;

                        case "number":
                            input = <InputNumber placeholder={'请输入' + field.label} style={{width: "100%"}}/>;
                            break;

                        case "date":
                            input = <DatePicker style={{width: "100%"}}/>;
                            break;
                        case "datetime":
                            input = <DatePicker showTime style={{width: "100%"}}/>;
                            break;

                        case "cascader":
                            // input = <Cascader options={field.data} fieldNames={field.fieldNames}/>;
                            input = <Cascader options={field.data}
                                              displayRender={displayRender}
                                              multiple={true}
                                              fieldNames={{label: "text", value: "id", children: "children"}}/>;
                            break;
                        case "select":
                            input = <Select options={field.data} fieldNames={field.fieldNames}/>;
                            break;

                        case "bool":
                            valuePropName = "checked";
                            input = <Switch/>;
                            break;

                        case "image":
                            hidden = <>{hidden}<Form.Item hidden={true} name={field.name}
                                                          label=''><Input/></Form.Item></>

                            input = <>
                                <Upload accept=".jpg,.jpeg,.png,.bmp,.webp,.gif"
                                        headers={{"accessToken": access_token}}
                                        action="/adminApi/files/upload"
                                        listType="picture-card"
                                        fileList={fileList[field.name]}
                                    // onChange={onImgChange1}
                                        onChange={({fileList: newFileList}) => onImgChange(newFileList, field.name)}
                                        onPreview={onImgPreview}
                                    // onRemove={onImgRemove}
                                >
                                    {(!fileList[field.name] || fileList[field.name].length < (field.maxImages || 1)) &&
                                        <div>
                                            <PlusOutlined/>
                                            <div style={{marginTop: 8}}>Upload</div>
                                        </div>
                                    }
                                </Upload>
                            </>
                            break;

                        case "show":
                            input = <div className='edit-image-show'><img src={initImg}/></div>
                            // input = <InputNumber placeholder={'请输入' + field.label} style={{width: "100%"}}/>;
                            break;

                        case "file":
                            hidden = <>{hidden}<Form.Item hidden={true} name={field.name}
                                                          label=''><Input/></Form.Item></>

                            const props: UploadProps = {
                                name: 'file',
                                accept: ".xls, .xlsx",
                                headers: {"accessToken": access_token},
                                action: "/mallApi/edm/uploadToList",
                                maxCount: 1,
                                onChange(info) {
                                    if (info.file.status !== 'uploading') {
                                        console.log(info.file, info.fileList);
                                    }
                                    if (info.file.status === 'done') {
                                        console.log("done", info)
                                        if (info.file.response.code === 200) {
                                            formRef.setFieldsValue({[field.name]: info.file.response.data});
                                            message.success(`${info.file.name} file uploaded successfully`);
                                        } else {
                                            formRef.setFieldsValue({[field.name]: ""});
                                            message.error(`${info.file.response.msg} file upload failed.`);
                                        }

                                    } else if (info.file.status === 'error') {
                                        formRef.setFieldsValue({[field.name]: ""});
                                        message.error(`${info.file.name} file upload failed.`);
                                    }
                                },
                            };

                            input = <Upload {...props}>
                                <Button icon={<UploadOutlined/>}>上传文件</Button>
                            </Upload>
                            break;

                        case "rich":
                            // hidden = <>{hidden}<Form.Item hidden={true} name={field.name} label=''><Input/></Form.Item></>
                            // field.name = "newName";
                            input = <div style={{border: '1px solid #CCC', zIndex: 100, fontSize: '12px'}}>

                                <Toolbar editor={editor}
                                         defaultConfig={toolbarConfig}
                                         mode="default"
                                         style={{borderBottom: '1px solid #ccc'}}
                                />
                                <Editor defaultConfig={editorConfig}
                                        value={html}
                                        onCreated={setEditor}
                                        onChange={editor => {
                                            console.log(editor.getHtml());
                                            formRef.setFieldsValue({[field.name]: editor.getHtml()})
                                        }}
                                        mode="default"
                                        style={{height: '300px', overflowY: 'hidden'}}
                                />
                                {/*<div>{html}</div>*/}
                            </div>

                            break;

                    }
                    return (
                        <Col key={index} span={field.type === "hidden" ? 0 : field.width || props.defaultWidth || 24}>
                            <Form.Item name={input == undefined ? undefined
                                : field.type === "rich" ? field.name + ""
                                    : field.type === "file" ? field.name + "1"
                                        : field.type === "show" ? field.name + "1"
                                            : field.name}
                                       rules={[{required: field.required}]}
                                       hidden={field.type === "hidden"}
                                       label={field.label}
                                       valuePropName={valuePropName}
                                       labelCol={{span: 4 * 24 / (field.width || props.defaultWidth || 24)}}>
                                {input}
                            </Form.Item>
                        </Col>
                    )
                })
                }
            </Row>
            <Image preview={{
                visible: preview,
                src: previewSrc,
                onVisibleChange: value => {
                    setPreview(value);
                },
            }}/>

            {hidden}
            {/*<Row>*/}
            {/*    <Col className="center" span={24}>*/}
            {/*        <Button type={"primary"} htmlType={"submit"}>*/}
            {/*            <SaveOutlined/> 保 存*/}
            {/*        </Button>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Form>
    )
}

export default forwardRef(EditorForm);