import {message, Modal} from "antd";
import {InboxOutlined, UploadOutlined} from "@ant-design/icons";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Dragger from "antd/es/upload/Dragger";
import store from "store";


const CashFlowUpload = (props: any, ref: any) => {

    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    useImperativeHandle(ref, () => {
        return {
            openForm: () => openUpload()
        }
    });

    useEffect(() => {
        //
    }, []);

    const [showUpload, setShowUpload] = useState(false);

    const openUpload = () => {
        setShowUpload(true);
    }
    const closeUpload = () => {
        setShowUpload(false);
    }
    // const onUploadChange= (a:any) => {
    //     console.log(a)
    // }
    const onUploadChange = (e:any) => {
        console.log("onChange", e);
        const r = e.file;
        if (r.status == "done" && r.response) {
            if (r.response.code == 200) {

                let res: any = r.response.data;
                if (res) {
                    message.success(<span>
                            上传成功：
                            新增 <span className={"strong"}>{res.inserted}</span> 条，
                            覆盖 <span className={"strong"}>{res.updated}</span> 条
                        </span>)
                }
                props.callback();
                closeUpload();

                return;
            } else {
                message.error(r.response.msg)
            }
        } else {
            //message.error(r.response.msg)
        }
    };

    return (

        <Modal title={<div><UploadOutlined/> 上传银行账单 </div>}
               key='up'
               open={showUpload}
               footer={null}
               width={520}
               onCancel={closeUpload}
               centered>
            <Dragger name='file'
                     accept=".xlsx,.xls"
                     multiple={false}
                     headers={{"accessToken": access_token}}
                     action="/crmApi/cashFlow/upload"
                     maxCount={1}
                     onChange={onUploadChange}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">支持Excel文件类型（.xls、.xlsx）</p>
                <p className="red strong"/>
            </Dragger>
        </Modal>
    )
}

export default forwardRef(CashFlowUpload);