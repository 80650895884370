import {SearchOutlined} from "@ant-design/icons";
import React from "react";
import {Button, Col, DatePicker, Form, Input, Row, Space} from "antd";
import type { FilterConfirmProps } from 'antd/es/table/interface';

// const {RangePicker} = DatePicker;
// const RangePicker: any = DatePicker.RangePicker;

interface FilterDropdownProps {
    setSelectedKeys: (selectedKeys: React.Key[]) => void;
    selectedKeys: React.Key[];
    confirm: (param?: any) => void;
    clearFilters?: () => void;
}

const ColumnFilterDateProp = (field: string, name: string, callback: (field: string, value: any) => void) => {

    const [queryRef] = Form.useForm();
    const clearSearch = (confirm: (param?: FilterConfirmProps) => void) => {
        queryRef.setFieldsValue({
            value: [null,null]
        })
        callback(field, { value: [null,null]});
        confirm();
    }
    const submitSearch = (confirm: (param?: FilterConfirmProps) => void) => {
        let v = queryRef.getFieldsValue();
        console.log(v);
        callback(field, v);
        confirm();
    }

    return ({
        filtered: queryRef.getFieldValue("value") !== "" && queryRef.getFieldValue("value") !== undefined,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
            <div style={{width: 280}}>
                <Form style={{padding: 16}} form={queryRef}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}><SearchOutlined/> {name}</Col>
                        <Col span={24}>
                            <Form.Item key="f1" name={"field"} initialValue={field} hidden={true}>
                                <Input/>
                            </Form.Item>
                            <Form.Item key="v1" name={"value"} initialValue={""}>
                                <DatePicker.RangePicker allowClear={true} allowEmpty={[true,true]} />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{textAlign: "right"}}>
                            <Space size={16}>
                                <Button type={"link"}
                                        onClick={()=>clearSearch(confirm)}>
                                    重置
                                </Button>
                                <Button type={"primary"}
                                        size={"small"}
                                        onClick={()=>submitSearch(confirm)}>
                                    搜索
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>)
    })
}

export default ColumnFilterDateProp;