import React, { useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {Button, Col, Drawer, Form, Input, Row, Upload, Image, Modal, message, Select} from "antd";
import {PrinterOutlined, SaveOutlined, PlusOutlined, LoadingOutlined, ExclamationCircleOutlined, ScanOutlined} from "@ant-design/icons";
import {InvoiceDo} from "../model/invoiceDo";
import {UploadFile, UploadProps} from "antd/es/upload/interface";
import 'antd/es/modal/style';
import * as store from "store";
import moment from "moment";
import JsonUtil from "../../../utils/JsonUtil";
import DataUtil from "../../../utils/DataUtil";
import {ContractDo} from "../model/contractDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import RawDataViewer from "../../../components/RawDataViewer";

const {confirm} = Modal

const InvoiceFrom = (props: any, ref: any) => {

    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    const [formRef] = Form.useForm();

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [invoiceInfo, setInvoiceInfo] = useState<InvoiceDo | undefined>(undefined);
    const [contractData, setContractData] = useState<ContractDo[]>([]);
    const [contractInfo, setContractInfo] = useState<ContractDo | undefined>(undefined);

    const [showForm, setShowForm] = useState(false);
    const [showForm2, setShowForm2] = useState(false);
    const [loadingImg, setLoadingImg] = useState(false);
    const [preview, setPreview] = useState(false);
    let changedValues = false;

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: InvoiceDo, c?: ContractDo) => openForm(r, c)
        }
    });

    useEffect(() => {
        loadContractData();
    }, []);

    const loadContractData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setContractData(dat.list);
            }
        }
        let query = {
            pageSize : 100,
            contractStatus: [79, 80, 81, 82, 83, 1262]
        };
        AxiosUtil.AxiosRequest('/crmApi/contract/list', query, callback);
    }

    const openForm = (r: InvoiceDo, c?: ContractDo) => {
        if (r) {
            r.invoiceDate = moment(r.invoiceDate).format("yyyy-MM-DD")
            setInvoiceInfo(r);
            formRef.setFieldsValue(r);
            setFileList([{
                uid: r.id?.toString(),
                name: r.imgUrl,
                status: 'done',
                url: r.imgUrl,
                thumbUrl: r.imgUrl.toLowerCase().indexOf(".pdf") > 0 ? "/images/pdf-preview.png": r.imgUrl
            }]);
            if (contractData.findIndex((value => value.id == r.contractId)) < 0) {
                let newItem = {id: r.contractId, contractTitle: r.contractTitle} as ContractDo;
                setContractData([...contractData, newItem])
            }
        } else {
            setInvoiceInfo(undefined);
            formRef.resetFields();
            setFileList([]);

            if (c){
                if (contractData.findIndex((value => value.id == c.id)) < 0) {
                    let newItem = {id: c.id, contractTitle: c.contractTitle} as ContractDo;
                    setContractData([...contractData, newItem])
                }
                formRef.setFieldsValue({contractId: c.id});
            }
        }
        setContractInfo(c);
        setShowForm(true);
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }
    const openForm2 = () => {
        setShowForm2(true);
    }
    const closeForm2 = () => {
        setShowForm2(false);
    }

    const onImgChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        console.log("onChange", newFileList);
        if (newFileList.length > 0) {
            const r = newFileList[0];
            if (r.status == "done" && r.response) {
                if (r.response.code == 200) {

                    let inv: InvoiceDo = r.response.data;
                    if (invoiceInfo) {
                        inv.id = invoiceInfo.id;
                        inv.remark = invoiceInfo.remark;
                        inv.contractId =  invoiceInfo.contractId;
                        inv.contractTitle =  invoiceInfo.contractTitle;
                    }
                    if (contractInfo){
                        inv.contractId = contractInfo.id
                        inv.contractTitle =  contractInfo.contractTitle
                    }
                    openForm(inv, contractInfo);
                    return;
                } else {
                    message.error(r.response.msg)
                }
            }
            setFileList(newFileList);
        } else {
            setFileList([]);
        }
    };

    const onImgPreview = async (file: UploadFile) => {
        if (file.url!.toLowerCase().indexOf(".pdf") > 0){
            window.open(file.url);
        } else {
            setPreview(true);
        }
    };

    const onFinishForm = () => {
        let values = formRef.getFieldsValue();
        console.log(values);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/invoice/edit', [values], callback);
    }
    const props1 = {
        width: 500,  //裁剪宽度
        height: 300, //裁剪高度
        resize: false, //裁剪是否可以调整大小
        resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
        modalTitle: "上传图片", //弹窗标题
        modalWidth: 600, //弹窗宽度
    };
    return (
        <Drawer placement="right"
                width={800}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12}>
                            <PrinterOutlined/> 上传发票
                        </Col>
                        <Col span={12}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64}}
                                    onClick={()=>{formRef.submit()}}
                            >
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>
            <Drawer placement="right"
                    width={800}
                    open={showForm2}
                    onClose={closeForm2}
                    title={<span><ScanOutlined/> OCR识别元数据</span>}>
                <RawDataViewer rawData={invoiceInfo?.imgOcr} label="OCR识别元数据"/>
            </Drawer>
            <Form id="invoiceForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 4, offset: 0}}
                  form={formRef}
                  style={{marginRight: 24}}
                  onValuesChange={() => {
                      changedValues = true
                  }}
            >
                <Form.Item hidden name={'id'} initialValue={0}><Input/></Form.Item>
                <Form.Item hidden name={'imgOcr'} initialValue={""}><Input/></Form.Item>
                <Form.Item hidden name={'imgUrl'} initialValue={""}><Input/></Form.Item>

                <Row>
                    <Col span={4}> </Col>
                    <Col span={10}>
                        {/*<ImgCrop {...props1}>*/}
                        <Upload
                            accept=".jpg,.jpeg,.png,.bmp,.pdf"
                            headers={{"accessToken": access_token}}
                            action="/crmApi/invoice/upload"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onImgChange}
                            onPreview={onImgPreview}
                            // onRemove={onImgRemove}
                        >
                            {fileList.length < 1 &&
                            <div>
                                {loadingImg ? <LoadingOutlined/> : <PlusOutlined/>}
                                <div style={{marginTop: 8}}>Upload</div>
                            </div>}
                        </Upload>
                        <Image preview={{
                            visible: preview,
                            src: invoiceInfo?.imgUrl,
                            onVisibleChange: value => {
                                setPreview(value);
                            },
                        }}/>
                    </Col>
                    <Col span={4}>
                        <Button type={"ghost"} onClick={openForm2}>
                            <ScanOutlined/> OCR元数据
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'contractId'} label={'关联合同'}>
                            {/*<Input placeholder={'关联合同'} autoComplete="off"/>*/}
                            <Select disabled={contractInfo!=undefined}>
                                {DataUtil.selectOptionsRender(contractData, 'id', 'contractTitle')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'invoiceType'} label={'发票类型'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入发票类型'}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'invoiceDate'} label={'开票日期'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入开票日期'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'totalMoney'} label={'开票金额'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入开票金额'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'invoiceMoney'} label={'未税金额'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入未税金额'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'taxMoney'} label={'税额'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入税额'}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'taxRate'} label={'税率'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入税率'}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'invoiceCode'} label={'发票代码'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入发票代码'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'invoiceNumber'} label={'发票号码'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入发票号码'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'buyerName'} label={'买方名称'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入买方名称'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'buyerTaxCode'} label={'买方税号'} labelCol={{span: 8}}>
                            <Input placeholder={'请输入买方税号'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'buyerAddress'} label={'买方地址'}>
                            <Input placeholder={'请输入买方地址'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'buyerBank'} label={'买方账号'}>
                            <Input placeholder={'请输入买方账号'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'detail'} label={'开票详情'}>
                            <Input placeholder={'请输入开票详情'} autoComplete="off"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'remark'} label={'备注信息'}>
                            <Input.TextArea placeholder={"请输入备注信息"} style={{height: 80}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default forwardRef(InvoiceFrom);