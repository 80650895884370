import React, { useEffect, useRef, useState} from "react";
import { Col, Input, Layout, Menu, message, Row, Select, Space, Spin,} from "antd";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavBreadcrumb from "../../../components/NavBreadcrumb";

import EditableTable1 from "../../../components/EditableTable1";
import {BaseInfoDo} from "../../admin/model/BaseInfoDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import moment from "moment";
import InfoEditor, {fieldProp} from "../../admin/components/InfoEditor";
import FetchUtil from "../../../utils/FetchUtil";
import {
    StarTwoTone,
} from "@ant-design/icons";
import SimpleList from "../../admin/components/SimpleList";
import LanguageUtil from "../../../utils/LanguageUtil";
import * as store from "store";

const {Content} = Layout;
const { Search } = Input;

const InfoIndex = (props: any) => {

    const formRef = useRef();
    const [mainData, setMainData] = useState<BaseInfoDo[]>([]);
    const [mainQuery, setMainQuery] = useState<any>();
    const [mainLoading, setMainLoading] = useState<any>(false);
    const [subMenu, setSubMenu] = useState<any[]>();
    const [currentMenu, setCurrentMenu] = useState<any>();
    const [listColumn, setListColumn] = useState<any>([]);
    const [inputFields, setInputFields] = useState<any[]>([]);

    const [cateData, setCateData] = useState<any[]>([]);
    const [typeData, setTypeData] = useState<any[]>([]);
    // const [langData, setLangData] = useState<any[]>();
    const [lang, setLang] = useState<string>();

    const langData = LanguageUtil.getLangData();
    let type = props.match.params.type || "";
    let pid = props.match.params.pid || 0;

    useEffect(() => {
        // AxiosUtil.AxiosRequest("/adminApi/language/list", {},
        //     (suc, data) => {
        //         setLangData(data.list.map((r: any) => {
        //             return {
        //                 value: r.langCode,
        //                 label: (<span><img src={r.icon} style={{height: 12}}/> {r.langName}</span>)
        //             }
        //         }));
        //         setLang(data.list[0].langCode || "en");
        //     })
        setLang(store.get("defaultLang"));

        type = props.match.params.type || "";
        pid = props.match.params.pid || 0;

        switch (type) {
            case "page" :
                loadMenuType();
                break;
            case "text":
                loadMenuType();
                break;
            case "email":
                pid = 1391;
                loadMenuType();
                break;
            case "game" :
                loadMenuGame();
                break;
            case "dev":
                loadMenuDev();
                break;
        }
    }, [props])

    useEffect(() => {
        loadMainData()
    }, [mainQuery])

    // const toggleCollapsed = () => {
    //     setCollapsed(!collapsed);
    // };

    const loadMenuType = async () => {
        const types = await FetchUtil.awaitFetch("/adminApi/param/list", {"parentId": pid});
        setTypeData(types.data.list);

        const menu = types.data.list.map((t: any) => {
            return {
                label: t.paramName,
                key: `${type}-0-${t.paramId}`,
                icon: <StarTwoTone/>
            }
        });
        setSubMenu(menu);
    }
    const loadMenuGame = async () => {
        const games = await FetchUtil.awaitFetch("/mallApi/category/list", {"parentId": 1});
        const types = await FetchUtil.awaitFetch("/adminApi/param/list", {"parentId": 1312});
        setTypeData(types.data.list);
        setCateData(games.data.list)

        const menu = games.data.list.map((g: any) => {
            return {
                label: g.categoryName,
                key: `game-${g.id}-0`,
                children: types.data.list.map((t: any) => {
                    return {
                        label: t.paramName,
                        key: `game-${g.id}-${t.paramId}`,
                    }
                }),
                icon: <img src={"/images/lang-en.jpg"} width={16} height={16}  alt={''}/>//<StarTwoTone twoToneColor={"#fe6752"}/>
            }
        });
        setSubMenu(menu);
    }

    /**
     * Load delivery input items for every games
     * Two level by tree list:
     *  [Game -> Platform]
     */
    const loadMenuDev = () => {
        SimpleList.categoryTree(1, (d) => {
            const menu = d.map((g: any) => {
                return {
                    label: g.text,
                    key: `dev-${g.id}-1376`,
                    children: g.children?.find((g1: any) => g1.value == "platform")
                        .children?.map((p: any) => {
                            return {
                                label: p.text,
                                key: `dev-${p.id}-1376`,
                            }
                        }),
                    icon: <StarTwoTone twoToneColor={"#fe6752"}/>
                }
            });
            setCateData(d);
            setSubMenu(menu);
        })
    }

    // const onOpenChange: MenuProps['onOpenChange'] = keys => {
    //     const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    //     if (rootKeys.indexOf(latestOpenKey!) === -1) {
    //         setOpenKeys(keys);
    //     } else {
    //         setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    //     }
    // };
    const onClickMenu = (e: any) => {

        const keys = e.key.split("-");
        const gameId = keys[1];
        const paramId = keys[2];

        let item = {
            gameId: gameId,
            paramId: type,
            gameName: cateData?.find((r) => r.id == gameId)?.categoryName,
            paramCode: typeData?.find((r) => r.paramId == paramId)?.paramCode
        }
        setCurrent(item);

        const query = {
            info: {
                infoType: paramId == 0 ? undefined : paramId,
                categoryId: gameId == 0 ? undefined : gameId,
            },
            page: {pageNo: 1, pageSize: 999}
        };
        setMainQuery(query);
    }

    const submitSearch = (values : any) => {
        console.log(values);
        setCurrent({"gameId": "0", "paramId": "text", "paramCode": "AI"});
        setMainQuery({
            info: {title: values, groupCode:"ai", langCode: lang},
            page: {
                pageSize: 100,
                pageNo: 1
            }
        });
    }

    const loadMainData = () => {
        if (!mainQuery) {
            return;
        }
        setMainLoading(true);
        AxiosUtil.AxiosRequest('/adminApi/info/list', mainQuery,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list);
                }
                setMainLoading(false);
            });
    }

    const openEdit = (r?: BaseInfoDo) => {
        console.log(r);
        if (!r) {
            r = {
                infoType: currentMenu.paramId,
                categoryId: currentMenu.gameId
            } as BaseInfoDo;
        }
        (formRef.current as any).openForm(r);
        // setShowEdit(true);
    }

    // const saveRecord = (record: any) => {
    //     console.log('save:', record);
    //     const callback = (suc: boolean, dat: any) => {
    //         if (suc) {
    //             console.log(suc, dat)
    //             message.success({content: '保存成功！', className: 'message_success'});
    //             loadMainData();
    //         }
    //     }
    //     AxiosUtil.AxiosRequest('/adminApi/info/edit', [record], callback);
    // }
    const deleteRecord = (id: string) => {
        AxiosUtil.AxiosRequest('/adminApi/info/delete', [id],
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '记录已删除成功！', className: 'message_success'});
                    loadMainData();
                }
            });
    }
    const sortRecord = (records: any[]) => {
        AxiosUtil.AxiosRequest('/adminApi/info/edit', records,
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '排序保存成功！', className: 'message_success'});
                }
            });
    }

    const setCurrent = (item: any) => {
        setCurrentMenu(item);

        let fields: fieldProp[] = [];
        if (type == "email") {
            fields = [
                {type: 'text', name: 'resume', label: '备注文字', list: true},
                {type: 'input', name: 'title', label: '邮件标题', list: true},
                {type: 'rich', name: 'contents', label: '详细内容'},
                {type: 'input', name: 'mappingCode', label: '映射', list: true},
            ];
        } else if (type == "dev") {
            fields = [
                {type: 'input', name: 'title', label: '标题', list: true},
                {
                    type: 'select', name: 'link', label: '字段类型', list: true,
                    data: [
                        {value: "input", text: "Input"},
                        {value: "email", text: "Email"},
                        {value: "radio", text: "Radio"},
                        {value: "select", text: "Select"},
                    ]
                },
                {type: 'input', name: 'mappingCode', label: '字段名', list: true},
                {type: 'input', name: 'author', label: '输入提示或选项'},
                {type: 'input', name: 'source', label: '错误提示'},
                {type: 'text', name: 'resume', label: '备注文字'},
                {type: 'image', name: 'image', label: '备注图片'},
            ];
        } else if (type == "text") {
            fields = [
                // {type: 'readonly', name: 'langCode', label: '语言', list: true},
                {type: 'readonly', name: 'groupName', label: '分组', list: true},
                {type: 'readonly', name: 'typeName', label: '分类', list: true},
                {type: 'readonly', name: 'resume', label: '说明', list: true},
                {type: 'text', name: 'title', label: '文案', list: true},
                {type: 'readonly', name: 'mappingCode', label: '映射', list: true},
                {type: 'imageUp', name: 'image', label: '图片上传'},
            ];
        } else if (item.paramCode == "slider") {
            fields = [
                {type: 'image', name: 'image', label: '主图', list: true},
                {type: 'input', name: 'title', label: '标题', list: true},
                {type: 'text', name: 'resume', label: '内容摘要', list: true},
                {type: 'text', name: 'link', label: '链接按钮'},
            ];
        } else if (item.paramCode == "image") {
            fields = [
                {type: 'image', name: 'image', label: '主图', list: true},
                {type: 'input', name: 'title', label: '标题', list: true},
                {type: 'input', name: 'link', label: '链接', list: true}
            ];
        } else if (item.paramCode == "data") {
            fields = [
                {type: 'input', name: 'title', label: '数据', list: true},
                {type: 'input', name: 'resume', label: '单位', list: true},
                {type: 'input', name: 'link', label: '说明', list: true}
            ];
        } else {
            fields = [
                {type: 'input', name: 'title', label: '标题', list: true},
                {type: 'input', name: 'resume', label: '副标题', list: true},
            ];
        }

        const columns: any[] = [
            {title: 'ID', dataIndex: 'id', width: 40, align: 'center' as 'center'},
        ]

        fields.forEach((field) => {
            // let value = fi
            if (field.list) {
                columns.push({
                    title: field.label,
                    dataIndex: field.name,
                    width: 100,
                    render: (v: string, r: BaseInfoDo, i: number) => (
                        <a onClick={() => openEdit(r)}>
                            {field.type == 'image' ?
                                <img src={v} alt='' style={{width: 128, height: 72}}/>
                                : v && v.length > 50 ? v.substring(0, 48) + "..." : v
                            }</a>)
                })
            }
        })
        columns.push({
            title: '修改时间',
            dataIndex: 'updateTime',
            width: 100,
            render: (v: string) => v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm'),
            align: 'center' as 'center'
        });
        setInputFields(fields);
        setListColumn(columns);
    }
    const crumb = [
        {name: '系统管理'},
        {name: '内容管理'},
        {name: currentMenu?.gameName},
        {name: currentMenu?.paramName},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain collapsed={true}/>

                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <Row gutter={24} wrap={false}>
                            <Col flex="240px">
                                <Menu mode={"inline"} onClick={onClickMenu} items={subMenu}/>
                            </Col>
                            <Col flex={"auto"}>
                                <div style={{marginBottom: 16}}>
                                    {/*<div style={{margin: 6, display:"inline-block"}}>搜索</div>*/}
                                    <Space.Compact>
                                        <Select value={lang} options={langData} onChange={(e)=>setLang(e)} />
                                        <Search  placeholder="搜索文案内容" onSearch={submitSearch} enterButton style={{ width: 500 }} />
                                    </Space.Compact>
                                </div>
                                <div id="mainTable" hidden={!currentMenu}>
                                    <Spin spinning={mainLoading}>
                                        <EditableTable1
                                            key={'categoryTable'}
                                            columns={listColumn}
                                            datas={mainData}
                                            record_key="id"
                                            addModelCallback={() => openEdit()}
                                            deleteCallback={deleteRecord}
                                            reloadCallback={loadMainData}
                                            sortCallback={sortRecord}
                                            actionButtons={type == "email" ? [] : ['addModal', 'reload']}
                                            // exButtons={exButtons}
                                        />
                                    </Spin>
                                </div>

                                <InfoEditor ref={formRef} inputFields={inputFields} callback={loadMainData}
                                            multiLanguage={true}/>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default InfoIndex;