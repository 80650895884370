import React, { useEffect, useState} from "react";
import {Affix, Button, Layout, Menu,} from 'antd';
import * as Icon from "@ant-design/icons";
import {AppstoreOutlined} from "@ant-design/icons";
import store from "store";
import {FunctionProps} from "../pages/admin/model/function";

// 创建类型接口
interface IProps {
    parentId? : number,
    selectedKeys?: string[] ,
    openKeys?: string[],
    collapsed?: boolean
}

const SiderMenuMain = (props: IProps) => {

    const login_power = store.get('login_power') || [];
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        setCollapsed(props.collapsed || false);
    }, [props]);

    let selectedKeys = props.selectedKeys;
    let openKeys = props.openKeys || [];

    const currentTopMenu = store.get("currentTopMenu");
    let menus: any = {};
    if (login_power.length === 1) {
        menus = login_power[0];

    } else if (props.parentId) {
        menus = login_power.find((el: any) => el.functionId === props.parentId)

    } else if (currentTopMenu && currentTopMenu !== "") {
        menus = login_power.find((el: any) => String(el.functionId) === currentTopMenu)

    }
    if (menus == undefined)
        menus = [];

    const renderMenus = (menus: FunctionProps[]) => {
        if (!menus || menus.length === 0) {
            return [];
        }
        return menus.filter((item: FunctionProps) => item.functionType === 2)
            .map((item: FunctionProps) => {
                let m: any = {
                    key: item.functionId,
                    // @ts-ignore
                    icon: React.createElement((item.icon in Icon) ? Icon[item.icon] : Icon['AppstoreOutlined']),
                }
                if (item && item.children && item.children.length > 0) {
                    m.children = renderMenus(item.children);
                    m.label = item.functionName;
                    openKeys.push(String(item.functionId));
                } else {
                    if (item.pageUrl.indexOf("https://") === 0 || item.pageUrl.indexOf("http://") === 0) {
                        m.label = <a href={item.pageUrl} target={"_blank"}>{item.functionName}</a>;
                    } else {
                        m.label = <a href={`/#/${item.pageUrl}`}>{item.functionName}</a>;
                    }
                }
                return m;
            })
    }
    const menuItems = renderMenus(menus.children);

    const onClickMenu = (e: any) => {
        store.set("currentSiderMenu", e.key);
    }

    const toggleCollapsed = () => {
        setCollapsed(collapsed ? !collapsed : true);
    };

    return (
        <Affix offsetTop={8}>
            <Layout.Sider width={220}
                          theme="light"
                          collapsed={collapsed}
                          style={{marginTop: -8, height: '100vh', overflow: "auto"}}>
                <Button type={'link'} onClick={toggleCollapsed} style={{margin: 0, marginLeft: 16}}>
                    {React.createElement(collapsed ? Icon['MenuUnfoldOutlined'] : Icon['MenuFoldOutlined'])}
                </Button>
                <Menu mode="inline"
                      defaultSelectedKeys={selectedKeys || store.get("currentSiderMenu")}
                      defaultOpenKeys={openKeys}
                      onClick={onClickMenu}
                      style={{height: '95%', position: "relative",}}
                      items={menuItems}>
                </Menu>
                <div style={{height: 40}}></div>

            </Layout.Sider>
        </Affix>
    );
}
export default SiderMenuMain;

