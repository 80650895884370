import React from "react";
import { Empty, Layout, } from "antd";
import NavHeader from "../components/NavHeader";
import NavFooter from "../components/NavFooter";

import './css/main.less'

const {Content} = Layout;


const Page404 = () => {

    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <Content className={'main-content'}>
                        <div id="empryDiv" style={{padding: 80}}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>

                        </div>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>


    );
}
export default Page404;