import React, { useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Row,
    Upload,
    Modal,
    message,
    Select,
    Popconfirm,
    Radio,
    RadioChangeEvent
} from "antd";
import {
    AppstoreAddOutlined,
    SaveOutlined,
    ExclamationCircleOutlined,
    UploadOutlined, FileTwoTone,DeleteOutlined
} from "@ant-design/icons";
import {UploadFile, UploadProps} from "antd/es/upload/interface";
import 'antd/es/modal/style';
import * as store from "store";
import DataUtil from "../../../utils/DataUtil";
import {ContractDo} from "../model/contractDo";
import AxiosUtil from "../../../utils/AxiosUtil";
import SimpleList from "../../admin/components/SimpleList";
import {ListDo} from "../../admin/model/listDo";
import {FileDo} from "../model/fileDo";

const {confirm} = Modal

const InvoiceFrom = (props: any, ref: any) => {

    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    const [formRef] = Form.useForm();

    const [fileInfo, setFileInfo] = useState<FileDo | undefined>(undefined);
    // const [contractData, setContractData] = useState<ContractDo[]>([]);
    const [contractInfo, setContractInfo] = useState<ContractDo | undefined>(undefined);
    const [typeData, setTypeData] = useState<ListDo[]>(props.typeData);

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [showForm, setShowForm] = useState(false);
    let changedValues = false;

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: FileDo, c?: ContractDo) => openForm(r, c)
        }
    });

    useEffect(() => {
        SimpleList.param(85, (d) => (setTypeData(d)));
    }, []);

    const openForm = (r: FileDo, c?: ContractDo) => {
        if (r) {
            setFileInfo(r);
            formRef.setFieldsValue(r);
        } else {
            setFileInfo(undefined);
            formRef.resetFields();
        }
        setContractInfo(c);
        setShowForm(true);
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    const onUploadChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        console.log("onChange", newFileList);
        if (newFileList.length > 0) {
            const r = newFileList[0];
            if (r.status == "done" && r.response) {
                if (r.response.code == 200) {

                    let file: string = r.response.data;
                    if (file) {
                        console.log(file);
                        let newFile = {} as FileDo;
                        if (fileInfo) {
                            newFile = fileInfo;
                        }
                        newFile.fileUrl = file;
                        setFileInfo(newFile);
                        formRef.setFieldsValue({fileUrl: file, fileName: r.name});
                    }
                } else {
                    message.error(r.response.msg)
                }
            }
            setFileList(newFileList);
            setTimeout(() => {
                setFileList([])
            }, 2000)
        }
    };
    const onFinishForm = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        let values = formRef.getFieldsValue();
        values.filePaper = fileInfo?.filePaper;
        console.log(values);
        AxiosUtil.AxiosRequest('/crmApi/files/edit', [values], callback);
    }
    const confirmDelete = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/files/delete', [{id: fileInfo?.id}], callback);
    }
    const onChecked = (e:RadioChangeEvent) => {
        let ii = {...fileInfo} as FileDo;
        ii.filePaper = e.target.value;
        setFileInfo(ii);
    }
    return (
        <Drawer placement="right"
                width={600}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12}>
                            <AppstoreAddOutlined/> 相关附件
                        </Col>
                        <Col span={12}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64}}
                                    onClick={() => {
                                        formRef.submit()
                                    }}
                            >
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>

            <Form id="cashForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 4, offset: 0}}
                  form={formRef}
                  style={{marginRight: 24}}
                  onValuesChange={() => {
                      changedValues = true
                  }}
            >
                <Form.Item hidden name={'id'} initialValue={0}><Input/></Form.Item>
                <Form.Item hidden name={'relType'} initialValue={props.relType}><Input/></Form.Item>
                <Form.Item hidden name={'relId'} initialValue={props.relId}><Input/></Form.Item>
                <Form.Item hidden name={'fileUrl'} initialValue={""} rules={[{required:true}]}><Input/></Form.Item>

                <Row>
                    <Col span={24}>
                        <Form.Item name={''} label={'上传文件'}>
                            <Upload action="/crmApi/files/upload"
                                    headers={{"accessToken": access_token}}
                                    onChange={onUploadChange}
                                    multiple={false}
                                    maxCount={1}
                                    fileList={fileList}>
                                <Button icon={<UploadOutlined/>}>Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                    </Col>
                    <Col span={20}>
                        <a href={fileInfo?.fileUrl} target="_blank" hidden={!fileInfo || !fileInfo.fileUrl}>
                            <FileTwoTone twoToneColor="#096dd9"/>{fileInfo?.fileUrl}
                        </a>
                        <br/>&nbsp;
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'filePaper'} label={'纸质存档'}>
                            <Radio.Group onChange={onChecked}
                                options={[
                                { label: '已存档', value: 1 },
                                { label: '暂缺', value: 0 },
                                { label: '无需存档', value: 2},
                            ]} />


                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item name={'fileName'} label="附件名称" rules={[{required:true}]}>
                            <Input placeholder={''}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'fileType'} label={'附件类型'} rules={[{required:true}]}>
                            <Select>
                                {DataUtil.selectOptionsRender(typeData, 'value', 'text')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'remark'} label={'备注'}>
                            <Input placeholder={''}/>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{textAlign:"right", marginTop:120}}>
                        <Form.Item name={''} label=" ">
                            <Popconfirm
                                title="您确定要删除这个文档吗？"
                                onConfirm={confirmDelete}
                                // onCancel={cancel}
                                okText="确认删除"
                                cancelText="再想想"
                            >
                                <Button type="primary" danger><DeleteOutlined/> 删除附件 </Button>
                            </Popconfirm>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default forwardRef(InvoiceFrom);