import React from "react";
import {Breadcrumb} from 'antd';
import {HomeOutlined} from '@ant-design/icons';


interface IProps {
    itemList?: any,
}

const NavBreadcrumb = ({itemList}: IProps) => {
    return (
        <Breadcrumb style={{margin: '0 0 16px 0'}}>
            <Breadcrumb.Item><HomeOutlined/></Breadcrumb.Item>
            {
                itemList.map((item: any, idx: number) => {
                    return <Breadcrumb.Item key={idx}>
                        {item.name}
                    </Breadcrumb.Item>;
                })
            }
        </Breadcrumb>
    );
}

export default NavBreadcrumb