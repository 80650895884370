import AxiosUtil from "../../../utils/AxiosUtil";
import {Button, Image, Layout, Space, Table} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {InvoiceDo} from "../model/invoiceDo";
import moment from "moment";
import {PictureOutlined, UploadOutlined} from "@ant-design/icons";
import DataUtil from "../../../utils/DataUtil";
import {ContractDo} from "../model/contractDo";
import InvoiceForm from "./InvoiceForm";


const ContractFormInvoice = (props: any, ref: any) => {

    const formRef = useRef();
    const [contractInfo, setContractInfo] = useState<ContractDo | undefined>(undefined);
    const [mainData, setMainData] = useState<InvoiceDo[]>([]);
    const [preview, setPreview] = useState(false);
    const [previewImg, setPreviewImg] = useState("");

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ContractDo) => openForm(r)
        }
    });

    useEffect(() => {
        loadMainData();
    }, [contractInfo])

    const openForm = (r: ContractDo) => {
        if (r) {
            setContractInfo(r);

        } else {
            setContractInfo(undefined);
        }
    }

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list);
            }
        }
        if (contractInfo && contractInfo.id) {
            AxiosUtil.AxiosRequest('/crmApi/invoice/list', {contractId: contractInfo.id}, callback);
            console.log("loadMainData - ok")
        } else {
            setMainData([]);
        }
    }

    const formatMoney = (v: number, v2?: number) => {
        return (
            v ? <span className={v < 0 ? "red" : ""}>
                    {DataUtil.formatNumber(v, 2)}
                    {v2 ? <sup>({v2})</sup> : ""}
            </span>
                : ""
        )
    }

    const openEdit = (r?: InvoiceDo) => {
        console.log(r);
        (formRef.current as any).openForm(r, contractInfo);
    }

    const onImgPreview = async (img: string) => {
        setPreview(true);
        setPreviewImg(img);
    };

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 48,
        },
        {
            title: "开票日期", dataIndex: "invoiceDate", align: "center" as "center", width: 100,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD')
            },
        },
        {
            title: "类型", dataIndex: "invoiceType", align: "center" as "center", width: 80,
        },
        {
            title: "金额", dataIndex: "totalMoney", align: "right" as "right", width: 88,
            render: (v: number, r: InvoiceDo) => (
                <a onClick={() => openEdit(r)}>{formatMoney(v)}</a>
            ),
        },
        {
            title: "税额", dataIndex: "taxMoney", align: "right" as "right", width: 80,
            render: (v: number) => (formatMoney(v)),
        },
        {
            title: "税率", dataIndex: "taxRate", align: "right" as "right", width: 80,
        },
        // {
        //     title: "发票代码", dataIndex: "invoiceCode", align: "center" as "center", width: 100
        // },
        {
            title: "发票号码", dataIndex: "invoiceNumber", align: "center" as "center", width: 100,
            render: (v: string, r: InvoiceDo) => <Space split={'-'}>{r.invoiceCode}{r.invoiceNumber}</Space>
        },
        {
            title: "存档", dataIndex: "imgUrl", align: "center" as "center", width: 48,
            render: (v: string) => (
                v.substring(v.length - 4).toLowerCase() === '.pdf'?
                    <a href={v} target='_blank'><PictureOutlined /></a>
                    :<a onClick={()=>onImgPreview(v)}><PictureOutlined /></a>
            )
        },
    ];

    return (
        <Layout>
            <Space size={16} style={{margin: 8}}>
                <Button type="primary"
                        key='showEdit'
                        size={"small"}
                        onClick={() => openEdit()}
                >
                    <UploadOutlined/> 上传发票
                </Button>
            </Space>
            <Table key='contract'
                   rowKey='id'
                   columns={columns}
                   dataSource={mainData}
                   size="small"
                   bordered
                   pagination={false}
            />

            <InvoiceForm ref={formRef} callback={loadMainData}/>

            <Image preview={{
                visible: preview,
                src: previewImg,
                onVisibleChange: value => {
                    setPreview(value);
                },
            }}/>
        </Layout>
    );
}

export default forwardRef(ContractFormInvoice);