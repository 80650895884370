
import React from "react";
import { createFromIconfontCN } from '@ant-design/icons';

export default class IconUtil {

    static platformIcon = '//at.alicdn.com/t/c/font_3715864_i3qb5kubqi.js';
    static iconSize = {fontSize: 16};

    static docIcon = (str?: string, size?: number) => {

        if (str == null) {
            return "";
        }
        const mySize = size === undefined ? this.iconSize : {fontSize: size};
        const MyIcon = createFromIconfontCN({scriptUrl: this.platformIcon});

        let v = str.toLowerCase();
        return (
            v === "pdf" ? <MyIcon type="icon-doc-pdf" style={mySize}/>
                : ".doc,.docx".includes(v) ? <MyIcon type="icon-doc-word" style={mySize}/>
                : ".xls,.xlsx".includes(v) ? <MyIcon type="icon-doc-excel" style={mySize}/>
                    : ".ppt,.pptx".includes(v) ? <MyIcon type="icon-doc-ppt" style={mySize}/>
                        : ".jpg,.jpeg,.png,.bmp,.gif,".includes(v) ? <MyIcon type="icon-doc-img" style={mySize}/>
                            : <MyIcon type="icon-file-other" style={mySize}/>
        )
    }

    static appIcon = (str?: string, size?: number) => {

        if (str == null) {
            return "";
        }
        const mySize = size === undefined ? this.iconSize : {fontSize: size};
        const MyIcon = createFromIconfontCN({scriptUrl: this.platformIcon});

        let v = str.toLowerCase();
        return (
            v === 'wx' ? <MyIcon type="icon-app-wx" style={mySize}/>
                : v === 'work' ? <MyIcon type="icon-app-work" style={mySize}/>
                : v === 'alipay' ? <MyIcon type="icon-app-alipay" style={mySize}/>
                    : v === 'tt' ? <MyIcon type="icon-app-douyin" style={mySize}/>
                        : ''
        )
    }
}