import MyProLayout from "../../../components/MyProLayout";
import React, {useRef, useState} from "react";
import {ActionType, DragSortTable, EditableProTable, PageContainer, ProTable} from "@ant-design/pro-components";
import {Button, Divider, message, Popconfirm, Space, Table} from "antd";
import FetchUtil from "../../../utils/FetchUtil";
import {CategoryDo} from "../../mall/model/CategoryDo";
import {
    DeleteOutlined,
    EnterOutlined,
    FormOutlined,
    GlobalOutlined,
    PlusCircleOutlined,
    SisternodeOutlined
} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {ListDo} from "../../admin/model/listDo";
import {ItemDo} from "../../mall/model/ItemDo";
import AxiosUtil from "../../../utils/AxiosUtil";

const Category = () => {

    const mlRef = useRef();
    const actionRef = useRef<ActionType>();
    let {parentId} = useParams<{ parentId: string }>();
    parentId = parentId === undefined ? '0' : parentId;

    const [parents, setParents] = useState<ListDo[]>(
        [{value: parentId, text: ''}])

    const openMl = (r: any) => {
        (mlRef.current as any).openEditor({type: "category", id: r.id});
    }

    const columns = [
        {
            dataIndex: '_id',
            width: 40,
            align: 'center' as 'center',
            editable:  () => false
        },
        {
            title: 'ID',
            dataIndex: 'id',
            width: 40,
            align: 'center' as 'center',
            editable:  () => false
        },
        {
            title: '所属层级',
            dataIndex: 'parentName',
            // width: 200,
            // editable: () => parents[parents.length - 1].text
        },
        {
            title: 'Code',
            dataIndex: 'categoryCode',
            // width: 200,
            // editor: {type: 'text', required: true}
        },
        {
            title: '分类名',
            dataIndex: 'categoryName',
            // width: 200,
            // editor: {type: 'text', required: true},
            render: (v: any, r: any) =>
                <a onClick={() => loadChildren(r)}>{v}</a>
        },
        {
            title: 'Icon',
            dataIndex: 'image',
            // width: 200,
            // editor: {type: 'text', required: false}
        },
        // {
        //     title: '价格系数',
        //     key: 'priceCoefficient',
        //     dataIndex: 'priceCoefficient',
        //     width: 200,
        //     editor: {type: 'text', required: true}
        // },
        {
            title: '映射',
            dataIndex: 'mappingCode',
            // width: 200,
            // editor: {type: 'text', required: false}
        },
        {
            title: "多语言",
            dataIndex: "_ml",
            align: 'center' as 'center',
            width: 88,
            render: (v : any, r : any) =>
                <a onClick={(e:any)=>openMl(r)} hidden={r.id==0}>
                    <GlobalOutlined/> 设置
                </a>,
            editable:  () => false
        },
        {
            title: '操作',
            dataIndex: '_opt',
            width: 120,
            align: 'center' as 'center',

            render: (v: any, r: any, _:any, action:any) =>
                <Space size="small" split={<Divider type="vertical"/>}>
                    <a onClick={() => {action?.startEditable?.(r.id);}}>
                        <FormOutlined/> 编辑
                    </a>
                    {/*<Popconfirm disabled={false}*/}
                    {/*            title="确定要删除这条记录吗"*/}
                    {/*            okText="删除"*/}
                    {/*            cancelText*/}
                    {/*            onConfirm={() => deleteRecord(r)}*/}
                    {/*>*/}
                    {/*    <a><DeleteOutlined/></a>*/}
                    {/*</Popconfirm>*/}
                </Space>,
            editable:  () => false
        },
    ];

    const loadChildren = (item: CategoryDo) => {
        const p = parents;
        p.push({'value': item.id, 'text': item.categoryName});
        setParents(p);
        actionRef.current?.reload();
        // loadMainData();
    }
    const loadParent = () => {
        if (parents.length > 1) {
            const p = parents;
            p.pop();
            setParents(p);
            actionRef.current?.reload();
            // loadMainData();
        }
    }

    const deleteRecord = (record_key: string) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '记录已删除成功！', className: 'message_success'});
                // loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/mallApi/category/delete', [record_key], callback);
    }
    
    const onDragSortEnd = (beforeIndex: number, afterIndex: number, newDataSource: any[]) => {
        console.log(beforeIndex, afterIndex, newDataSource)
    }

    // const DraggableContainer = (props: any) => (
    //     <SortContainer
    //         useDragHandle
    //         disableAutoscroll
    //         helperClass="row-dragging"
    //         onSortEnd={onSortEnd}
    //         {...props}
    //     />
    // );
    //
    // const DraggableBodyRow = (props: any) => {
    //     const { className, style, ...restProps } = props;
    //     const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
    //     return <SortableItem index={index} {...restProps} />;
    // }

    return (
        <MyProLayout>
            <PageContainer title={false}>
                <DragSortTable
                    rowKey={"id"}
                    dragSortKey={"_id"}
                    onDragSortEnd={onDragSortEnd}
                    toolbar={{
                        // search: {
                        //     onSearch: (value: string) => {
                        //         alert(value);
                        //     },
                        // },
                        actions: [
                            <Button type='link' onClick={loadParent}
                                    key='loadParent'><EnterOutlined rotate={90}/> 返回上级</Button>,
                            <Button type='primary' onClick={loadParent}
                                    key='loadParent'><PlusCircleOutlined/> 新建</Button>
                        ],
                    }}

                    actionRef={actionRef}
                    columns={columns}
                    cardBordered={true}
                    params={parents}
                    search={false}
                    request={async (
                        params,
                        sort,
                        filter,
                    ) => {
                        let query = {parentId: parents[parents.length - 1].value};
                        const res = await FetchUtil.awaitFetch("/mallApi/category/list", query);
                        return {
                            success: true,
                            data: res.data.list,
                        };
                    }}
                    // recordCreatorProps={{record: () => ({id: 0}),}}
                />
            </PageContainer>
        </MyProLayout>
    )
}

export default Category