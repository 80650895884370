import React, { useState} from "react";
import {Modal, Layout, Menu, message, Form, Input, Spin} from 'antd';
import {UserOutlined, ExportOutlined, UnlockOutlined} from '@ant-design/icons';
import store from "store";
import AxiosUtil from "../utils/AxiosUtil";
import { useHistory } from 'react-router-dom'
import * as Icon from "@ant-design/icons";
import {FunctionProps} from "../pages/admin/model/function";
import cookie from "react-cookies";

// 创建类型接口
interface IProps {
    selected?: string[]
}

interface IResPwdParams {
    origPwd: string,
    newPwd: string,
    rptPwd: string,
}

const NavHeader = ({selected}: IProps) => {

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [formPwd] = Form.useForm();

    let login_info: any;

    // region ### 检查登录状态
    login_info = store.get('login_info');
    console.log("login_info -> ", login_info);

    const cookieLogin = cookie.load("isLogin");
    if (cookieLogin !== "true") {
        store.set("loginInfo", undefined);
        // window.location.replace('#/login');
        history.push('/login')
        return <></>;
    }
    if (!login_info || !login_info.accessToken || !login_info.userId) {
        // window.location.replace('#/login');
        history.push('/login')
        return <></>;
    }
    // endregion 检查登录状态

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 7},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };

    const handleCancel = () => {
        setOpenForm(false)
        formPwd.resetFields()
    }
    const onFinish = (dat: any) => {
        setLoading(true)
        let queryParams: IResPwdParams = {
            origPwd: dat.orig_pwd,
            newPwd: dat.new_pwd,
            rptPwd: dat.rpt_pwd,
        }
        AxiosUtil.AxiosRequest('/authApi/login/changePwd', queryParams,
            (suc: boolean) => {
                setLoading(false)
                if (suc) {
                    store.set('login_info', undefined);
                    message.success('密码已重置, 请重新登录');
                    history.push('/login')
                }
            })
    }

    const login_power = store.get('login_power') || [];
    const globalSetting = store.get('globalSetting') || {} as any;

    const menuItems = login_power.filter((item: FunctionProps) => item.functionType === 2)
        .map((item: FunctionProps) => {
            let m :any = {
                key: item.functionId,
                // @ts-ignore
                icon: React.createElement((item.icon in Icon) ? Icon[item.icon] : Icon['AppstoreOutlined']),
            }
            if (item.pageUrl.indexOf("https://") === 0 || item.pageUrl.indexOf("http://") === 0) {
                m.label = <a href={item.pageUrl} target={"_blank"} rel={"opener"}>{item.functionName}</a>;
            }else {
                m.label = <a href={`/#/${item.pageUrl}`}>{item.functionName}</a>;
            }
            return m;
        });
    const onClickMenu = (e: any) => {
        console.log("onClickMenu",e.key)
        if (e.key.indexOf("https://") === 0 || e.key.indexOf("http://") === 0) {
            window.open(e.key);
        } else {
            store.set("currentTopMenu", e.key);
            // history.push("/" + e.key);
        }
        // return false;
    }
    //     {
    //     login_power.filter((item: FunctionProps) => item.functionType == 2).map((item: FunctionProps) => {
    //         return (
    //             <Menu.Item key={item.pageUrl}
    //                 // @ts-ignore
    //                        icon={React.createElement(isValidKey(item.icon, Icon) ? Icon[item.icon] : Icon['AppstoreOutlined'])}>
    //                 {item.pageUrl.indexOf("http://") === 0 || item.pageUrl.indexOf("https://") === 0
    //                     ? <a href={item.pageUrl} target={"_blank"} rel="noreferrer">{item.functionName}</a>
    //                     : <a href={`/#/${item.pageUrl}`}
    //                          onClick={() => navTopMenu(item)}>{item.functionName}</a>
    //                 }
    //             </Menu.Item>
    //         )
    //     })
    // }
    // if (!selected) {
    //     const hashString = window.location.hash.split('?')[0].split('/');
    //     // login_power.filter((el: any) => {
    //     //     return el.pageUrl === hashString[0]
    //     // })
    //     const selectedIndex = login_power.findIndex((item: any) => item.pageUrl.split('/')[1] === hashString[1])
    //     if (selectedIndex >= 0) {
    //         selected = [login_power[selectedIndex].pageUrl];
    //         // console.log( hashString,selected);
    //     }
    // }

    const rightItems = [{
        key: login_info?.loginName,
        label: login_info?.loginName,
        icon: <UserOutlined/>,
        children: [
            {key: "setPassword", label: "修改密码", icon: <UnlockOutlined/>,},
            {key: "userLogout", label: "退出登录", icon: <ExportOutlined/>,}
        ]
    }];
    const onClickMenuRight = (e: any) => {
        if (e.key === "setPassword") {
            setOpenForm(true)
        } else if (e.key === "userLogout") {
            store.set('login_info', undefined);
            cookie.save("isLogin", "false", {path: "/"});
            message.success('已退出，请重新登录');
            window.setTimeout(() => {
                history.push('/login')
            }, 1000)
        }
    }


    return (
        <Layout.Header id={"main-header"} style={{background: globalSetting.background}}>
            <div className="logo" style={{backgroundImage: `url(${globalSetting.logo})`}}></div>
            <Menu className={'main-header-menu flex-blank'}
                  mode="horizontal"
                  defaultSelectedKeys={selected || store.get("currentTopMenu")}
                  onClick={onClickMenu}
                  items={menuItems}>
            </Menu>
            <Menu className={'main-header-menu'} mode="horizontal" items={rightItems} onClick={onClickMenuRight}>

                {/*<SubMenu key='login' icon={<UserOutlined/>} title={<>*/}
                {/*    {login_info?.loginName} {login_info?.systemName && `(${login_info?.systemName})`}*/}
                {/*</>}*/}
                {/*         className={'main-header-menu'}>*/}

                {/*    <Menu.Item key='setPassword'>*/}
                {/*        <Button type='link' onClick={setPassword}><UnlockOutlined/> 修改密码</Button>*/}
                {/*    </Menu.Item>*/}
                {/*    <Menu.Item key='userLogout'>*/}
                {/*        <Button type='link' onClick={userLogout}><ExportOutlined/> 退出登录</Button>*/}
                {/*    </Menu.Item>*/}

                {/*</SubMenu>*/}
            </Menu>
            <Modal title="修改密码"
                   open={openForm}
                   onOk={() => formPwd.submit()}
                   onCancel={handleCancel}>
                <Spin tip="Loading..."
                      size="large"
                      spinning={loading}>
                    <Form
                        {...formItemLayout}
                        form={formPwd}
                        name="resetPwd"
                        onFinish={onFinish}
                        validateTrigger={['onBlur',]}
                    >
                        <Form.Item name="orig_pwd" label="请输入原密码"
                                   rules={[{required: true}]}>
                            <Input.Password placeholder="请输入原密码"/>
                        </Form.Item>

                        <Form.Item
                            name="new_pwd"
                            label="请输入新密码"
                            rules={[
                                {required: true, message: '请输入新密码'},
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('orig_pwd') !== value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('新密码不能和原密码一样!'));
                                    },
                                }),
                            ]}>
                            <Input.Password placeholder="请输入新密码"/>
                        </Form.Item>

                        <Form.Item
                            name="rpt_pwd"
                            label="请确认新密码"
                            dependencies={['new_pwd']}
                            hasFeedback
                            rules={[
                                {required: true, message: '请确认密码'},
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('new_pwd') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('两次输入的密码不匹配!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="请确认新密码"/>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </Layout.Header>
    );
}

export default NavHeader