import {Button, message} from "antd";
import {memo} from "react";
import moment from "moment";

const Ntest = () => {

    const dingyue = () => {
        Notification.requestPermission();

    }

    if (Notification.permission == "granted") {
        alert("123")
        setInterval(function () {
            message.success("ok")
            new Notification("通知标题：" + moment(new Date()).format("yyyy-MM-dd HH:mm:ss"))

        },10000)
    }

    return <>
    <Button onClick={dingyue}>订阅 </Button>
    </>
}
export default Ntest