import { Divider, Layout, message, Space, Table} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import NavFooter from "../../components/NavFooter";
import React, {useEffect, useRef, useState} from "react";
import {Content} from "antd/es/layout/layout";
import {Query} from "../../model/query";
import AxiosUtil from "../../utils/AxiosUtil";
import moment from "moment";
import AppForm from "./components/AppForm";
import {AppConfigDo} from "./model/AppConfigDo";
import {QrcodeOutlined, MobileOutlined} from "@ant-design/icons";
import copyToClipboard from "copy-to-clipboard";
import IconUtil from "../../utils/IconUtil";


const AppList =()=>{

    const formRef = useRef();

    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState();
    const [query, setQuery] = useState<Query>({
        pageSize: 20,
        pageNo: 1,
        orderBy: "",
    } as Query);

    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/appApi/config/list', query,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list)
                    setTotal(dat.total)
                }
            });
        console.log("loadMainData - ok")
    }

    useEffect(() => {
        loadMainData();
    }, [query]);

    useEffect(() => {
        // SimpleList.param(99, (d) => (setTypeData(d)));
    }, []);

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

        let querys = {...query};
        switch (extra.action) {
            case "paginate":
                querys.pageNo = querys.pageSize === pagination.pageSize ? pagination.current : 1;
                querys.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order === "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    querys.orderBy = "";
                }
                break;
            case "filter":
                break;
        }
        setQuery(querys)
    }

    const openEdit = (r?: AppConfigDo) => {
        console.log(r);
        (formRef.current as any).openForm(r);
    }
    const openAuthCode = (r: AppConfigDo, type: string) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                if (type === 'pc') {
                    window.open(dat.pcAuthLink, '_blank');
                }else if (type === 'h5'){
                    copyToClipboard(dat.h5AuthLink);
                    message.success('手机H5授权链接已复制');
                }
            }
        }
        AxiosUtil.AxiosRequest('/appApi/wx/service/auth_code', {appId:r.appId}, callback);
    }

    const crumb = [
        {name: '微信平台'},
        {name: 'APP ID'},
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 16,
        },
        {
            title: 'Source',
            dataIndex: 'appSource',
            align: 'center' as 'center',
            width: 16,
            render: (v: string) => (IconUtil.appIcon(v)),
        },
        {
            title: 'AppId',
            dataIndex: 'appId',
            width: 100,
            render: (v: string, r: AppConfigDo) => (<a onClick={() => openEdit(r)}>{v}</a>),
        },
        {
            title: 'OrigId',
            dataIndex: 'origId',
            width: 100,
            render: (v: string, r: AppConfigDo) => (<a onClick={() => openEdit(r)}>{v}</a>),
        },
        {
            title: 'APP Name',
            dataIndex: 'appName',
            width: 120,
            render: (v: string, r: AppConfigDo) => (<a onClick={() => openEdit(r)}>{v}</a>),
        },
        {
            title: '类型',
            dataIndex: 'appType',
            width: 100,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: 100,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD')
            },
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            width: 100,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            title: '授权链接',
            dataIndex: 'appType',
            key: 'appType_1',
            width: 80,
            align: 'center' as 'center',
            render: (v: string, r: AppConfigDo) => {
                return v === '第三方平台' ?
                    <Space split={<Divider type="vertical" />}>
                        <a onClick={() => openAuthCode(r,'pc')}><QrcodeOutlined /></a>
                        <a onClick={() => openAuthCode(r,'h5')}><MobileOutlined /></a>
                    </Space>
                    : ''
            },
        },
    ];
    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Space size={16} style={{marginBottom: 16}}>
                            </Space>
                        </div>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               pagination={{
                                   // onChange: this.onPageChange,
                                   total: total,
                                   pageSize: query.pageSize,
                                   current: query.pageNo
                               }}
                        />

                        <AppForm ref={formRef} callback={loadMainData} />


                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default AppList;