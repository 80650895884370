import {Col, Layout, Result, Row} from "antd";
import NavFooter from "../../components/NavFooter";
import React from "react";
import {Content} from "antd/es/layout/layout";


const AppList =()=>{

    return (

        <div className={'layout-main'}>
            <Row>
                <Col span={24}>
                    <Layout.Header id={'main-header'}>
                        <div className={'logo'}/>
                        <div className={'flex-blank'}/>
                    </Layout.Header>
                </Col>
            </Row>

            <Content className={"main-body"} style={{padding: 100}}>
                <Result
                    status="success"
                    title="微信第三方平台授权成功！"
                    subTitle="授权已成功，感谢您的信任。现在可以开始您的业务……"
                    extra={[
                    ]}
                />
            </Content>
            <NavFooter/>
        </div>
    );
}

export default AppList;