import { useEffect, useRef, useState} from "react";
import {Button,Dropdown, Col, Divider, Form, Input, Layout, message, Popconfirm, Row, Select, Space, Table, Tag,} from "antd";
import {FormOutlined, DeleteOutlined, PlusCircleOutlined, RedoOutlined, SearchOutlined
} from "@ant-design/icons";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavFooter from "../../../components/NavFooter";
import React from "react";
import AxiosUtil from "../../../utils/AxiosUtil";
import NavBreadcrumb from "../../../components/NavBreadcrumb";
import {ListDo} from "../../admin/model/listDo";
import {ItemDo} from "../model/ItemDo";
import ItemEditor from "../components/ItemEditor";
import SimpleList from "../../admin/components/SimpleList";
import moment from "moment";
import {EditorFieldProp} from "../../../components/EditorForm";
import SiderMenuSub from "../../../components/SiderMenuSub";

const {Content} = Layout;
const onSellList = [
    { label: "上架", value: "1"},
    { label: "下架", value: "0"}
]

const  Item = (props: any) => {

    const formRef = useRef();
    const [queryForm] = Form.useForm();
    const defaultSort = "IFNULL(sort_index, 999999), item_name";

    let class1 = props.match.params.class1;
    const search = new URLSearchParams(props.location.search);
    const id = search.get('id');

    const [total, setTotal] = useState(0);
    const [className, setClassName] = useState('');

    const [category, setCategory] = useState<any[]>();
    const [skuProps, setSkuProps] = useState<ListDo[]>();
    const [prop1Data, setProp1Data] = useState<ListDo[]>();
    const [prop2Data, setProp2Data] = useState<ListDo[]>();

    const [mainData, setMainData] = useState<ItemDo[]>([]);
    const [mainLoading, setMainLoading] = useState(false);
    const [mainQuery, setMainQuery] = useState<any>();

    useEffect(() => {
        queryForm.resetFields();
        class1 = props.match.params.class1;
        setMainQuery({
            item: {onSell: ""},
            category: {classId1 : class1},
            page: {pageSize: 20, pageNo: 1,orderBy: defaultSort}
        });

        const getItemTree = (item:any) => {
            if (item && item.children && item.children.length > 0) {
                return item.children.map((cate: any) => {
                    return {
                        id: cate.id,
                        key: cate.id,
                        value: cate.id,
                        text: cate.categoryName,
                        label: cate.categoryName,
                        children: getItemTree(cate),
                    }
                })
            }
        }
        
        if (class1 && class1 != 0) {
            SimpleList.categoryTree(class1, (data) => {
                setSkuProps(data.find((r: any) => r.categoryCode == "skuprops")?.children);
                const products = data.find((r: any) => r.categoryCode == "products");
                setCategory(getItemTree(products));
            });
        }
    }, [props]);

    useEffect(() => {
        loadMainData();
    }, [mainQuery]);

    const loadMainData = () => {
        if (mainQuery) {
            setMainLoading(true);
            // mainQuery.classId1 = class1;
            AxiosUtil.AxiosRequest('/mallApi/item/list', mainQuery,
                (suc: boolean, data: any) => {
                    if (suc) {
                        setMainData(data.list);
                        setTotal(data.total);
                        if (data.list.length > 0) {
                            setClassName(data.list[0].className1);
                        }
                    }
                    setMainLoading(false)
                });
        }
    }

    const onQuery = () => {
        let values = queryForm.getFieldsValue();
        setMainQuery({
            item: values,
            category: {classId1: class1},
            page: {pageSize: mainQuery.page.pageSize, pageNo: 1, orderBy: defaultSort}
        });
    }

    const onTableChange = (pagination: any) => {
        setMainQuery({...mainQuery,
            page: {
                pageSize: pagination.pageSize,
                pageNo: (mainQuery.page.pageSize == pagination.pageSize) ? pagination.current : 1
            }
        });
    }

    const openEdit = (r?: ItemDo) => {
        console.log(r);
        if (!r) {
            r = {
                classId1: class1,
            };
            if (mainData.length > 0) {
                r.classCode1 = mainData[0].classCode1;
                r.className1 = mainData[0].className1;
            }
        }
        r.classId1 = class1;
        (formRef.current as any).openForm(r);
        // setShowEdit(true);
    }

    const deleteRecord = (record: ItemDo) => {
        AxiosUtil.AxiosRequest('/mallApi/item/delete', [record.itemId],
            (suc: boolean, dat: any) => {
                if (suc) {
                    console.log(suc, dat)
                    message.success({content: '记录已删除成功！', className: 'message_success'});
                    loadMainData();
                }
            });
    }

    const onClickMenu = (e: any) => {
        console.log(e)
        let query: any = {classId1: class1};
        switch (e.keyPath.length) {
            case 1:
                query.classId2 = e.keyPath[0];
                break;
            case 2:
                query.classId2 = e.keyPath[1];
                query.categoryId1 = e.keyPath[0];
                break;
            case 3:
                query.classId2 = e.keyPath[2];
                query.categoryId1 = e.keyPath[1];
                query.categoryId2 = e.keyPath[0];
                break;
        }
        setMainQuery({
            item: {onSell: ""},
            page: {pageSize: 20, pageNo: 1,orderBy: defaultSort},
            category: query});
    }

    const onClickMenuMore = (e: any) => {
        console.log(e)
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'itemId',
            width: 40,
            align: 'center' as 'center',
        },
        {
            title: '名称',
            dataIndex: 'itemName',
            width: 100,
            render: (v: string, r: ItemDo) => <a onClick={() => openEdit(r)}>{v}</a>
        },
        {
            title: '原始名',
            dataIndex: 'itemNameOrig',
            width: 100,
            render: (v: string, r: ItemDo) => <a onClick={() => openEdit(r)}>{v}</a>
        },
        // {
        //     title: '分类',
        //     dataIndex: 'categoryName1',
        //     width: 100,
        //     render: (v: string, r: ItemDo) => {
        //         return (<> {v}
        //             {r.categoryId1 === r.categoryId2 ? '' : ` / ${r.categoryName2}`}
        //         </>)
        //     }
        // },
        {
            title: '来源',
            dataIndex: 'source',
            width: 60,
            align: "center" as "center",
        },
        {
            title: class1==3?'皇冠数':'原始价格',
            dataIndex: 'price',
            width: 60,
            align: "center" as "center",
            render: (v: number, r: any) => class1==3?
                <>{r.pricePoint}
                    {r.priceDiscount != null && r.priceDiscount != 0 && <>({r.priceOrig} / {r.priceDiscount*100}%)</>}
                </>
                : r.priceOrig,
        },
        {
            title: '到期时间',
            dataIndex: 'expiryDate',
            width: 60,
            align: "center" as "center",
            render: (v: number) => v!=null && v!=0
                ? moment(v < 10000000000 ? v*1000: v).format('yyyy-MM-DD HH:mm') : "",
        },
        // {
        //     title: '映射',
        //     dataIndex: 'mappingCode',
        //     width: 60,
        //     align: "center" as "center",
        // },
        {
            title: 'SKU数',
            dataIndex: 'skuCount',
            width: 60,
            align: "center" as "center",
        },
        {
            title: 'SKU价格',
            dataIndex: 'skuCount',
            width: 60,
            align: "center" as "center",
            render: (v: string, r: ItemDo) => {
                return (<>{r.skuPriceMin === r.skuPriceMax ? r.skuPriceMin : `${r.skuPriceMin} ~ ${r.skuPriceMax}`}</>)
            },
        },
        {
            title: '状态',
            dataIndex: 'onSell',
            width: 60,
            align: "center" as "center",
            render: (v: number) => v == 1 ? <Tag color="green">上架</Tag> : <Tag color="red">下架</Tag>
        },
        // {
        //     title: '排序',
        //     dataIndex: 'sortIndex',
        //     width: 60,
        //     align: "center" as "center",
        // },
        {
            title: '更新',
            dataIndex: 'updateTime',
            width: 60,
            align: "center" as "center",
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            title: '操作',
            dataIndex: '_',
            width: 60,
            align: "center" as "center",
            render: (v: string, r: ItemDo) => {
                return (<Space size="small" split={<Divider type="vertical"/>}>
                    <a onClick={() => openEdit(r)}><FormOutlined/></a>
                    <Popconfirm disabled={false}
                                title="确定要删除这条记录吗"
                                okText="删除"
                                cancelText
                                onConfirm={() => deleteRecord(r)}
                    >
                        <a><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>)
            },
        },
    ];

    const inputFields: EditorFieldProp[] = [
        {type: 'cascader', name: 'categorise', label: '商品分类', list: true, data: category, required: true},
        {type: 'input', name: 'itemName', label: '商品名称', list: true, required: true},
        {type: 'input', name: 'itemNameOrig', label: '商品名称-orig', list: true},
        // {type: 'input', name: 'itemNameExt', label: '商品名称-zh', list: true},
        {type: 'image', name: 'image', label: '主图', list: true, width: 12},
        {type: 'show', name: 'imageOrig', label: '原图', list: true, width: 12},
        {type: 'bool', name: 'onSell', label: '上架状态', list: true, width: 12},
        {type: 'number', name: 'sortIndex', label: '排序值', list: true, width: 12},
        {type: 'readonly', name: 'expiryDate', label: '到期时间', list: true, width: 12},
        {type: 'readonly', name: 'pricePoint', label: class1==3?'皇冠数':'原始价格', list: true, width: 12},

        {type: 'readonly', name: 'updateTime', label: '更新日期', list: true, width: 12, format: 'datetime'},
        {type: 'readonly', name: 'mappingCode', label: '数据映射', list: true, width: 12, format: 'datetime'},
        {type: 'text', name: 'itemDesc', label: '商品描述', list: true},
        // {type: 'input', name: 'link', label: '商品商城URL地址', list: true},
    ];
    const crumb = [
        {name: '线上商城'},
        {name: '商品管理'},
        {name: mainQuery?.className1},
    ];

    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain collapsed={true}/>
                    <Content className={'   '}>
                        <NavBreadcrumb itemList={crumb}/>
                        <Layout>
                            <SiderMenuSub onClickMenu={onClickMenu} items={category}></SiderMenuSub>
                            <Content className={'main-content'}>
                                <div id="mainTable">

                                    <Form form={queryForm}
                                          onFinish={onQuery}
                                          layout="horizontal"
                                          className={"queryForm"}
                                          labelCol={{span: 8, offset: 0}}
                                          colon={false}>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item name="itemName" label="物品名称" labelCol={{span: 4, offset: 0}}>
                                                    <Input allowClear={true} />
                                                </Form.Item>
                                            </Col>
                                            {/*<Col span={6}>*/}
                                            {/*    <Form.Item name="categories" label="分类" labelCol={{span: 6}}>*/}
                                            {/*        <Cascader options={category}*/}
                                            {/*                  placeholder="选择物品分类"*/}
                                            {/*                  allowClear={true}*/}
                                            {/*                  changeOnSelect*/}
                                            {/*                  fieldNames={{*/}
                                            {/*                      label: "text",*/}
                                            {/*                      value: "id",*/}
                                            {/*                      children: "children"*/}
                                            {/*                  }}*/}
                                            {/*        />*/}
                                            {/*    </Form.Item>*/}
                                            {/*</Col>*/}
                                            <Col span={4}>
                                                <Form.Item name="source" label="来源">
                                                    <Input placeholder="输入物品名称" allowClear={true}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item name="onSell" label="状态" initialValue={""}>
                                                    <Select options={onSellList} placeholder="选择上架状态"
                                                            allowClear={true}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Button type={"primary"}
                                                        htmlType={"submit"}><SearchOutlined/> 查询</Button>
                                            </Col>
                                            <Col span={6}>
                                                <Space size={24} className={'right'}>
                                                    <Dropdown.Button menu={{ items:[
                                                            {key: "1", label: "批量下架"},
                                                            {key: "2", label: "批量上架"},
                                                            {key: "3", label: "设置库存"},
                                                            ],
                                                        onClick: onClickMenuMore }}>
                                                        批量操作
                                                    </Dropdown.Button>
                                                    <Button key="K1"
                                                            type="primary"
                                                            onClick={() => openEdit()}>
                                                        <PlusCircleOutlined/> 新建
                                                    </Button>
                                                    {/*<Button key="K2"*/}
                                                    {/*        type="primary"*/}
                                                    {/*        onClick={loadMainData}>*/}
                                                    {/*    <RedoOutlined/> 刷新*/}
                                                    {/*</Button>*/}
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Form>

                                    <Table key={'r'}
                                           loading={mainLoading}
                                           columns={columns}
                                           dataSource={mainData}
                                           rowKey="itemId"
                                           bordered
                                           size="small"
                                           indentSize={24}
                                           showSorterTooltip={false}
                                           onChange={onTableChange}
                                           rowSelection={{
                                               type: "checkbox",
                                               onChange: (e)=>console.log(e),
                                           }}
                                           pagination={{
                                               total: total,
                                               pageSize: mainQuery?.page?.pageSize,
                                               current: mainQuery?.page?.pageNo,
                                               showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                                               showQuickJumper: true
                                           }}
                                    />

                                    <ItemEditor ref={formRef}
                                                inputFields={inputFields}
                                                category={category}
                                                prop1Data={prop1Data}
                                                prop2Data={prop2Data}
                                                callback={loadMainData}
                                                multiLanguage={true}
                                    />

                                </div>
                            </Content>
                        </Layout>

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}


export default Item;
