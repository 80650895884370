import {useEffect, useRef, useState} from "react";
import {Button, Layout, message, Modal} from "antd";
import {
    SisternodeOutlined,
    EnterOutlined,
    SettingOutlined, SaveOutlined, GlobalOutlined
} from "@ant-design/icons";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavFooter from "../../../components/NavFooter";
import React from "react";
import AxiosUtil from "../../../utils/AxiosUtil";
import NavBreadcrumb from "../../../components/NavBreadcrumb";
import {CategoryDo} from "../model/CategoryDo";
import {ListDo} from "../../admin/model/listDo";
import EditableTable1 from "../../../components/EditableTable1";
import {useParams} from "react-router-dom";
import PropertiesTable from "../components/PropertiesTable";
import MulitLanguageEditor from "../../../components/MulitLanguageEditor";

const {Content} = Layout;

const  Category = (props: any) => {

    const mlRef = useRef();
    const propsRef = useRef();
    let {parentId} = useParams<{ parentId: string }>();
    parentId = parentId === undefined ? '0' : parentId;

    const [parents, setParents] = useState<ListDo[]>([{value: parentId, text: ''}])
    const [mainData, setMainData] = useState<CategoryDo[]>([]);
    const [showEdit, setShowEdit] = useState(false);
    const [properties, setProperties] = useState<any[]>([]);

    useEffect(() => {
        loadPropertiesData();
        loadMainData();
    }, []);

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.list);
            }
        }
        const query = {parentId: parents[parents.length - 1].value};
        AxiosUtil.AxiosRequest('/mallApi/category/list', query, callback);
    }
    const loadPropertiesData = () => {
        let prop1: CategoryDo[], prop2: CategoryDo[];
        const callback1 = (suc: boolean, dat: any) => {
            if (suc) {
                prop1 = dat.list;
                callbackFinished();
            }
        }
        const callback2 = (suc: boolean, dat: any) => {
            if (suc) {
                prop2 = dat.list;
                callbackFinished();
            }
        }
        const callbackFinished = () => {
            if (prop1 && prop2 ) {
                setProperties([
                    {'id': 11, 'code': 'Platform', 'name': 'Platform', 'data': prop1},
                    {'id': 12, 'code': 'Server', 'name': 'Server', 'data': prop2},
                    // {'pid': 12, 'code': 'Server2', 'name': 'Server2', 'data': prop2}
                ]);
            }
        }
        AxiosUtil.AxiosRequest('/mallApi/category/list', {parentId: 11}, callback1);
        AxiosUtil.AxiosRequest('/mallApi/category/list', {parentId: 12}, callback2);
    }

    const loadChildren = (item: CategoryDo) => {
        const p = parents;
        p.push({'value': item.id, 'text': item.categoryName});
        setParents(p);
        loadMainData();
    }
    const loadParent = () => {
        if (parents.length > 1) {
            const p = parents;
            p.pop();
            setParents(p);
            loadMainData();
        }
    }

    const saveRecord = (record: any) => {
        if (parents.length > 1) {
            record.parentId = parents[parents.length - 1].value;
        }
        console.log('save:', record);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '保存成功！', className: 'message_success'});
                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/mallApi/category/edit', [record], callback);
    }
    const deleteRecord = (record_key: string) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '记录已删除成功！', className: 'message_success'});
                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/mallApi/category/delete', [record_key], callback);
    }
    const sortRecord = (records: any[]) => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                console.log(suc, dat)
                message.success({content: '排序保存成功！', className: 'message_success'});
            }
        }
        AxiosUtil.AxiosRequest('/mallApi/category/edit', records, callback);
    }
    const openEdit = (show: boolean) => {
        setShowEdit(show);
    }
    const saveProperties = () => {
        (propsRef.current as any).getFormValues();
    }

    const openMl = (r : any) => {
        (mlRef.current as any).openEditor({type: "category", id: r.id});
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 40,
            align: 'center' as 'center',
        },
        {
            title: '所属层级',
            key: 'parentName',
            dataIndex: 'parentName',
            width: 200,
            render: () => parents[parents.length - 1].text
        },
        {
            title: 'Code',
            key: 'categoryCode',
            dataIndex: 'categoryCode',
            width: 200,
            editor: {type: 'text', required: true}
        },
        {
            title: '分类名',
            key: 'categoryName',
            dataIndex: 'categoryName',
            width: 200,
            editor: {type: 'text', required: true},
            render: (v: string, item: CategoryDo) =>
                <a onClick={() => loadChildren(item)}>{v}</a>
        },
        {
            title: 'Icon',
            dataIndex: 'image',
            width: 200,
            editor: {type: 'text', required: false}
        },
        {
            title: '价格系数',
            key: 'priceCoefficient',
            dataIndex: 'priceCoefficient',
            width: 200,
            editor: {type: 'text', required: true}
        },
        {
            title: '映射',
            key: 'mappingCode',
            dataIndex: 'mappingCode',
            width: 200,
            editor: {type: 'text', required: false}
        },
        // {
        //     title: '供应商系数',
        //     key: 'mappingCode',
        //     dataIndex: 'mappingCode',
        //     width: 200,
        //     editable: true,
        //     render: (id: number, item: CategoryDo) =>
        //         <span>
        //             <a onClick={() => openEdit(true)}><EditOutlined/></a>
        //         </span>
        // },
        {
            title: "多语言",
            key: "_ml",
            dataIndex: "_ml",
            align: 'center' as 'center',
            width: 88,
            render: (v : string, r : any) =>
                <a onClick={(e:any)=>openMl(r)}>
                    <GlobalOutlined/> 设置
                </a>
        },
        {
            title: '子项',
            key: 'next',
            dataIndex: '_id',
            width: 88,
            align: 'center' as 'center',
            render: (id: number, item: CategoryDo) =>
                <a onClick={() => loadChildren(item)}><SisternodeOutlined style={{fontSize: 16}} /></a>
        },
    ];

    const exButtons = [
        <Button type='link' onClick={loadParent} hidden={parents.length <= 1}
                key='loadParent'><EnterOutlined rotate={90}/> 返回上级</Button>
    ];
    const crumb = [
        {name: '线上商城'},
        {name: '商品分类'},
    ];
    return (
        <Layout>
            <NavHeader selected={["mall/"]}/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain />
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <EditableTable1
                                key={'categoryTable'}
                                columns={columns}
                                datas={mainData}
                                record_key="id"
                                saveCallback={saveRecord}
                                deleteCallback={deleteRecord}
                                reloadCallback={loadMainData}
                                sortCallback={sortRecord}
                                exButtons={exButtons}
                            />

                            <MulitLanguageEditor ref={mlRef} />

                            <Modal title={<div><SettingOutlined/> 供应商系数：{}</div>}
                                   width={800}
                                   key='editUser'
                                   open={showEdit}
                                   onOk={saveProperties}
                                   onCancel={() => openEdit(false)}
                                   forceRender={true}
                                   okText={<span><SaveOutlined/> 保存</span>}
                                   centered>
                                <div style={{minHeight: 300, maxHeight: 'calc(100vh - 280px)', overflow: 'scroll'}}>
                                    <PropertiesTable
                                        ref={propsRef}
                                        properties={properties}
                                        values={[
                                            {'id':1,'code': 'PRICE', 'name': '价格系数'},
                                            {'id':2,'code': 'SUP1', 'name': '供应商1系数'},
                                            {'id':2,'code': 'SUP1', 'name': '供应商1系数'},
                                            {'id':2,'code': 'SUP1', 'name': '供应商1系数'},
                                            {'id':3,'code': 'SUP2', 'name': '供应商2系数'}]}
                                    />
                                </div>
                            </Modal>
                        </div>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );

}


export default Category;
