
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";

import {Button, Col, Drawer, message, Modal, Row,} from "antd";
import AxiosUtil from "../../../utils/AxiosUtil";

import '../../css/InfoEditor.less'
import {ExclamationCircleOutlined, AlipayOutlined, SaveOutlined} from "@ant-design/icons";
import {ItemDo} from "../model/ItemDo";
import {ListDo} from "../../admin/model/listDo";
import {PaymentChannelDo} from "../model/PaymentChannelDo";
import EditorForm, {EditorFieldProp} from "../../../components/EditorForm";


const {confirm} = Modal

interface IProps {
    // inputFields: EditorFieldProp[];
    category?:ListDo[];
    prop1Data?:ListDo[];
    prop2Data?:ListDo[];
    callback: ()=>void;
}


const PaymentEditor = (props: IProps, ref: any) => {

    const editorFormRef = useRef();
    const [editingData, setEditingData] = useState<any>({});
    const [showForm, setShowForm] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ItemDo) => openForm(r)
        }
    });

    const openForm = (r: PaymentChannelDo) => {
        setEditingData(r);
        setShowForm(true);
    }


    let changedValues = false;
    const changeForm = (change:boolean)=>{
        changedValues = change;
    }
    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }

    let inputFields: EditorFieldProp[] = [
        {type: 'image', name: 'image', label: '主图'},
        {type: 'input', name: 'payName', label: '名称', required:true},
        {type: 'input', name: 'payCode', label: 'Code', required:true},
        {type: 'number', name: 'feeRate', label: '费率%',  width:12},
        {type: 'number', name: 'feeFixed', label: '固定费用',  width:12},
        {type: 'number', name: 'sortIndex', label: '排序',  width:12},
        {name: '', width:12},
        {type: 'bool', name: 'payStatus', label: '启用', width:12},
        {type: 'bool', name: 'topping', label: '推荐', width:12},
        {type: 'text', name: 'properties', label: '参数配置', },
        {type: 'hidden', name: 'id'},
    ];

    const onSave = () => {
        (editorFormRef.current as any).submitForm();
    }

    const submitForm = (values:any)=> {
        console.log(values);
        values.payStatus = values.payStatus ? 1 : 0;
        values.topping = values.topping ? 1 : 0;
        AxiosUtil.AxiosRequest("/mallApi/payConfig/edit", [values],
            (suc) => {
                if (suc) {
                    message.success("保存成功！");
                    setShowForm(false);
                    if (props.callback) {
                        props.callback();
                    }
                }
            })
    }

    return (

        <Drawer placement="right"
                width={720}
                open={showForm}
                onClose={closeForm}
                title={<Row>
                    <Col span={11}><AlipayOutlined /> 支付渠道编辑</Col>
                    <Col span={11} className="right">
                        <Button type={"primary"} onClick={onSave}><SaveOutlined/> 保 存</Button>
                    </Col>
                </Row>}>

            <EditorForm ref={editorFormRef}
                        inputFields={inputFields}
                        initData={editingData}
                        changeCallback={changeForm}
                        submitCallback={submitForm}
            />

            <p><br/><br/></p>
        </Drawer>
    )
}

export default forwardRef(PaymentEditor)