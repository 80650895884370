import store from "store";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Modal, Row, Select, Tabs} from "antd";
import {ContractDo} from "../model/contractDo";
import moment from "moment";
import {ExclamationCircleOutlined,SaveOutlined, FileDoneOutlined} from "@ant-design/icons";
import DataUtil from "../../../utils/DataUtil";
import AxiosUtil from "../../../utils/AxiosUtil";
import {CustomerDo} from "../model/customerDo";
import {ListDo} from "../../admin/model/listDo";
import SimpleList from "../../admin/components/SimpleList";
import ContractFormInvoice from "./ContractFormInvoice";
import ContractFormCashflow from "./ContractFormCashflow";
import ContractFormFile from "./ContractFormFile";
import ContractFormReward from "./ContractFormReward";

const {confirm} = Modal
const {TabPane} = Tabs

const ContractForm = (props: any, ref: any) => {

    const login_info = store.get("login_info");
    const access_token = login_info ? login_info.accessToken || "" : "";

    const invoiceRef = useRef();
    const cashflowRef = useRef();
    const fileRef = useRef();
    const rewardRef = useRef();

    const [formRef] = Form.useForm();

    const [contractInfo, setContractInfo] = useState<ContractDo | undefined>(undefined);
    const [customerData, setCustomerData] = useState<CustomerDo[]>([]);

    const [statusData, setStatusData] = useState<ListDo[]>([]);
    const [typeData, setTypeData] = useState<ListDo[]>([]);

    const [showForm, setShowForm] = useState(false);
    let changedValues = false;

    useImperativeHandle(ref, () => {
        return {
            openForm: (r: ContractDo) => openForm(r)
        }
    });

    useEffect(() => {
        loadCustomerData();
        SimpleList.param(78, (d) => (setStatusData(d)));
        SimpleList.param(100, (d) => (setTypeData(d)));

        const ir = invoiceRef.current;
        console.log(ir);
    }, []);

    useEffect(() => {
        if (showForm) {
            (invoiceRef.current as any).openForm(contractInfo);
            (cashflowRef.current as any).openForm(contractInfo);
            (fileRef.current as any).openForm(contractInfo);
            (rewardRef.current as any).openForm(contractInfo);
        }
    }, [showForm])

    const loadCustomerData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setCustomerData(dat.list);
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/customer/list', {}, callback);
        console.log("loadCustomerData - ok")
    }

    const openForm = (r: ContractDo) => {
        if (r) {
            const dateSign = r.dateSign == null || r.dateSign == "" ? "" : moment(r.dateSign)
            const dateEnd = r.dateEnd == null || r.dateEnd == "" ? "" : moment(r.dateEnd)
            setContractInfo(r);
            formRef.setFieldsValue({...r, dateSign: dateSign, dateEnd: dateEnd});

        } else {
            const callback = (suc: boolean, dat: any) => {
                if (suc) {
                    r = {} as ContractDo;
                    r.contractCode = dat;
                    setContractInfo(r);
                }else{
                    setContractInfo(undefined);
                }
                formRef.resetFields();
                formRef.setFieldsValue(r);
            }
            AxiosUtil.AxiosRequest('/crmApi/contract/nextCode', {}, callback);
        }
        setShowForm(true);
    }

    const closeForm = () => {
        if (changedValues) {
            confirm({
                title: '有未保存的修改，确认关闭？',
                icon: <ExclamationCircleOutlined/>,
                content: '',
                okText: "确认关闭",
                cancelText: "取消",
                onOk() {
                    console.log('OK');
                    setShowForm(false);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            setShowForm(false);
        }
    }


    const onFinishForm = () => {
        let values = formRef.getFieldsValue();
        console.log(values);
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                props.callback();
                changedValues = false;
                closeForm();
            }
        }
        AxiosUtil.AxiosRequest('/crmApi/contract/edit', [values], callback);
    }

    return (
        <Drawer placement="right"
                width={800}
                open={showForm}
                onClose={closeForm}
                title={
                    <Row>
                        <Col span={12}>
                            <FileDoneOutlined/> 合同详情
                        </Col>
                        <Col span={12}>
                            <Button type={"primary"}
                                    style={{float: "right", marginRight: 64}}
                                    onClick={() => {
                                        formRef.submit()
                                    }}
                            >
                                <SaveOutlined/> 保 存
                            </Button>
                        </Col>
                    </Row>
                }>

            <Form id="invoiceForm"
                  layout="horizontal"
                  colon={false}
                  onFinish={onFinishForm}
                  labelCol={{span: 8, offset: 0}}
                  form={formRef}
                  style={{marginRight: 24}}
                  onValuesChange={() => {
                      changedValues = true
                  }}
            >
                <Form.Item hidden name={'id'} initialValue={0}><Input/></Form.Item>

                <Row>
                    <Col span={12}>
                        <Form.Item name={'contractCode'} label={'合同编号'} rules={[{required: true}]}>
                            <Input placeholder={'请输入合同编号'}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'contractTitle'} label={'合同名称'} labelCol={{span: 4}}
                                   rules={[{required: true}]}>
                            <Input placeholder={'请输入合同名称'}/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'customerId'} label={'客户名称'} labelCol={{span: 4}}>
                            <Select allowClear={false}>
                                {DataUtil.selectOptionsRender(customerData, 'id', 'customerName')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'totalMoney'} label={'合同金额'}>
                            <InputNumber placeholder={'请输入合同金额'} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'dateSign'} label={'签订日期'}>
                            <DatePicker placeholder={'请输入签订日期'} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'contractType'} label={'合同类型'}>
                            <Select>
                                {DataUtil.selectOptionsRender(typeData, 'value', 'text')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'dateEnd'} label={'截止日期'}>
                            <DatePicker placeholder={'请输入截止日期'} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'contractStatus'} label={'合同状态'}>
                            <Select>
                                {DataUtil.selectOptionsRender(statusData, 'value', 'text')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'remark'} label={'备注信息'} labelCol={{span: 4}}>
                            <Input.TextArea placeholder={"请输入备注信息"} style={{height: 80}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}
                  hidden={contractInfo == undefined || contractInfo.id == 0}>
                <TabPane tab={
                    <> 文档附件
                        {contractInfo?.fileCount ? <sup>（{contractInfo?.fileCount}）</sup> : ""}
                    </>}
                         key="1" forceRender={true}>
                    <ContractFormFile ref={fileRef} forceRender={true}/>
                </TabPane>
                <TabPane tab={
                    <> 开票记录
                        {contractInfo?.invoiceCount ? <sup>（{contractInfo?.invoiceCount}）</sup> : ""}
                    </>}
                         key="2" forceRender={true}>
                    <ContractFormInvoice ref={invoiceRef} forceRender={true}/>
                </TabPane>
                <TabPane tab={
                    <> 资金流水
                        {contractInfo?.cashflowCount ? <sup>（{contractInfo?.cashflowCount}）</sup> : ""}
                    </>}
                         key="3" forceRender={true}>
                    <ContractFormCashflow ref={cashflowRef} forceRender={true}/>
                </TabPane>
                <TabPane tab={
                    <> Cost
                        {contractInfo?.rewardCount ? <sup>（{contractInfo?.rewardCount}）</sup> : ""}
                    </>}
                         key="4" forceRender={true}>
                    <ContractFormReward ref={rewardRef} forceRender={true}/>
                </TabPane>
            </Tabs>

        </Drawer>
    )
}

export default forwardRef(ContractForm);