import React, { useEffect, useState} from 'react';
import {
    LoginForm,
    LoginFormPage,
    PageContainer,
    ProCard,
    ProConfigProvider, ProFormCheckbox,
    ProFormText,
    ProLayout
} from "@ant-design/pro-components";
import dLogo from "../images/game.svg"
import MyProLayout from "../components/MyProLayout";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {Checkbox, message, theme} from "antd";
import moment from "moment/moment";
import AxiosUtil from "../utils/AxiosUtil";
import store from "store";
import cookie from "react-cookies";


const Login = () => {
    // const {token} = theme.useToken();

    let login_info : any;

    const getPowerMenu = () => {
        AxiosUtil.AxiosRequest('/adminApi/function/tree', {systemId: login_info?.systemId || 1},
            (suc: boolean, d?: any) => {
                if (suc) {
                    const menus = d;
                    store.set('login_power', menus);
                    // console.log('login_power: ', menus);
                    if (menus && menus.length > 0 && menus[0].pageUrl && menus[0].pageUrl !== "") {
                        window.setTimeout(() => {
                            window.location.replace('#/' + menus[0].pageUrl)
                        }, 1000)
                    } else {
                        window.setTimeout(() => {
                            window.location.replace('#/')
                        }, 1000)
                    }
                }
            });
    }
    const onFinish = (values: Record<string, any>) => {
        // setShowLoading(true);
        console.log(values);
        console.log(moment().add(7, 'day'));

        const post = {
            loginName: values.username,
            loginPassword: values.password
        }
        AxiosUtil.AxiosRequest('/authApi/login/login', post,
            (suc: boolean, dat?: any) => {
                if (suc) {
                    // login_info = dat;
                    store.set('login_info', dat);
                    if (values.remember) {
                        cookie.save(
                            "isLogin",
                            "true",
                            {path: "/", expires: values.remember
                                    ? new Date(moment().add(7, 'day').format('YYYY-MM-DD HH:mm:ss'))
                                    : undefined });
                    }else {
                        cookie.save("isLogin", "true", {path: "/"});
                    }
                    message.success('登录成功，即将跳转');

                    getPowerMenu();
                }
                // setShowLoading(false);
            });
        return "" as any
    };

    return (
        <ProLayout layout={"top"} logo={undefined} title={false}>
            <ProCard boxShadow style={{width: 512, margin: "auto"}}>
                <LoginForm logo={dLogo}
                           title={"EasyGameNow"}
                           subTitle={" "}
                           actions
                           message={""}
                           onFinish={onFinish}
                >
                    <ProFormText
                        name="username"
                        fieldProps={{
                            size: 'large',
                            prefix: <UserOutlined className={'prefixIcon'}/>,
                        }}
                        placeholder={'用户名: admin or user'}
                        rules={[{required: true, message: '请输入用户名!',}]}
                    />
                    <ProFormText.Password
                        name="password"
                        fieldProps={{
                            size: 'large',
                            prefix: <LockOutlined className={'prefixIcon'}/>,
                        }}
                        placeholder={'密码: Your password'}
                        rules={[{required: true, message: '请输入密码！',}]}
                    />

                    <div style={{marginBlockEnd: 24,}}>
                        <Checkbox name="autoLogin" checked>
                            自动登录
                        </Checkbox>
                        <a style={{float: 'right',}}>
                            忘记密码
                        </a>
                    </div>
                </LoginForm>
            </ProCard>
        </ProLayout>
    )
}

export default Login