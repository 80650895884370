import { Layout, Popconfirm, Space, Table} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import NavFooter from "../../components/NavFooter";
import React, {useEffect, useState} from "react";
import {Content} from "antd/es/layout/layout";
import AxiosUtil from "../../utils/AxiosUtil";
import moment from "moment";


const AliSecurity = () => {

    // const formRef = useRef();

    const [mainData, setMainData] = useState();

    useEffect(() => {
        loadMainData();
    }, []);

    const loadMainData = () => {
        const callback = (suc: boolean, dat: any) => {
            if (suc) {
                setMainData(dat.permissions.permission)
                // setTotal(dat.total)
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/aliSecurityGroup/list', {}, callback);
        console.log("loadMainData - ok")
    }

    const renewIp = (r: any) => {
        const callback = (suc: boolean) => {
            if (suc) {
                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/aliSecurityGroup/renewIp', r, callback);
    }

    const crumb = [
        {name: '系统管理'},
        {name: '阿里云安全组'},
    ];

    const columns = [
        {
            title: '授权策略',
            dataIndex: 'policy',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '协议类型',
            dataIndex: 'ipProtocol',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '端口范围',
            dataIndex: 'portRange',
            align: 'center' as 'center',
            width: 60,
        },
        {
            title: '授权对象',
            dataIndex: 'sourceCidrIp',
            align: 'center' as 'center',
            width: 100,
        },
        {
            title: '描述',
            dataIndex: 'description',
            align: 'center' as 'center',
            width: 100,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center' as 'center',
            width: 100,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
        {
            title: '操作',
            dataIndex: '_1',
            align: 'center' as 'center',
            width: 60,
            render: (v: string, r:any) => (
                <Popconfirm title="确定要将这条记录替换为当前IP吗？"

                            okText="更新"
                            cancelText
                            onConfirm={() => renewIp(r)}
                >
                    <a >更新</a>
                </Popconfirm>

            ),
        },
    ];
    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Space size={16} style={{marginBottom: 16}}>
                            </Space>
                        </div>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               bordered
                               pagination={false}
                        />

                        {/*<AppForm ref={formRef} callback={loadMainData} />*/}

                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default AliSecurity;