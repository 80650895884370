import { useEffect, useState} from "react";
import {
    Button, Col, Divider, Form, Input, Layout, message, Modal, Popconfirm, Row, Select, Space, Spin,
    Table, Typography
} from "antd";
import AxiosUtil from "../../utils/AxiosUtil";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavBreadcrumb from "../../components/NavBreadcrumb";
import NavFooter from "../../components/NavFooter";
import React from "react";
import {
    SettingOutlined,
    FormOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import moment from "moment";
import {ParamProps} from "./model/param";
import {EditorFieldProp} from "../../components/EditorForm";

const {Content} = Layout;

// interface IProps {
//     match: any
// }

const Param = (props: any) => {

    const [userForm] = Form.useForm();
    const [queryForm] = Form.useForm();

    const [mainData, setMainData] = useState<any[]>([]);
    const [mainQuery, setMainQuery] = useState<any>();
    const [total, setTotal] = useState(0);

    const [openEdit, setShowEdit] = useState(false);
    const [editingUser, setEditingUser] = useState<any>({});

    const [roleData, setRoleData] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [systemData, setSystemData] = useState<ParamProps[]>([]);

    useEffect(()=>{
        loadParam();
        loadRole();
    },[]);

    useEffect(()=> {
    },[props]);

    useEffect(()=>{
        loadMainData();
    },[mainQuery]);

    const  loadMainData = () => {
        if (!mainQuery) {
            return;
        }
        AxiosUtil.AxiosRequest('/adminApi/user/list', mainQuery,
            (suc, data) => {
                if (suc) {
                    setMainData(data.list);
                    setTotal(data.total);
                }
            });
    }

    const loadParam = () => {
        AxiosUtil.AxiosRequest('/adminApi/user/getUserSystem', {},
            (suc, data) => {
                if (suc) {
                    setSystemData(data.list);
                    setMainQuery({
                        systemId: data.list.length == 1 ? data.list[0].paramCode : null,
                        pageSize: 20,
                        pageNo: 1
                    });
                }
            });
    }

    const loadRole = () => {
        AxiosUtil.AxiosRequest('/adminApi/role/list', {},
            (suc, data) => {
                if (suc) {
                    setRoleData(data.list);
                }
            });
    }

    const onFinishAiForm = () => {
    }
    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows( selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };


    const  showEdit = (record: any) => {
        if (record) {
            AxiosUtil.AxiosRequest('/adminApi/user/getUserRole', {userId: record.userId},
                (suc, data) => {
                if (suc) {
                    console.log(suc, data);
                    setSelectedRows (data);
                }
            });
            record.systemId = record.systemId?.toString();
            userForm.setFieldsValue(record);
            setEditingUser(record);
        } else {
            userForm.resetFields();
            if (systemData.length == 1) {
                userForm.setFieldsValue({systemId: systemData[0].paramCode})
            }
            setEditingUser({
                settingUserId: 0,
                settingUserName: '',
            });
        }
        setShowEdit(true);
    }
    const   hideEdit = () => {
        setShowEdit(false);
    }
    const  saveRecord = () => {
        const values = userForm.getFieldsValue();
        const roles = selectedRows;
        if (roles.length === 0) {
            message.error('请选择用户角色！').then() ;
            return;
        }
        if (!values.systemId) {
            values.systemId = 0;
        }
        const post = {...values, roles: roles};
        AxiosUtil.AxiosRequest('/adminApi/user/setInfo', post,
            (suc: boolean) => {
                if (suc) {
                    message.success('保存成功！').then() ;
                    setShowEdit(false);
                    loadMainData();
                }
            });
    }

    const  deleteRecord = (record_key: string) => {
        const callback = (suc: boolean) => {
            if (suc) {
                message.success({content: '用户已删除！'}).then();
                loadMainData();
            }
        }
        AxiosUtil.AxiosRequest('/adminApi/user/delete', [{userId: record_key}], callback);
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

        let query = {...mainQuery};
        switch (extra.action) {
            case "paginate":
                query.pageNo = query.pageSize == pagination.pageSize ? pagination.current : 1;
                query.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order == "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    query.orderBy = "";
                }
                break;
            case "filter":
                // 此功能仅适用于本地删选
                break;
        }
        setMainQuery(query);
    }

    const crumb = [
        {name: '系统管理'},
        {name: '人员账号'},
    ];

    const columns = [
        {
            title: 'ID',
            key: 'userId',
            dataIndex: 'userId',
            align: 'center' as 'center',
            width: 1,
        },
        {
            title: '登录名',
            key: 'loginName',
            dataIndex: 'loginName',
            width: 100,
            sorter: {compare: (a: any, b: any) => a.loginName.localeCompare(b.loginName)},
            defaultSortOrder: 'ascend' as 'ascend',
        },
        {
            title: '姓名',
            key: 'userName',
            dataIndex: 'userName',
            width: 100,
            sorter: {compare: (a: any, b: any) => a.userName.localeCompare(b.userName)}
        },
        {
            title: '角色',
            key: 'roleName',
            dataIndex: 'roleName',
            width: 100,
        },
        {
            title: '客户',
            key: 'systemName',
            dataIndex: 'systemName',
            width: 100,
        },
        {
            title: '编辑',
            key: 'operation',
            dataIndex: 'operation',
            width: 100,
            align: 'center' as 'center',
            render: (_: any, record: any) => {
                return (
                    record.loginName === "admin" ? <></> :
                        <Space size="small" split={<Divider type="vertical"/>}>
                            <Typography.Link onClick={() => showEdit(record)}
                                             key={'edit' + record.userId}><FormOutlined/></Typography.Link>
                            <Popconfirm title="确定要删除这个用户吗"
                                        okText="删除"
                                        cancelText
                                        onConfirm={() => deleteRecord(record.userId)}
                            >
                                <Typography.Link key={'del' + record.userId}><DeleteOutlined/></Typography.Link>
                            </Popconfirm>
                        </Space>
                )
            }
        },
        {
            title: '最近登录',
            key: 'lastLogin',
            dataIndex: 'lastLogin',
            width: 100,
            sorter: {compare: (a: any, b: any) => a.lastLogin - b.lastLogin},
            render: (text: any) => {
                return text === null ? '' : moment(text).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: '登录IP',
            key: 'lastIp',
            dataIndex: 'lastIp',
            width: 100,
        },
        {
            title: '修改日期',
            key: 'updateTime',
            dataIndex: 'updateTime',
            width: 100,
            render: (text: any) => {
                return text === null ? '' : moment(text).format('yyyy-MM-DD HH:mm')
            }
        },
    ];

    const roleColumns = [
        {title: 'ID', key: 'roleId', dataIndex: 'roleId', ellipsis: true},
        {title: '角色', key: 'roleName', dataIndex: 'roleName', ellipsis: true},
        // {title: '类型', key: 'roleTypeName', dataIndex: 'roleTypeName', ellipsis: true},
        {title: '备注', key: 'remark', dataIndex: 'remark', ellipsis: true},
    ]

    const submitForm = () => {
        let values = queryForm.getFieldsValue();
        setMainQuery({...mainQuery, ...values, pageNo:1});
    }

    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Form form={queryForm}
                                  onFinish={submitForm}
                                  layout="horizontal"
                                  className={"queryForm"}
                                  labelCol={{span: 8, offset: 0}}
                                  colon={false}>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Form.Item name="text" label="登录名">
                                            <Input allowClear />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="mapping" label="姓名">
                                            <Input  allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="type" label="角色">
                                            <Select allowClear options={roleData} fieldNames={{label: "roleName", value: "roleId"}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name="systemId" label="客户">
                                            <Select allowClear options={systemData} fieldNames={{label: "paramName", value: "paramCode"}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Space size={24}>
                                            <Button type={"primary"} onClick={submitForm}>
                                                <SearchOutlined/> 查询
                                            </Button>
                                            <Button type={"primary"} onClick={() => showEdit(null)}>
                                                <PlusCircleOutlined/> 新建
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>


                            {/*<Space size={16} style={{marginBottom: 16}}>*/}
                            {/*    <Button type="primary" key='showEdit'*/}
                            {/*            onClick={() => showEdit(null)}>*/}
                            {/*        <PlusCircleOutlined/> 新增*/}
                            {/*    </Button>*/}
                            {/*    <Button type="primary" key='reload'*/}
                            {/*            onClick={(e) => loadMainData()}>*/}
                            {/*        <RedoOutlined/> 刷新*/}
                            {/*    </Button>*/}
                            {/*</Space>*/}
                            <Table key='user'
                                   rowKey='userId'
                                   columns={columns}
                                   dataSource={mainData}
                                   size="small"
                                   bordered
                                   onChange={onTableChange}
                                   pagination={{
                                       total: total,
                                       pageSize: mainQuery?.pageSize,
                                       current: mainQuery?.pageNo
                            }}
                            />
                        </div>

                        <Modal title={<div><SettingOutlined/> 编辑用户：{editingUser.userName}</div>}
                               key='editUser'
                               open={openEdit}
                               onOk={saveRecord}
                               onCancel={hideEdit}
                               forceRender={true}
                               centered>
                            <div id="modalUserInfo">
                                    <Form name="AiReport_Form"
                                          layout="horizontal"
                                          autoComplete="off"
                                          key='ff123'
                                          onFinish={onFinishAiForm}
                                          labelCol={{span: 4, offset: 0}}
                                          form={userForm}
                                          // ref={formRef}
                                    >
                                        <Form.Item key='a1' hidden name={'userId'}
                                                   initialValue=''><Input/></Form.Item>
                                        <Form.Item key='a2' hidden name={'updated'}
                                                   initialValue=''><Input/></Form.Item>
                                        <Row gutter={[0, 0]} key="r">

                                            <Col span={24}>
                                                <Form.Item name={'loginName'} initialValue='' label={'登录名'}
                                                           style={{margin: 8}}
                                                           rules={[{required: true, message: '请输入登录名'}]}>
                                                    <Input placeholder={'请输入登录名'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={'userName'} initialValue='' label={'真实姓名'}
                                                           style={{margin: 8}}
                                                           rules={[{required: true, message: '请输入真实姓名'}]}>
                                                    <Input placeholder={'请输入真实姓名'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={'setPwd'} initialValue='' label={'重置密码'}
                                                           style={{margin: 8}}>
                                                    <Input.Password placeholder={'如无需修改请保持空白'}
                                                                    autoComplete="new-password"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={'userEmail'} initialValue='' label={'Email'}
                                                           style={{margin: 8}}>
                                                    <Input placeholder={'请输入Email'}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={'systemId'}  label={'客户'} style={{margin: 8}}>
                                                    <Select allowClear options={systemData} fieldNames={{label: "paramName", value: "paramCode"}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={'userMobile'} initialValue='' label={'手机号'}
                                                           style={{margin: 8}}>
                                                    <Input placeholder={'请输入手机号'}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table key="role"
                                           size='small'
                                           rowKey="roleId"
                                           pagination={false}
                                           columns={roleColumns}
                                           dataSource={roleData}
                                           rowSelection={{type: 'checkbox', ...rowSelection}}
                                           bordered
                                    />
                            </div>
                        </Modal>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}
export default Param;


