import React, {useEffect, useState} from 'react';

import {Table, Form, Input, InputNumber, DatePicker, Select, Popconfirm, Typography, Space, Button, Divider, Row, Col, Checkbox,} from 'antd';
import {FormOutlined, MenuOutlined, DeleteOutlined, PlusCircleOutlined, StopOutlined, SaveOutlined, RedoOutlined} from "@ant-design/icons";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import DataUtil from "../utils/DataUtil";
import {arrayMoveImmutable} from "array-move";


interface IProps {
    columns: any[],
    datas: any[],
    record_key: string,
    addModelCallback?: (record: any) => void,
    saveCallback?: (record: any) => void,
    deleteCallback: (record_key: string) => void,
    reloadCallback: () => void,
    sortCallback?: (records: any[]) => void,
    actionButtons?: ('add' | 'addModal' | 'reload' | 'back')[],
    exButtons?: any[],
    exButtonsRight?: any[],
    addChild?: boolean
}

interface editorProps {
    type: 'number' | 'text' | 'select' | 'date' | 'checkbox',
    required: boolean
    idField: string,
    valueField: string,
    textField: string,
    data: any[],
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    editor: editorProps;
    dataIndex: string;
    title: any;
    record: any;
    index: number;
    children: React.ReactNode;
}

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortContainer = SortableContainer((props: any) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined/>);

const EditableTable1 = (props: IProps) => {

    const [mainData, setMainData] = useState<any[]>(props.datas)
    const [editingId, setEditingId] = useState<string>('')
    const [editingRecord, setEditingRecord] = useState({})
    const [editingSelected, setEditingSelected] = useState({})
    // const [changeSelect, setChangeSelect] = useState({})


    useEffect(() => {
        // console.log('监听父组件通过props传递的值', props, editingId);
        setMainData(props.datas);

        setEditingId('');
        setEditingRecord({});
        setEditingSelected({});
    }, [props])

    // useEffect(()=>{
    //     console.log('监听', editingSelected);
    // },[editingSelected])

    // 这里刷新编辑状态，会导致新增记录不能直接进入编辑状态。移到[props]和reload
    // useEffect(() => {
    //     setEditingId('');
    //     setEditingRecord({});
    //     setEditingSelected({});
    // }, [mainData])

    const [formRef] = Form.useForm();
    // const formRef = React.createRef<FormInstance>();

    const EditableCell: React.FC<EditableCellProps> = ({
                                                           editing,
                                                           editor,
                                                           dataIndex,
                                                           title,
                                                           record,
                                                           index,
                                                           children,
                                                           ...restProps
                                                       }) => {
        // 暂存行编辑中的
        const onSelectChange = (val: any, item: any) => {
            const oldState = editingSelected;
            setEditingSelected({
                ...oldState,
                [editor.idField]: item.value,
                [dataIndex]: editor.idField === dataIndex ? item.value : item.children
            })
        }
        let inputNode = <></>;
        if (editor) {
            switch (editor.type) {
                case "number":
                    inputNode = <InputNumber/>;
                    break;
                case "checkbox":
                    inputNode = <Checkbox/>;
                    break;
                case "date":
                    inputNode = <DatePicker/>;
                    break;
                case "select":
                    inputNode = <Select allowClear={!editor.required} onChange={onSelectChange}>
                        {DataUtil.selectOptionsRender(editor.data, editor.valueField, editor.textField)}
                    </Select>;
                    break;
                default:
                    inputNode = <Input/>;
            }
        }

        return (
            <td {...restProps} >
                {editing ? (
                    <Form.Item
                        key={dataIndex}
                        name={dataIndex}
                        style={{margin: 0}}
                        rules={[{required: editor.required}]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const onSortEnd = ({oldIndex, newIndex}: { oldIndex: any, newIndex: any }) => {
        if (props.sortCallback) {
            if (oldIndex !== newIndex) {
                // #@ts-ignore
                let newData: any[] = arrayMoveImmutable([].concat(...mainData), oldIndex, newIndex).filter(el => !!el);
                console.log('Sorted items: ', newData);
                for (let i = 0; i < newData.length; i++) {
                    newData[i]['sortIndex'] = i + 1;
                }
                props.sortCallback(newData);
                setMainData(newData);
            }
        }
    };

    const DraggableContainer = (props: any) => (
        <SortContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    // @ts-ignore
    const DraggableBodyRow = ({className, style, ...restProps}) => {
        //const {data} = this.state;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = mainData.findIndex((x: any) => x[props.record_key] === restProps['data-row-key']);
        //console.log(index,restProps)
        return <SortableItem key={index} index={index} {...restProps} />;
    };

    /**
     * 判断当前是否处于编辑状态
     * @param record 该记录是否正在编辑（不传，则判断任意一条是否正在编辑）
     */
    const isEditing = (record?: any) => {
        if (record) {
            // console.log(new Date(), editingRecord);
            // console.log(new Date(), editingId);
            return record[props.record_key] === editingId
        } else {
            return editingId !== '';
        }
    };
    const columns = [...props.columns];

    //const columnsInit = () => {
    if (props.addChild && columns.findIndex(item => item.dataIndex === 'addChild') < 0) {
        columns.push({
            title: '子项',
            key: 'addChild',
            dataIndex: 'addChild',
            align: 'center' as 'center',
            width: 100,
            render: (_3: any, record: any) => {
                return (
                    <Typography.Link disabled={isEditing()}
                                     onClick={() => AddChild(record[props.record_key])}>
                        <PlusCircleOutlined/>
                    </Typography.Link>)
            },
        },);
    }
    if (props.sortCallback && columns.findIndex(item => item.key === 'sort') < 0) {
        columns.push({
            title: '排序',
            key: 'sort',
            dataIndex: 'sort',
            align: 'center' as 'center',
            width: 100,
            className: 'drag-visible',
            render: (_2: any, record: any) => {
                return (<Typography.Link disabled={isEditing()}><DragHandle/></Typography.Link>)
            },
        },);
    }
    if (columns.findIndex(item => item.key === 'operation') < 0) {
        //let c = this.state.columns;
        columns.push({
            title: '编辑',
            key: 'operation',
            dataIndex: 'operation',
            width: 100,
            align: 'center' as 'center',
            render: (_1: any, record: any) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space size="small" split={<Divider type="vertical"/>}>
                        <Typography.Link key={1} onClick={() => save(record[props.record_key])}>
                            <SaveOutlined/>
                        </Typography.Link>
                        <Typography.Link key={2} onClick={cancel}><StopOutlined/></Typography.Link>
                    </Space>
                ) : (
                    <Space size="small" split={<Divider type="vertical"/>}>
                        {props.saveCallback ?
                            <Typography.Link key={3} disabled={isEditing()} onClick={() => edit(record)}>
                                <FormOutlined/>
                            </Typography.Link>
                            : undefined}
                        <Popconfirm disabled={isEditing()}
                                    title="确定要删除这条记录吗"
                                    okText="删除"
                                    cancelText
                                    onConfirm={() =>
                                        props.deleteCallback(record[props.record_key])
                                        // Delete(record[this.props.record_key]) 没理解当时为什么要在取一遍id
                                    }
                        >
                            <Typography.Link key={4} disabled={isEditing()}><DeleteOutlined/></Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },)
        //this.setState({columns: c});
    }
    //}

    const Reload = () => {
        cancel();
        props.reloadCallback();
    };
    const Add = () => {
        formRef.resetFields();
        const newData = [...mainData];
        const newRecord = {
            [props.record_key]: '0',
            sortIndex: DataUtil.findMaxMin(newData, 'sortIndex').max.sortIndex + 1
        };
        newData.push(newRecord);

        setMainData(newData);
        setEditingId('0')
        setEditingRecord(newRecord);

    };
    const AddChild = (key_id: number) => {
        let parent_id = key_id;
        let newRecord = {[props.record_key]: '0', parentId: parent_id};
        const FindToAdd = (d: any[]) => {
            for (let i = 0; i < d.length; i++) {
                if (d[i][props.record_key] === parent_id) {
                    if (!d[i].children) {
                        d[i].children = [];
                    }
                    newRecord.sortIndex = DataUtil.findMaxMin(d[i].children, 'sortIndex').max.sortIndex + 1;
                    d[i].children.push(newRecord);
                    break;
                }
                if (d[i].children) {
                    d[i].children = FindToAdd(d[i].children);
                }
            }
            return d;
        }
        const newData = FindToAdd([...mainData]);
        formRef.resetFields();

        setMainData(newData);
        setEditingId('0')
        setEditingRecord(newRecord);
    };
    const edit = (record: any) => {
        console.log("edit --- ", record);
        formRef.setFieldsValue({...record});
        setEditingId(record[props.record_key]);
        setEditingRecord(record);
    };
    const cancel = () => {
        const newData = [...mainData];
        const index = newData.findIndex(item => item[props.record_key] === '0');
        if (index > -1) {
            newData.splice(index, 1);
            setMainData(newData);
        }
        setEditingId('')
        setEditingRecord({});
        // setChangeSelect({});
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await formRef.validateFields());

            //const newData = [...this.state.data];
            const newRecord = {...editingRecord, ...row, ...editingSelected};

            if (key === '0' || key === 0) {
                newRecord[props.record_key] = null;
            }
            if (props.saveCallback) {
                props.saveCallback(newRecord);
            }
            setEditingId('')
            setEditingRecord({});
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const mergedColumns = columns.map(col => {
        if (!col.editor) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editor: col.editor,
                dataIndex: col.dataIndex,
                title: col.dataIndex,
                editing: isEditing(record),
            }),
        };
    });
    const actionButtons = props.actionButtons || ['add', 'reload'];

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Space size={16} style={{marginBottom: 16}}>
                        {actionButtons.includes('add') ?
                            <Button key="K1"
                                    type="primary"
                                    disabled={isEditing()}
                                    onClick={Add}
                            >
                                <PlusCircleOutlined/> 新增
                            </Button>
                            : ""}
                        {actionButtons.includes('addModal') ?
                            <Button key="K1-1"
                                    type="primary"
                                    disabled={isEditing()}
                                    onClick={props.addModelCallback}
                            >
                                <PlusCircleOutlined/> 新增
                            </Button>
                            : ""}
                        {actionButtons.includes('reload') ?
                            <Button key="K2"
                                    type="primary"
                                    onClick={Reload}
                                    hidden={!actionButtons.includes('reload')}>
                                <RedoOutlined/> 刷新
                            </Button>
                            : ""}
                        {props.exButtons}
                    </Space>
                </Col>
                <Col span={12}>
                    <Space size={16} style={{marginBottom: 16, float: 'right'}}>
                        {props.exButtonsRight}
                        {/*<Switch defaultChecked={false} disabled={true}/> 已删除记录*/}
                    </Space>

                </Col>
            </Row>
            <Form form={formRef} component={false}>
                {mainData && mainData.length>0 &&
                <Table
                    id={'mainTable'}
                    key={'mainTable'}
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                            cell: EditableCell,
                        },
                    }}
                    rowKey={props.record_key}
                    bordered
                    size="small"
                    dataSource={mainData}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={false}
                    indentSize={24}
                    expandable={{
                        defaultExpandAllRows:true,
                        expandIconColumnIndex:1
                    }}
                />

                }
                <>Total: {mainData?.length}</>
            </Form>
        </div>
    );
};

export default React.memo(EditableTable1)
