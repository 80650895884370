import { Layout, Space, Table} from "antd";
import NavHeader from "../../../components/NavHeader";
import SiderMenuMain from "../../../components/SiderMenuMain";
import NavBreadcrumb from "../../../components/NavBreadcrumb";
import NavFooter from "../../../components/NavFooter";
import React, {useEffect, useState} from "react";
import {Content} from "antd/es/layout/layout";
import {Query} from "../../../model/query";
import AxiosUtil from "../../../utils/AxiosUtil";
import moment from "moment";


const AppList = () => {


    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState();
    const [query, setQuery] = useState<Query>({
        pageSize: 20,
        pageNo: 1,
        orderBy: "",
    } as Query);


    useEffect(() => {
        loadMainData();
    }, [query]);

    useEffect(() => {
        // SimpleList.param(99, (d) => (setTypeData(d)));
    }, []);

    const loadMainData = () => {
        AxiosUtil.AxiosRequest('/mallApi/exchange/list', query,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list)
                    setTotal(dat.total)
                }
            });
        console.log("loadMainData - ok")
    }
    const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: any[], action: "paginate" | "sort" | "filter" }) => {

        let querys = {...query};
        switch (extra.action) {
            case "paginate":
                querys.pageNo = querys.pageSize === pagination.pageSize ? pagination.current : 1;
                querys.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order === "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    querys.orderBy = "";
                }
                break;
            case "filter":
                break;
        }
        setQuery(querys)
    }

    const crumb = [
        {name: '线上商城'},
        {name: '汇率设置'},
    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            align: 'center' as 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: 'center' as 'center',
        },
        {
            title: '中间价',
            dataIndex: 'middleRate',
            align: 'right' as 'right'
        },
        // {
        //     title: '基准价',
        //     dataIndex: 'benchmark',
        //     align: 'right' as 'right'
        // },
        {
            title: '现汇买入价',
            dataIndex: 'buying',
            align: 'right' as 'right'
        },
        {
            title: '现汇卖出价',
            dataIndex: 'selling',
            align: 'right' as 'right'
        },
        {
            title: '现钞买入价',
            dataIndex: 'cashBuying',
            align: 'right' as 'right'
        },
        {
            title: '现钞卖出价',
            dataIndex: 'cashSelling',
            align: 'right' as 'right'
        },
        {
            title: '修改时间',
            dataIndex: 'updateTime',
            align: 'center' as 'center',
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
        },
    ];
    return (

        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                            <Space size={16} style={{marginBottom: 16}}>
                            </Space>
                        </div>

                        <Table key='contract'
                               rowKey='id'
                               columns={columns}
                               dataSource={mainData}
                               size="small"
                               onChange={onTableChange}
                               bordered
                               pagination={{
                                   // onChange: this.onPageChange,
                                   total: total,
                                   pageSize: query.pageSize,
                                   current: query.pageNo
                               }}
                        />



                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );
}

export default AppList;