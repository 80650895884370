
import {Layout} from "antd";
import NavHeader from "../../components/NavHeader";
import SiderMenuMain from "../../components/SiderMenuMain";
import NavFooter from "../../components/NavFooter";
import React from "react";
import NavBreadcrumb from "../../components/NavBreadcrumb";

const {Content} = Layout;

const  MallIndex = () => {

    const crumb = [
        {name: '线上商城'},
        {name: 'Dashboard'},
    ];
    return (
        <Layout>
            <NavHeader/>
            <Content className={"main-body"}>
                <Layout>
                    <SiderMenuMain/>
                    <Content className={'main-content'}>
                        <NavBreadcrumb itemList={crumb}/>
                        <div id="mainTable">
                        </div>
                    </Content>
                </Layout>
            </Content>
            <NavFooter/>
        </Layout>
    );

}


export default MallIndex;
