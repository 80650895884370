import {Button, Col, Drawer, Form, Input, Modal, Row, Spin} from "antd";
import React, { useState} from "react";
import AxiosUtil from "../utils/AxiosUtil";
import store from "store";
import LogList from "./admin/components/logList";
import { v4 as uuidv4 } from 'uuid';
import {ExpandOutlined} from "@ant-design/icons";

const { Search } = Input;

const EaTest = () => {

    const [refresh, setRefresh] = useState("");
    const [fcData, setFcData] = useState<any>();
    const [fcData2, setFcData2] = useState<any>();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [logQuery, setLogQuery] = useState<any>({logType: "FC24-Result"});

    // useEffect(() => {
    const login_info = store.get("login_info");
    if (!login_info || !login_info.accessToken) {
        store.set("login_info", {accessToken: "1234567890987654321"})
    }
    // }, [])


    const submitFrom = (values: any) => {
        setLoading(true)
        AxiosUtil.AxiosRequest("/mallApi/eatest/checkAndGetFc24", values,
            (suc, data) => {
                if (suc) {
                    const res = JSON.parse(data)
                    setFcData({...res, email: values.email, password: values.password});
                    if (res.FC24Info.error != null && res.FC24Info.error != "") {
                        setError(res.FC24Info.error);
                    } else {
                        setError(undefined);
                    }
                } else {
                    setFcData(undefined);
                    setError(data);
                    Modal.error({title: "Error", content: data});
                }
                setRefresh(uuidv4());
                setLoading(false);
            })
    }
    const showResult = (dat: any) => {
        console.log(dat)
        setFcData2(JSON.parse(dat.postResult));
        setOpenView(true);
    }
    const closeView = () => {
        setOpenView(false);
        setFcData2(undefined);
    }
    const onSearch = (s:string) => {
        console.log(s)
        setLogQuery({logType: "FC24-Result", relField: s ? s.trim() : ""})

    }

    const FC24CheckResult = (fcData:any, canRedo?:boolean) => {

        console.log(fcData)
        return (<>
                {(typeof fcData == "string" && fcData.length > 1 && fcData.substring(0, 1) !== "{") ?
                    <>
                        <span style={{fontWeight: 600, color: "red"}}>{fcData}</span>
                        <br/>
                        {fcData == "用户提供的 code 已失效" && "1.账号密码 ----------------------- 正确"}
                    </>
                    : <>
                        1.账号密码 ----------------------- 正确
                        <br/>2.备用码验证 --------------------- 正确
                        <br/>3.重新生成备用码 ----------------- {fcData.newCodes ? "正确" :
                        <span className={"red"}>错误（）</span>}
                        <br/>4.俱乐部验证 --------------------- {fcData.FC24Info.ClubName ? "正确" :
                        <span className={"red"}>错误（）</span>}
                        <br/>5.订单平台匹配检查 --------------- {fcData.FC24Info.platform}
                        <br/>6.其他设备登录 ------------------- {fcData.FC24Info.online ?
                        <span className={"red"}>错误（）</span> : "正确"}
                        <br/>7.交易市场解锁 ------------------- {
                        fcData.FC24Info.trade == "2"
                            ? "正确"
                            : fcData.FC24Info.trade == "0"
                                ? <span className={"red"}>错误</span>
                                : <span className={"red"}>未知状态- {fcData.FC24Info.trade}</span>
                    }
                        <br/>--------------------------------
                        <br/>账号: {fcData.email}
                        <br/>密码: {fcData.password}
                        <br/>新备用码: {fcData.newCodes && fcData.newCodes.map((r: any, i: number) =>
                        <>
                            <br/> &nbsp; &nbsp; {i + 1}. {r}
                        </>
                    )}
                        <br/>
                        {(fcData.newCodes && fcData.newCodes.length<6 && canRedo) &&
                            <Button type={"primary"} onClick={()=>reGenerate(fcData)}>重新生成备用码</Button>
                        }
                        <br/>
                        <br/>FUT平台: {fcData.FC24Info.platform}
                        <br/>俱乐部: {fcData.FC24Info.ClubName}
                        <br/>起始金币数: {fcData.FC24Info.COINS}
                    </>}
            </>
        )
    }

    const reGenerate = (fcData:any) => {
        submitFrom({
            email:fcData.email,
            password:fcData.password,
            backcode:fcData.oldCodes,
            reGenerate: "1"
        })
    }

    return (
        <Spin spinning={loading}>
            <Row gutter={[24,24]} style={{padding:24}}>
                <Col span={12}>
                    <Form id={"form1"} labelCol={{span: 8}} onFinish={submitFrom} colon={false}>
                        <Form.Item name={"email"} label={"email"} rules={[{required:true,message: "必填"}]}>
                            <Input></Input>
                        </Form.Item>
                        <Form.Item name={"password"} label={"password"} rules={[{required:true,message: "必填"}]}>
                            <Input></Input>
                        </Form.Item>
                        <Form.Item name={"backcode"} label={"Backup Code"} rules={[{required:true,message: "必填"}]}>
                            <Input.TextArea style={{height: 160}}></Input.TextArea>
                        </Form.Item>
                        {/*<Form.Item name={"ge"} label={"强制生码"}>*/}
                        {/*    <Checkbox /> 无论是否已信任设备，都会生成新的备用码（一般用于老客户的新订单）*/}
                        {/*</Form.Item>*/}
                        <Form.Item label={" "}>
                            <Button type={"primary"} htmlType={"submit"}>Check FC24 & Backup Code</Button>
                        </Form.Item>
                    </Form>

                </Col>
                <Col span={12}>
                    <div style={{backgroundColor: "#f4f8ff", padding: 24}}>
                        {error && <>
                            <span style={{fontWeight: 600, color: "red"}}>{error}</span>
                            <br/>
                            {error=="用户提供的 code 已失效" && "1.账号密码 ----------------------- 正确"}
                            <br/>
                        </>
                        }
                        {fcData && FC24CheckResult(fcData,true)}
                    </div>

                </Col>
                <Col span={8}>
                    <Search placeholder="输入Email搜索历史记录" onSearch={onSearch} enterButton allowClear />
                </Col>
                <Col span={24}>
                    <LogList pageSize={10} query={logQuery} refresh={refresh} openViewCall={showResult}></LogList>
                </Col>
            </Row>

            <Drawer placement="right"
                    width={680}
                    open={openView}
                    onClose={closeView}
                    title={<span><ExpandOutlined/> Log Info</span>}>
                {fcData2 && FC24CheckResult(fcData2)}
            </Drawer>
        </Spin>
    )
}

export default EaTest;

