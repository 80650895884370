import { Col, Drawer, Form, Layout, Row, Table} from "antd";
import React, {useEffect, useState} from "react";
import AxiosUtil from "../../../utils/AxiosUtil";
import moment from "moment";
import {LogDo} from "../model/logDo";
import {ExpandOutlined} from "@ant-design/icons";
import RawDataViewer from "../../../components/RawDataViewer";
import ColumnFilterDateProp from "../../../components/ColumnFilterDateProp";
import {logQuery} from "../model/logQuery";
import ColumnFilterSearchProp from "../../../components/ColumnFilterSearchProp";

const LogList =(props: any)=> {

    const [total, setTotal] = useState(0);
    const [mainData, setMainData] = useState();
    const [viewDate, setViewData] = useState<LogDo>({} as LogDo);
    const [showForm, setShowForm] = useState(false);
    const [logQuery, setLogQuery] = useState<logQuery>();

    useEffect(() => {
        setLogQuery({
            ...props.query,
            pageSize: props.pageSize || 20,
            pageNo: 1,
            orderBy: ""
        })
        // loadMainData();
    }, [props.query, props.refresh]);

    useEffect(() => {
        loadMainData();
    }, [logQuery]);

    useEffect(() => {
        // SimpleList.param(99, (d) => (setTypeData(d)));
    }, []);

    const loadMainData = () => {
        if (!logQuery)
            return;
        AxiosUtil.AxiosRequest('/adminApi/log/list', logQuery,
            (suc: boolean, dat: any) => {
                if (suc) {
                    setMainData(dat.list)
                    setTotal(dat.total)
                }
            });
        console.log("loadMainData - ok")
    }
    const onTableChange = (pagination: any, filters: any, sorter: any, extra: {
        currentDataSource: any[],
        action: "paginate" | "sort" | "filter"
    }) => {

        let query = {...logQuery};
        switch (extra.action) {
            case "paginate":
                query.pageNo = query.pageSize === pagination.pageSize ? pagination.current : 1;
                query.pageSize = pagination.pageSize
                break;
            case "sort":
                if (sorter.order) {
                    let orderBy = sorter.field.replace(/([A-Z])/g, '_$1').toLowerCase();
                    orderBy += sorter.order === "ascend" ? " ASC" : " DESC";
                    query.orderBy = orderBy;
                } else {
                    query.orderBy = "";
                }
                break;
            case "filter":
                // 此功能仅适用于本地删选
                break;
        }
        setLogQuery(query as  logQuery)
    }

    const onSearch = (f: string, v: any) => {
        console.log("onSearch", v);
        let query = {...logQuery};
        switch (f) {
            case "type":
                query.logType = v.value;
                break;
            case "relTable":
                query.relTable = v.value;
                break;
            case "relField":
                query.relField = v.value;
                break;
            case "relCode":
                query.relCode = v.value;
                break;
            case "clientIp":
                query.clientIp = v.value;
                break;
        }
        setLogQuery(query as logQuery);
    }

    const onSearchDate = (f: string, v: any) => {
        console.log("onSearchDate", v);
        let query = {...logQuery};
        if (v.value.length !== 2) {
            return
        }
        query.beginDate = v.value[0] ? v.value[0].format('yyyy-MM-DD') : null;
        query.endDate = v.value[1] ? v.value[1].format('yyyy-MM-DD') : null;

        setLogQuery(query as logQuery);
    }

    const openView = (id: number) => {
        AxiosUtil.AxiosRequest('/adminApi/log/detail', {id: id},
            (suc: boolean, dat: any) => {
                if (suc) {
                    if (props.openViewCall) {
                        props.openViewCall(dat)
                    } else {
                        setViewData(dat);
                        setShowForm(true);
                    }
                }
            });
    }
    const closeForm = () => {
        setShowForm(false);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center' as 'center',
            width: 40,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 100,
            ...ColumnFilterSearchProp("type", "Type", onSearch)
        },
        {
            title: 'Table',
            dataIndex: 'relTable',
            width: 100,
            ...ColumnFilterSearchProp("relTable", "Table", onSearch)
        },
        {
            title: 'Field',
            dataIndex: 'relField',
            width: 100,
            ...ColumnFilterSearchProp("relField", "Field", onSearch)
        },
        {
            title: 'Code',
            dataIndex: 'relCode',
            width: 100,
            ...ColumnFilterSearchProp("relCode", "Code", onSearch)
        },
        {
            title: 'Time',
            dataIndex: 'createTime',
            width: 100,
            render: (v: number) => {
                return v === null ? '' : moment(v).format('yyyy-MM-DD HH:mm:ss')
            },
            ...ColumnFilterDateProp("createTime", "Time", onSearchDate),
        },
        {
            title: 'User',
            dataIndex: 'loginName',
            width: 100,
        },
        // {
        //     title: 'IP',
        //     dataIndex: 'clientIp',
        //     width: 100,
        // },
        {
            title: 'View',
            dataIndex: 'id',
            key: 'id_2',
            align: 'center' as 'center',
            width: 60,
            render: (v: number) => (<a onClick={() => openView(v)}><span><ExpandOutlined/> 查看</span></a>),
        },
    ];
    return (

        <Layout>

            <Table key='logs'
                   rowKey='id'
                   columns={columns}
                   dataSource={mainData}
                   size="small"
                   onChange={onTableChange}
                   bordered
                   pagination={{
                       // onChange: this.onPageChange,
                       total: total,
                       pageSize: logQuery?.pageSize,
                       current: logQuery?.pageNo,
                       showTotal: (total: number, range: number[]) => `第${range[0]}-${range[1]}条 / 共${total}条`,
                       showQuickJumper: true
                   }}
            />
            <Drawer placement="right"
                    width={666}
                    open={showForm}
                    onClose={closeForm}
                    title={<span><ExpandOutlined/> Log Info</span>}>
                <Form id="cashForm"
                      layout="horizontal"
                      colon={false}
                      labelCol={{span: 4, offset: 0}}
                      style={{marginRight: 24}}
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item label={'ID'} labelCol={{span: 8}}>
                                {viewDate?.id}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Type'} labelCol={{span: 8}}>
                                {viewDate?.type}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Table'} labelCol={{span: 8}}>
                                {viewDate?.relTable}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Field'} labelCol={{span: 8}}>
                                {viewDate?.relField}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={'Code'}>
                                {viewDate?.relCode}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'Time'} labelCol={{span: 8}}>
                                {moment(viewDate?.createTime).format('yyyy-MM-DD HH:mm:ss')}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={'IP'} labelCol={{span: 8}}>
                                {viewDate?.clientIp}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <RawDataViewer rawData={viewDate.postRaw} label="Post Raw"/>
                        </Col>
                        <Col span={24}>
                            <RawDataViewer rawData={viewDate.postResult} label="Post Result"/>
                        </Col>

                    </Row>
                </Form>
            </Drawer>
        </Layout>
    );
}

export default LogList;